import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import {faqlists} from '../actions/v1/report'
import ReactHTMLParser from 'react-html-parser'

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #a30726;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #a30726;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Conclusion(props) {
  const classes = useStyles();
  const { ...rest } = props;
 

  return (
    <div className="inner_header howItWorks">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
            <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid"/></Link></div>
            </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader resourcesswe"}>
        <div className="bg_red_1">
        <div className="container-fluid container-theme">

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="inner_title">Miscellany</h2>
              </div>
             
            </GridItem>
          </GridContainer>
        </div>
        </div>
        <div className="container mt-3">
        <div className="accordion" id="accordionExample">
  
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Contact Details</button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
     
     <div className="accordionparalist">
     <p>Email: support@edaface.com</p>
     <p>Contact Phone:  +27 67 201 8677 (Victoria)</p>
     <p>Physical Address:  House 1, Block C, Mabopane, Pretoria 0190, South Africa.</p>
       
       
        
     </div>
   

      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Our Communities
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      
      <div className="accordionparalist">
        <p>Join our rapidly growing communities:</p>
                    <h2>Facebook</h2>
                    <h2>Instagram</h2>
                    <h2>Telegram</h2>
                    <h2>Twitter</h2>
                    <h2>Youtube</h2>
        </div>

      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
      Further Reading
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <div className="accordionparalist">
       <p>For more on NFT please refer to:</p>
               <h2>(1). The Resource Centre of EdaFace NFT Marketplace <span><a href="www.nft.edaface.com">(www.nft.edaface.com)</a></span> </h2>  
               <h2>(2). The School of Cryptocurrencies of EdaFace Academy <span><a href="www.school.edaface.com"> (www.school.edaface.com)</a></span> </h2>  
               <h2>(3). Component 5 of “How to Use EdaFace” in The Resource Centre of EdaFace website <span><a href="www.edaface.com">(www.edaface.com)</a></span> </h2>  
               
               </div>        


      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
      Legal Information

      </button>
    </h2>
    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>All rights reserved. This document, its logo and any other trademarks, remain the property of EDA
(Pty) Ltd. No part of this document may be reproduced, stored in a retrieval system, or transmitted in
any form or by any means, electronic, mechanical, photocopying, recording, or otherwise without the
prior written permission of EDA.</p>
        <p>The information being provided in this document does not constitute investment advice, financial
advice, trading advice, or any other sort of advice and you should not treat any of the contents as
such. It is entirely for your information, education and entertainment. </p>
                         
<p>Do conduct your own due diligence and consult your financial advisor before making any investment
decisions.</p>
<p>EDA and its representatives strive to ensure accuracy of information provided in this document and
on all its platforms although they will not hold any responsibility for any missing or wrong
information. You understand that you are using any and all information available here at your own
risk.</p>
<p>The appearance of third-party advertisements and hyperlinks in this document and on all other EDA
platforms does not constitute an endorsement, guarantee, warranty, or recommendation by EDA. Do
conduct your own due diligence before deciding to use any third-party services</p>
<p>You also, hereby, acknowledge that you have read and agreed to all the Terms of Use, Disclaimers,
Listing Terms and Conditions, Privacy Policy, and all the legal requirements of EDA as outlined on
EdaFace website <span><a href="www.edaface.com">(www.edaface.com). </a></span></p>

      </div>
    </div>
  </div>
</div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
