import React, { useState, useEffect, useRef, useContext, } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect,useHistory,Link,useLocation} from "react-router-dom";
import './index.css';
import './styles.css';
import Mail from 'assets/images/registermail.png'
import Password from 'assets/images/registerpass.png'
import { useDispatch } from "react-redux";
import { Account_Connect } from "actions/redux/action";
// pages for this product
import Home from "views/Home.js";
import Create from "views/Create.js";
import CreateSingle from "views/Create-single.js";
import About from "views/About.js";
import EditProfile from "views/edit-profile.js";
import Myitems from "views/my-items.js";
import Following from "views/following.js";
import Privacypolicy from "views/Privacypolicy.js";
import Terms from "views/Termsofservice.js";
import Register from "views/register.js";
import Explore from "views/explore.js";
import Art from "views/art.js";
import Art1 from "views/art1";
import Artdetail from "views/artdetail";
import Info from "views/info.js";
import community from "views/communty.js"
import Whatisnft from './views/whatisnft.js';
import Typesofnft from './views/typesofnft.js';
import Globalrush from './views/globalrush.js';
import Nftusecase from './views/nftusecase.js';
import Investnft from './views/investnft.js';
import Probabilitynft from './views/probabilitynft.js';
import Terminology from './views/terminology.js';
import Nftmarketplaces from './views/nftmarketplaces.js';
import Transaction from './views/transaction.js';
import Howtocreate from './views/howtocreate.js';
import Howtobuy from './views/howtobuy.js';
import Howtosell from './views/howtosell.js';
import Edafacepartnership from './views/edafacepartnership.js';
import Conclusion from './views/conclusion.js';

import collectionlist from "views/collectionList.js"

import AddCollection from "../src/views/addcollection"
import UserCollection from "../src/views/usercollections"

import Activity from "views/activity.js";
import Search from "views/search.js";
import comingsoon from "views/comingsoon.js";
import Faq from "views/faq.js";
import List from "views/List";

// added by me
import '@metamask/legacy-web3'
import 'react-toastify/dist/ReactToastify.css';
import config from './lib/config';
import { toast } from 'react-toastify';
import Icon1 from "assets/images/icon1.jpg";
import Icon2 from "assets/images/wallet_03.png";
import {submitdata,generatepass,Forgotpassword,Changepassword} from './actions/v1/user'
import Reset from "./views/resetpassword"
// import { async } from "@firebase/util";
import { data } from "jquery";
import nfttc from "../src/views/nfttc";
import Bulkmint from "../src/views/bulkmint"
import { create } from "nouislider";
toast.configure();
let toasterOption = config.toasterOption;



export default function Indexs(props) {
   let history = useHistory();
   const location =useLocation()
    //console.log('locaaaaaa',location)
    var [changepassword,setChangePassword] = useState(location);
const[username,set_username]= useState('')
const[password,set_password]= useState('')
const [confirmpassword,set_confirmPassword] = useState('')
const [checkpass,setcheckpass]=useState()
const [checkemail,Set_checkemail]=useState('')
const [checkconpass,setcheckconpass] = useState('')
const[pass,setPass]=useState(true);
const[conpass,setConpass]=useState(true);
const [login,setLogin] = useState(false);
var [reslog,setReslog] = useState('login')
const [forgotpassword,setforgotPassword] = useState(false);
const [forgetpopup,setforgetpopup] = useState(false);
var [message,setMessage] = useState({});
var dispatch = useDispatch();

const handleOpenLogin = () => {
    setLogin(true);
}
const handleCloseLogin = () => {
    setLogin(false);
}
const handleOpenPassword = () => {
    //console.log("Pujuku");
    setforgotPassword(true);
}
const handleClosePassword = () => {
    setforgotPassword(false);
}
const[validation,setvalidate]=useState()
const[redirect,setredirect]=useState()
const onInputchange = (e)=>
{
const{id,value}=e.target
//console.log("id,value",id,value);
Set_checkemail('')
setcheckconpass('')
setcheckpass('')
if(id == "Email")
{
    if(checkemail !== '') Set_checkemail(''); 
    set_username(value)
}
if(id == "password")
{
    if(checkpass === false) setcheckpass();
    set_password(value)
}
if(id == 'confirmpassword'){
    set_confirmPassword(value)
}
}

async function Submit()
{
// //console.log("dsfbghsfgbh",username,password);
var data={username,password}
localStorage.getItem('logverify') ?  data.LoginOTP = localStorage.getItem('logverify') : data.LoginOTP = null;
data.from = "login";
// //console.log('fhgfdshfdhsf',data);
var emailcheck = await submitdata(data)
//console.log("dfgadgaedr",emailcheck.message.data.status)
setcheckpass(emailcheck.message.data)

if(emailcheck?.message?.data?.status === true)
{
    if(localStorage.getItem("logverify")!==null) localStorage.removeItem("logverify")
    setredirect(true)
    localStorage.setItem("registedEmail",username)
    dispatch({
        type: Account_Connect,
        Account_Detail: {
            Regname : emailcheck.message.data.data.Regname,
            Profile : emailcheck.message.data.data.Profile,
            Customurl : emailcheck.message.data.data.Customurl,
            _id : emailcheck.message.data.data._id,
        }
    })

    window.location="/"
 //history.push('/');
//location.href = '/'
}
else if(emailcheck?.message?.data ==="invalid email")
{
    // toast.warning(emailcheck?.message?.data)
    Set_checkemail(emailcheck.message.data)
}
else if(emailcheck.message.data == "Please Verify Your Email Address"){
    setReslog('verify')
}
}

const Forgetpassword = async()=>

{
   if(username == '')
   {
    setvalidate(false)
   }
    var data ={username}
var forgetpass = await generatepass(data)
//console.log("dftjhdtfjuy",forgetpass.message.data.Message);
if(forgetpass?.message?.data?.Message)
{
toast.success(forgetpass?.message?.data?.Message)
}
window.$('#login').modal('hide');
}

const redirectfun = async()=>
{
    // history.push("/Register")
    window.location.reload()
}

const forgetpassword = async()=>
{
    // window.$('#login').modal('hide');

    // window.$('#forgetpassword').modal('show');
 var elem = document.getElementById("login");
 elem.classList.remove("show");
 var elem1 = document.getElementById("forgetpassword");
 elem1.classList.add("show")
}
const closePassword = () =>{
    var elem1 = document.getElementById("forgetpassword");
    elem1.classList.remove("show");
}
const ResetPassword =async()=>
{
    var data = {username}
    var emailcheck = await Forgotpassword(data)
    //console.log('fhhgjhg',emailcheck)
    if(emailcheck?.message?.data == 'invalid email'){
        Set_checkemail(emailcheck?.message?.data)
    }
    else{
        
        // window.$("#login").modal("hide");
        // document.getElementById('logclose').click()
        setMessage({resetpassword:"We have e-mailed your password reset link!"})
        // toast.success("Password Change Link Has been Sent to your Email")
    }
   // var sendmail = await resetpassword()
   

}

useEffect(()=>{
    ChangePasswordCheck();
},[changepassword])

var ChangePasswordCheck = () =>{
    //console.log('changepassswo',forgetpopup,reslog,changepassword.pathname.split('/')[1])
    if(location.pathname.split('/')[1] == 'resetpassword'){
        setforgetpopup(true)
        setReslog(false)
    }
    else if(location.pathname.split('/')[1] == 'verify'){
        setReslog('login')
        setforgetpopup(false);
    }
    else{
        setforgetpopup(false)
    }
}

const Validate = (data) =>{
    var emailcheck = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var passcheck =/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    var i=0;
    //console.log('fgfhhgs',data)
    if(data.username === ''){
        Set_checkemail('Enter Email')
        ++i;
    }
    else if(!(emailcheck).test(data.username)){
        Set_checkemail("Enter Valid EmailId")
        ++i;
    }
    if(data.password == ''){
        setcheckpass('Enter Password')
        ++i;
    }
    else if(passcheck.test(data.password)==false){
        setcheckpass('Must have at least one small letter, a special character, a capital letter, and a digit')
        ++i;
    }
    if(data.password !== data.confirmpassword){
        setcheckconpass("Password And Confirm Password Must be Same")
        ++i;
    }
    return i;
}

var ChangePassword = async() =>{
    var data = {username,password,confirmpassword};
    data.key = location.pathname.split('/')[2] ;
    var validation = Validate(data);
    if(validation == 0){
        //console.log('fgfhhgs111',location.pathname.split('/')[2])
        data.from = "changepassword"
        var resp = await Changepassword(data)
        //console.log('fgfvfvgf',resp.message.data)
        if(resp.message.data =='success'){
            toast.success("Password Changed successfully")
            localStorage.setItem("registedEmail",username)
            window.location="/"
        }
        else{
            //console.log('jdshjsdhgjhsd',resp)
            if(resp.message.data=='Invalid Password'){
                toast.error('Invalid Password')
            }
            else if(resp.message.data=='invalid email'){
                toast.error('Invalid Email')
            }
            else if(resp.message.data == 'Please Enter New Password'){
                toast.error(resp.message.data);
                setcheckpass("Can't set Old password as New passsword")
            }
        }
    }
    else{
        toast.error("Validation Failed")
    }
}

var Resendemail = async()=>{
    setMessage('')
    var data = {username};
    if(data?.username){
        data.from = "resend";
        var resp = await Changepassword(data)
        //console.log('gfhfhs',resp)
        if(resp.message.data){
            // toast.success("Password Changed successfully")
            setTimeout(() => {
                setMessage({verifymail:"A fresh verification link has been sent to your email address."})
            },1000)
        }
    }
}

    return (
        <>
            <BrowserRouter basename="/" >
                <Switch>
                <Route path="/bulkmint" component={Bulkmint} />
                <Route path="/bulkmint/:type" component={Bulkmint} />

                    <Route path="/my-items/:activity?" component={Myitems} />
                    <Route path="/create-single" component={CreateSingle} />
                    <Route path="/create-multiple" component={CreateSingle} />
                    <Route path="/how-it-works" component={Faq} />
                    <Route path="/edit-profile" component={EditProfile} />
                    <Route path="/addCollections" component={AddCollection} />
                    <Route path="/addCollectionss/:name/:form" component={AddCollection} />
                    <Route path="/search" component={Search} />
                    <Route path="/my-items" component={Myitems} />
                    <Route path="/Register" component={Register} />
                    <Route path="/Following" component={Following} />
                    <Route path='/list/:address/:id' component={List} />
                    <Route path="/create/" component={Create} />
                    <Route path={"/create-polymultiple"} component = {Create} />
                    <Route path="/privacy-policy" component={Privacypolicy} />
                    <Route path="/about" component={About} />
                    <Route path="/community" component={community} />
                    <Route path="/terms-and-conditions" component={Terms} />
                    <Route path="/activity" component={Activity} />
                    {/* <Route path={["/explore","/explore/:Param_category"]} component={Explore} /> */}
                    <Route path="/explore/:Param_category/:Param_subcategory" component={Explore} />
                    <Route path="/explore/:Param_category" component={Explore} />
                    <Route path="/art" component={Art} /> 
                    {/* <Route path="/artdetail" component={Artdetail} /> 
                    <Route path="/art1/:category" component={Art1} />  */}
                    <Route path="/comingsoon" component={comingsoon} />
                    {/* <Route path="/info/" component={Info} /> */}
                    <Route path="/info/:owneraddress/:collectionaddress/:tokenidval" component={Info} />
                    <Route path="/user/:paramAddress" component={Myitems} />
                    <Route path="/whatisnft" component={Whatisnft} />
                    <Route path="/typesofnft" component={Typesofnft} />
                    <Route path="/globalrushof_nft" component={Globalrush} />
                    <Route path="/nftusecase" component={Nftusecase} />
                    <Route path="/investnft" component={Investnft} />
                    <Route path="/probabilitynft" component={Probabilitynft} />
                    <Route path="/terminology" component={Terminology} />
                    <Route path="/nftmarketplaces" component={Nftmarketplaces} />
                    <Route path="/transaction" component={Transaction} />
                    <Route path="/howtocreate" component={Howtocreate} />
                    <Route path="/howtobuy" component={Howtobuy} />
                    <Route path="/howtosell" component={Howtosell} />
                    <Route path="/edafacepartnership" component={Edafacepartnership} />
                    <Route path="/conclusion" component={Conclusion} />
                    {/* <Route path="/collection/:param/:customurl" component={UserCollection} /> */}
                    <Route path="/collection/:param/:customurl/:collAddr" component={UserCollection} />
                    <Route path={"/resetpassword/:key"} component={Register} />
                    <Route path={"/verify/:verifykey"} component={Register}/>
                    <Route path={"/login"} component={Register}/>
                    <Route path={"/nfttc"} component={nfttc}/>
                    <Route path="/:paramUsername" component={Myitems} />
                    <Route path="/" component={Home} />
                    <Route exact path="/*" component={Home}>
                        <Redirect to="/" />
                    </Route>
              

                </Switch>
            </BrowserRouter>




            {/* <div className="modal fade primary_modal" id="connect_modal" role="dialog" aria-labelledby="connect_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false" >
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="connect_modalLabel">Connect Your Wallet</h5>

                            <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ul className="connect_ul">
                                {

                                   
                                    <li>
                                        <div className=" connect_card" >
                                            <div className="card-body-mod">
                                                <div className="media follow_media">
                                                  

                                                    <div className="media-body flex_body">
                                                        <div className="w-100">
                                                            <div className="wallet-lists"
                                                            >
                                                                <p className="my-0 media_text"> <img src={Icon1} />Metamask</p>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </li>


                                }



                                {

                                    
                                    <li>
                                        <div className=" connect_card" >
                                            <div className="card-body-mod">
                                                <div className="media follow_media">
                                              

                                                    <div className="media-body flex_body">
                                                        <div className="w-100">
                                                            <div className="wallet-lists"
                                                            >
                                                                <p className="my-0 media_text"> <img src={Icon2} />Wallet Connect</p>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </li>

                                }
                               
                              

                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className={login == true ? "modal fade primary_modal show" : "modal fade primary_modal" } id="login" role="dialog" aria-labelledby="connect_modalCenteredLabel"  >
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        {reslog == 'login' &&
                        <>
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="connect_modalLabel">Login</h5>
                            <button type="button" className="close"  data-dismiss="modal" aria-label="Close">


                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ul className="connect_ul">
                                {

                                   
                                    <li>
                                        <div className=" connect_card" >
                                            <div className="card-body-mod">
                                                <div className="">
                                                  

                                                    <div className="">
                                                        <div className="w-100">
                                                        <div className="registeruserinput mt-0">
                   
                    <input type="text" id="Email" placeholder="Email" onChange={(e)=>onInputchange(e)}/>
                    </div>
                    
                                                        </div>
                                                        {validation === false&& <span className="text-danger"> email is required</span>}
                                                        {checkemail !== ''&& <span className="text-danger"> {checkemail}</span>}

                                                        
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </li>


                                }



                                {


                                    <li>
                                        <div className=" connect_card" >
                                            <div className="card-body-mod">
                                                <div className="">
                                              

                                                    <div className="">
                                                        <div className="w-100">
                                                        <div className="registeruserinput passowrdd mt-0">
                    
                    <input type={pass?"password":"text"} placeholder="Password" id="password" onChange={(e)=>onInputchange(e)}/>
                    <span className="faeyee"><i className={pass?"fas fa-eye-slash":"fas fa-eye"} type="button" id="eye" onClick={() =>setPass(!pass)}></i></span>
                    </div>

                                                        </div>
                                                        {checkpass === false&& <span className="text-danger">password invalid</span>}
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </li>

                                }
                               

                            </ul>
                            <a onClick={()=>Submit( )} className="reglogintbn">Login</a>
                            
                            <div className="alreadycacc">
                        <h3 className="tect-center">Don't have an account ?
          <a  className= "Login_det" onClick={()=>redirectfun()}>
            Register
          </a>
       </h3>
           
                    </div>
                    <a 
                    onClick={()=>{setReslog('reset')}}  //Forgetpassword();
                    className= "Login_detforgto"
                    //  onClick={() => {setforgetpopup(true)
                    // setLogin(false) }} 
                     >
            Forgot Password
          </a>
                        </div>
                        </>}
                     
          {reslog == 'reset' &&
            <><div className="modal-header text-center">
          <h5 className="modal-title" id="connect_modalLabel">Reset Password</h5>
          <button type="button" id="logclose" className="close"  data-dismiss="modal" aria-label="Close">


              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div className="modal-body">
          <ul className="connect_ul">
              {

                 
                  <li>
                      <div className=" connect_card" >
                          <div className="card-body-mod">
                              <div className="">
                                

                                  <div className="">
                                      <div className="w-100">
                                      {message?.resetpassword && <span className="text-danger forgotpsss"> {message?.resetpassword}</span>}
                                      <div className="registeruserinput mt-0">
 
  <input type="text" id="Email" placeholder="Email" onChange={(e)=>onInputchange(e)}/>
  </div>
  
                                      </div>
                                      
                                      

                                      
                                  </div>

                              </div>

                          </div>
                      </div>
                  </li>


              }

             

          </ul>
          <a onClick={()=>ResetPassword()} className="reglogintbn">Send Reset Link</a>
          
          <div className="alreadycacc">
      <h3 className="tect-center">Don't have an account ?
<a  className= "Login_det" onClick={()=>redirectfun()}>
Register
</a>
</h3>

  </div>
  <a 
  onClick={()=>{setReslog('login')}}  //Forgetpassword();
  className= "Login_detforgto"
  //  onClick={() => {setforgetpopup(true)
  // setLogin(false) }} 
   >
Login
</a>
      </div></>}

      {
        reslog == 'verify' &&
        <><div className="modal-header text-center mt-3">
   {message?.verifymail && <span className="text-danger forgotpsss">{message?.verifymail}</span>}
      <h5 className="modal-title " id="connect_modalLabel">Please verify your email address.</h5>
      
      <button type="button" id="logclose" className="close"  data-dismiss="modal" aria-label="Close">


          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div className="modal-body">
      <ul className="connect_ul">
          {

             
              <li>
                  <div className=" connect_card" >
                      <div className="card-body-mod">
                          <div className="">
                            

                              <div className="">
                                  <div className="w-100">
                                  <div className="registeruserinput mt-0">

                                 <p> Before proceeding, please check your email for a verification link. If you did not receive the email, click the button to resend.</p>
</div>

                                  </div>                                  
                              </div>

                          </div>

                      </div>
                  </div>
              </li>


          }

         

      </ul>
      <a onClick={()=>Resendemail()} className="reglogintbn">Resend Email</a>
<a 
onClick={()=>{setReslog('login')}}  //Forgetpassword();
className= "Login_detforgto"
//  onClick={() => {setforgetpopup(true)
// setLogin(false) }} 
>
Login
</a>
  </div></>
      }

      { forgetpopup &&
    <><div className="modal-header text-center">
    <h5 className="modal-title" id="connect_modalLabel">Change Password</h5>
    <button type="button" className="close"  data-dismiss="modal" aria-label="Close">


        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div className="modal-body">
    
    <ul className="connect_ul">
        {

           
            <li>
                <div className=" connect_card" >
                    <div className="card-body-mod">
                        <div className="">
                          

                            <div className="">
                                <div className="w-100">
                                <div className="registeruserinput mt-0">

<input type="text" id="Email" placeholder="Email" onChange={(e)=>onInputchange(e)}/>
</div>

                                </div>
                                
                                {checkemail !== ''&& <span className="text-danger"> {checkemail}</span>}

                                
                            </div>

                        </div>

                    </div>
                </div>
            </li>


        }
        {


<li>
    <div className=" connect_card" >
        <div className="card-body-mod">
            <div className="">
          

                <div className="">
                    <div className="w-100">
                    <div className="registeruserinput passowrdd mt-0">

<input type={pass?"password":"text"} placeholder="New Password" id="password" onChange={(e)=>onInputchange(e)}/>
<span className="faeyee"><i className={pass?"fas fa-eye-slash":"fas fa-eye"} type="button" id="eye" onClick={() =>setPass(!pass)}></i></span>
</div>

                    </div>
                    {checkpass !== '' && <span className="text-danger">{checkpass}</span>}
                </div>

            </div>

        </div>
    </div>
</li>

}
{


<li>
    <div className=" connect_card" >
        <div className="card-body-mod">
            <div className="">
          

                <div className="">
                    <div className="w-100">
                    <div className="registeruserinput passowrdd mt-0">

<input type={conpass?"password":"text"} placeholder="Confirm New Password" id="confirmpassword" onChange={(e)=>onInputchange(e)}/>
<span className="faeyee"><i className={conpass?"fas fa-eye-slash":"fas fa-eye"} type="button" id="eye" onClick={() =>setConpass(!conpass)}></i></span>
</div>

                    </div>
                    {checkconpass !== ''&& <span className="text-danger">{checkconpass}</span>}
                </div>

            </div>

        </div>
    </div>
</li>

}
       

    </ul>
    <a onClick={()=>ChangePassword()} className="reglogintbn">Change Password</a>
    
    <div className="alreadycacc">
<h3 className="tect-center">Don't have an account ?
<a  className= "Login_det" onClick={()=>redirectfun()}>
Register
</a>
</h3>

</div>
<a 
onClick={()=>{setReslog('login');history.push('/Register');setforgetpopup(false)}}  //Forgetpassword();
className= "Login_detforgto"
//  onClick={() => {setforgetpopup(true)
// setLogin(false) }} 
>
Login
</a>
</div></>
}
                    </div>
            
                </div>
             
            </div>


        </>
    )
}



// ReactDOM.render(<Indexs/>,document.getElementById('root')
// );