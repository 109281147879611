/*eslint-disable*/
import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FooterLogo from "../../assets/images/footer_logo.png";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import { Link, useLocation } from "react-router-dom";
import { sociallinksfunction } from '../../actions/v1/report';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux'
import { subscriberAction } from "actions/v1/token";
import config from '././../../lib/config'

const useStyles = makeStyles(styles);

export default function Footer(props) {

  const classes = useStyles();


  const [sociallinks, setsociallinks] = useState({})

  const [mail, setmail] = useState('');
  const Wallet_Details = useSelector(state => state.wallet_connect_context);


  useEffect(() => {
   

    sociallinksfunct()
   
  }, [Wallet_Details.WalletConnected, Wallet_Details.UserAccountAddr, localStorage.walletConnectType,mail, config.providercon])

  const mailstate = (e) => {
    ////console.log("Mialllll",e.target.value)
    setmail(e.target.value)
  }

  const sociallinksfunct = async () => {
    var soci = await sociallinksfunction()
   //console.log("soci",soci?.data?.soci)
    if (soci !== undefined && soci?.data?.soci !== undefined) {
      setsociallinks(soci?.data?.soci)
    }
    else {

      setsociallinks({})
    }
  }


  const newmailadd = async () => {
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
    if (mail != "") {
     if (!emailRegex.test(mail)) {
       return toast.error("Enter valid email")
     }
   }
     //console.log("mail>>>>",mail);
    var newmailData = await subscriberAction({ email: mail });
    if (newmailData && newmailData.data && newmailData.data && newmailData.data.success && newmailData.data.success == true) {
      toast.success(newmailData.data.message);
      setmail('');
    }
    // //console.log(">>>>newmailData",newmailData);
  }


  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });


 
  return (
    <>
      

    
      <footer className={footerClasses}>

<div className="container-fluid container-theme container-lg">
 <div className="footergrid">
<div className="foote1">
<div className="footone">
      <h4>Company</h4>
      <ul>
        <li><a href="https://edaface.com/vision" target="_blank">Vision</a></li>
        <li><a href="https://edaface.com/mission" target="_blank">Mission</a></li>
        <li><a href="https://edaface.com/core" target="_blank">Core Values</a></li>
      
        <li><a href="https://edaface.com/branding" target="_blank">Branding</a></li>
        <li><a href="https://edaface.com/founders" target="_blank">Team</a></li>
        <li><a href="https://edaface.com/" target="_blank">Careers</a></li>
        <li><a href="https://edaface.com/eda" target="_blank">FAQ</a></li>
      </ul>
</div>
<div className="foottwo">
      <h4>Products</h4>
      <ul>
        <li><a href="https://news.edaface.com/" target="_blank">EDA Token</a></li>
        <li><a href="https://www.digitalclinicnet.com/" target="_blank">Digital Clinic</a></li>
        <li><a href="https://www.eda.social/" target="_blank">School of Cryptos </a></li>
        <li><a href="https://launchpad.edaface.com/white-paper" target="_blank">Litepaper</a></li>
        <li><a href="https://launchpad.edaface.com/white-paper/" target="_blank">Whitepaper</a></li>
        <li><a href="https://edaface.com/welfare-donations" target="_blank">Welfare Donations</a></li>
        <li><a href="https://news.edaface.com/" target="_blank">How to use EdaFace</a></li>
        
      </ul>
</div>
</div>
<div className="footthree">
<img src={FooterLogo} alt="XDCNFT" />
     <h3 className="subxcribe">Subscribe to Our Newsletter</h3>
     <div className="newsletter_grp">
                  <input type="email" className="newsletter_inp" placeholder="Your Email Address" value={mail} onChange = {mailstate}
                     />
                  <div className="newsletter_grp_append">
                    <button className="btn m-0" type="button" id="news_submit" onClick={()=>{newmailadd()}}>Subscribe</button>
                  </div>
                </div>
</div>
<div className="foote">
{/* <div className="footfour">
      <h4>Legal</h4>
      <ul>
        <li><a href="/terms-and-conditions">Terms of Use</a></li>
        <li><a href="/privacy-policy">Privacy Policy</a></li>
        <li><a href="/how-it-works">FAQ</a></li>
      
       
      </ul>
</div> */}

<div className="footfour">
      <h4>Legal</h4>
      <ul>
        <li><a href="https://edaface.com/terms" target="_blank">Terms of Use</a></li>
        <li><a href="https://edaface.com/privacy" target="_blank">Privacy Policy</a></li>
        <li><a href="https://edaface.com/disclaimer" target="_blank">Disclaimers</a></li>
        <li><a href="https://edaface.com/tc" target="_blank">Listing T&C</a></li>
        <li><a href="/nfttc" target="_blank">NFT T&C</a></li>
        <li className="mt-5 pt-2"><a href="https://edaface.com/contact" target="_blank" style={{color:"#03377f"}}>Con<span  className="reoranga">tact </span>Us</a></li>
       
      </ul>
</div>
<div className="footfive">
      <h4>Community</h4>
      <ul>
        <li><a href={sociallinks && sociallinks.Facebook && (sociallinks.Facebook != "" ? sociallinks.Facebook : "#")} target="_blank">Facebook</a></li>
        <li><a href={sociallinks && sociallinks.Discord && (sociallinks.Discord != "" ? sociallinks.Discord : "#")} target="_blank">Discord</a></li>
        <li><a href={sociallinks && sociallinks.Instagram && (sociallinks.Instagram != "" ? sociallinks.Instagram : "#")} target="_blank">Instagram</a></li>
        <li><a href={sociallinks && sociallinks.Reddit && (sociallinks.Reddit != "" ? sociallinks.Reddit : "#")} target="_blank">Reddit</a></li>
        <li><a href={sociallinks && sociallinks.Telegram && (sociallinks.Telegram != "" ? sociallinks.Telegram : "#")} target="_blank">Telegram</a></li>
        <li><a href={sociallinks && sociallinks.Twitter && (sociallinks.Twitter != "" ? sociallinks.Twitter : "#")} target="_blank">Twitter</a></li>
        <li><a href={sociallinks && sociallinks.Youtube && (sociallinks.Youtube != "" ? sociallinks.Youtube : "#")} target="_blank">Youtube</a></li>
      </ul>
</div>
</div>
 </div>
  
  <div className="bordrline"></div>
  <div className="mt-4">
    <p className="copyright_txt">{`Copyright © ${new Date().getFullYear()}. EdaFace NFT Marketplace. All Rights Reserved.`}</p>
  </div>
</div>
</footer>
    </>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
