
import React, {useEffect,useState} from "react";
import {useHistory} from "react-router-dom";

import config from '../../lib/config';


export default function Convert(props) {
        // const [ UserAccountAddr, Set_UserAccountAddr] = React.useState('');
        // const [ UserAccountBal, Set_UserAccountBal] = React.useState(0);
        // const [ WalletConnected, Set_WalletConnected] = React.useState(false);
        // const [ Accounts, Set_Accounts] = React.useState('');
        // const [ AddressUserDetails, Set_AddressUserDetails] = useState({});
        // const [ Service_Fee,set_Service_Fee] = useState(0);
        // const[ Wen_Bln,set_Wen_Bln]=useState(0);
        const[ val,set_val]=useState('')
        // const [convertVal, setConvertVal] = React.useState(0);
        const AfterWalletConnected=()=>{

        }
        var history=useHistory();
        var {
        item,convertVal,coinName
        } = props;

        useEffect(()=>{
                var nev=item*convertVal
                nFormatter(nev,coinName)
                // //alert('1')
        });

function nFormatter(num, coinName) {
  var digits;
  // //console.log("num",num,coinName)
  var cn=String(coinName).toLowerCase()
  if(cn===String(config.tokenSymbol).toLowerCase()){
    digits=2
  }
  else{
    digits=5
  }
  var si = [
    { value: 1, symbol: "   " },
    { value: 1E3, symbol: " k " },
    { value: 1E6, symbol: " M " },
    { value: 1E9, symbol: " B " },
    { value: 1E12, symbol: " T " },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  var bl=(num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol
  set_val(bl)
  return bl;
}


    return (
       <>
    
     

    {/* <> {item/convertVal}</> */}
                <> {item > 0
              ?  (val!==0?val:"")
              :  (item/convertVal)}</>
                
       


      </>     
    )
}
