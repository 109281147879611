//kslp
import React, { useEffect, useState, useRef } from "react";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link, useParams,useLocation } from "react-router-dom";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import { Scrollbars } from 'react-custom-scrollbars';
import HeaderLinks from "components/Header/HeaderLinks.js";
import {
  CollectiblesList_Home,
  topCreatorsApi,
  Explorecollection
} from '../actions/v1/token';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";
import { getcmslistinhome, getpromotion } from '../actions/v1/report'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import range from "../../src/assets/images/dollar.png";
import collect from "../../src/assets/images/category.png";
import sales from "../../src/assets/images/sales.png";
import Collection from "../../src/assets/images/collection.png";
import filter from "../../src/assets/images/sort.png";
// mysid
import TokenCard from './separate/TokenCard'
import axios from 'axios';
import '@metamask/legacy-web3'
import { toast } from 'react-toastify';
import config from '../lib/config';
import { useSelector } from "react-redux";
import isEmpty from "lib/isEmpty";





toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function Explore(props) {

  const classes = useStyles();

  const Wallet_Details = useSelector(state => state.wallet_connect_context);
const location = useLocation()
  const { ...rest } = props;
  const { Param_category,Param_subcategory } = useParams()
  //console.log("Dabhfadfbh",Param_category,Param_subcategory,useParams());
  const [Categorylist, setCategorylist] = useState([]);
  const [CatName, setCatName] = useState('All');
  const [newcollstate, Setnewcollstate] = useState({});
  const [hotcol, Sethotcoll] = useState({});
  const [getcmslistinhome1, setgetcmslistinhome1] = useState({})
  const [getcmslistinhome12, setgetcmslistinhome12] = useState({})
  const [Promotion_List, Set_Promotion_list] = useState({})
  const [topcreatorsection, settopcreatorsection] = React.useState({});
  const [typefunfix11, Settypefun] = useState('');
  const [CatBasedTokenList, setCatBasedTokenList] = useState({ 'loader': false, 'All': { page: 1, list: [], onmore: true } });
  const [LoadDisable, Set_LoadDisable] = useState(false);
  const [TokenList, setTokenList] = useState([]);
  const [Creatorcount, setCreatorcount] = useState(0);
  const [item, Set_item] = useState({});
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [Bids, Set_Bids] = useState([]);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [convertVal, setConvertVal] = React.useState(0);
  const [categorytype,setCategorytype] = useState('category')





  useEffect(() => {

//console.log("xfbdsfzbh",location.state);
    getInit();
    // if(getcmslistinhome1.length==0){
    Hotcoll();
    // }
  }, [,Param_category,Param_subcategory])


  useEffect(() => {
    TokenListCall()
  }, [CatName])



  useEffect(() => {
    //console.log("dfbhdtfh",Param_category);
    if(Param_category && !Param_subcategory)
    {
    catChange(Param_category)
    }
    else if(Param_subcategory){
      catChange(Param_subcategory)
    }
  }, [Param_category])


  // async function catChange(name, type) {
  //   //console.log("name,type",name, type);
  //   if (name !== CatName) {
  //     setCatName(name);
  //     Setnewcollstate(type)
    
  //   }
  // }




  async function catChange(name, type) {
 
    setCatBasedTokenList([])
   
   
    if (name !== CatName) {
      setCatName(name);
      Setnewcollstate(type)
    
    }
  
  }








  async function getInit() {
    // TokenListCall();
    getcmslistinhomes();
    getcmslistinhomes1();
    promotionData();
    CategoryListCall();
    topCreatorsFUnc();
    // timeAuctionFUnc();
    // recentNftFUnc();
    // ;}

  }

  const Hotcoll = async () => {
    var collectiongal = await Explorecollection();
    //console.log("explorecomming", collectiongal.data.data.collections)
    Sethotcoll(collectiongal.data.data.collections)
  }


  const getcmslistinhomes = async () => {
    var reqdata = {
      load: 'home'
    }
    var convers = await getcmslistinhome(reqdata);
    if (convers && convers.data) {
      setgetcmslistinhome1(convers.data)
    }
  }
  const getcmslistinhomes1 = async () => {
    var reqdata = {
      load: 'tophome'
    }
    var convers = await getcmslistinhome(reqdata);
    if (convers && convers.data) {
      setgetcmslistinhome12(convers.data)
    }
  }
  const promotionData = async () => {
    var test = await getpromotion();
    ////////console.log("Dsajdashdjasgdaskdkagshdghasgkd",test)
    if (test && test.userValue) {
      Set_Promotion_list(test.userValue[0])
    }
  }



  async function CategoryListCall() {
    axios
      .get(`${config.vUrl}/token/category/list`)
      .then(response => {
        if (response && response.data && response.data.list) {
          //console.log("gvjfbgvhbftfg",Param_category,response.data.list);
          
          if(Param_category === "All"){
            setCategorylist(response.data.list);
            //console.log('vallllll222222',response.data.list)
            setCategorytype('category');
          }
          else{
                response.data.list.map((val)=>{
                  if(val.name === Param_category){
                    //console.log('valllll11111',val)
                    var arr = [];
                    val.subcategory.map((sub)=>{
                      arr.push({name:sub});
                    })
                    setCategorylist(arr);
                    setCategorytype('subcategory');
                  }
                })
          }
          
        }
      })
      .catch(e => console.log(e))
  }



  const topCreatorsFUnc = async () => {
    var topCraete = await topCreatorsApi();
    if (topCraete && topCraete.data) {
      settopcreatorsection(topCraete.data)
    }
  }



  async function TokenListCall(data = {}) {
    var currAddr = Wallet_Details.UserAccountAddr
    var name = CatName;
    console.log("vdscvffsd", CatName);
    if (data.CatName) {
      // //console.log("gbifrbirhdb", data.CatName);
      name = data.CatName
    }

    var from = typefunfix11;
    // //console.log("vjbdsvchb", typefunfix11);
    if (newcollstate == 'collection') {
      //console.log("Other Filters")
      var name = CatName
      var payload = {
        limit: 10,
        page: (CatBasedTokenList[name] && CatBasedTokenList[name].page) ? CatBasedTokenList[name].page : 1,
        currAddr: currAddr,
        customurl: name,
        from: "collection",
        // saleType:from,
      }

    }
    else {
      //console.log("Collection Filters")
      var payload = {
        limit: 10,
        page: (CatBasedTokenList[name] && CatBasedTokenList[name].page) ? CatBasedTokenList[name].page : 1,
        currAddr: currAddr,
        CatName: name,
        RealCatName: Param_category,
        from: "filter",
        type: Param_subcategory ? "subcategory" : "category"
        // saleType:from,
      }
    }

    Set_LoadDisable(true)
     //console.log("loadMore>>>", payload, CatBasedTokenList[name], CatBasedTokenList)
    CatBasedTokenList.loader = true;
    setCatBasedTokenList(CatBasedTokenList);
     //console.log("devfkjdfgdhvbihfb", CatBasedTokenList);
    var resp = await CollectiblesList_Home(payload);
     //console.log("dbcfhbdsjhvcbjshd", CatBasedTokenList,resp);
    CatBasedTokenList.loader = false;
    setCatBasedTokenList(CatBasedTokenList);
    Set_LoadDisable(false)
    //console.log("fgdnjdghmjdfghmk",resp && resp.data && resp.data.from === 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0);
    if (resp && resp.data && resp.data.from === 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      setTokenList(TokenList.concat(resp.data.list));
      setCreatorcount(resp.data.list.length);
      if (typeof CatBasedTokenList[name] == 'undefined') {
        CatBasedTokenList[name] = { page: 1, list: [] };
      }
      CatBasedTokenList[name].list = CatBasedTokenList[name].list.concat(resp.data.list);
      setCatBasedTokenList([]);
      
      setCatBasedTokenList(CatBasedTokenList);

    }
    
    else {
      if(CatBasedTokenList[name]?.onmore)
      {
      CatBasedTokenList[name].onmore = false;
      }
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
  }



  const onLoadMore = () => {
    //console.log("sdgvfsdgvdsfcgdgvdf", CatBasedTokenList[CatName].page);
    CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page + 1;
    setCatBasedTokenList(CatBasedTokenList);

    TokenListCall({
      page: CatBasedTokenList[CatName].page + 1
    });
  }




  return (

    <div>

      <div className="home_header">


        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<>
            <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
            <div className="dark_logo"><Link to="/">
              <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
          </>}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />

        <section className="navtabs pb-2 recentlyadded explore">
          <div className="container-fluid container-theme container-lg">
            <div className="flex_block_mob scrollheight align-items-center justify-content-center mb-5">
          
              {/* <h1 className="title_text_white">{"Explore"+" "+" " +CatName }</h1>
           
{
(CatName==="art"||CatName==="Collectibles"||CatName==="games")&&
              <p class="artnft">{'The Home of Digital  '+CatName}</p>
} */}
          
          
          
          { (Param_category === "Art") &&<div><h1 className="title_text_white">Art NFT</h1>

             <h1 className="homeofart">The Home of Digital Arts</h1></div>
             }
             
              { (Param_category === "Collectible") &&<div><h1 className="title_text_white">Collectible NFT</h1>
             <h1 className="homeofart">Discover mystical Digital Collectibles of various species</h1></div>
             }
              { (Param_category === "Gaming") &&<div><h1 className="title_text_white">Gaming NFT</h1>
             <h1 className="homeofart">Welcome to Gaming NFTs, where the present meets the future</h1></div>

             }
             {
             
             (CatName!=="art")||(CatName!=="Collectibles")||(CatName!=="games")&&

              <h1 className="title_text_white">{"Explore"+" "+" " +CatName }</h1>}

          
          
          
          
          
          
          
          
          
          
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-12">
                  <div className="row">

                    <div className="col-lg-10 col-sm-10 col-12 col-md-10 explore_enf">

                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={collect} className="filteradd" />
                            Category</div>}
                          dropdownList={[
                            <div className="buernotir">
                              <Scrollbars style={{ height: 150 }} className="nto_scrol_div">
                                <ul className="explorebg">
                                {Categorylist.length > 0 && Categorylist.map((itempro) => {
                                    return (
                                      <li className="px-3">
                                        <div className="media">

                                          <div className="media-body">
                                          <Link to={Param_subcategory ? `/explore/${Param_category}/${itempro.name}` : `/explore/${itempro.name}/All` }>
                                              <div onClick={() => catChange(itempro.name)}>

                                                <p className="mt-0 banner_desc_user mb-0 not_banner_dessc"  >{itempro.name}</p>


                                              </div>
                                              </Link>
                                          </div>
                                        </div>

                                      </li>
                                    
                                    )
                                  })}

                                </ul>
                              </Scrollbars>



                            </div>
                          ]}
                        />
                      </div>


                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={Collection} className="filteradd" />
                            Collections </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 150 }} className="nto_scrol_div">
                                <ul className="explorebg">
                                  
                                {hotcol.length > 0 &&
                                    hotcol.map((itemcoll, index) => {
                                      return (
                                     
                                        <li className="px-3" onClick={() =>
                                          catChange(itemcoll.customurl, 'collection')}>
                                          <div className="media">
                                            <div className="media-body">
                                              <div>
                                                <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >{itemcoll.collectionName}</p>

                                              </div>

                                            </div>
                                          </div>

                                        </li>

                                      
)
})}

                                </ul>
                   
                              </Scrollbars>


                            </div>
                          ]}
                        />
                      </div>

                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={sales} className="filteradd" />
                            Sales Type </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 150 }} className="nto_scrol_div">
                                <ul className="explorebg">
                                  <li className="px-3"  onClick={() =>
                                    catChange('FixedPrice')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Buy Now</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3"onClick={() =>
                                    catChange('TimedAuction')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Time Auctions</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('UnLimitedAuction')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Unlimited Auctions</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                </ul>
             
                              </Scrollbars>



                            </div>
                          ]}
                        />
                      </div>
                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={range} className="filteradd" />
                            Price Range </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 100 }} className="nto_scrol_div">
                  
                                <ul className="explorebg">
                                {
                                  <li className="px-3"   onClick={() =>
                                      catChange('LowtoHigh')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div >
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Price:Low to High</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                          }
                                  <li className="px-3" onClick={() =>
                                    catChange('HightoLow')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div >
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Price:High to Low</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                       
                                </ul>
                  
                              </Scrollbars>



                            </div>
                          ]}
                        />
                      </div>
                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={filter} className="filteradd" />
                            Filter & Sort </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 150 }} className="nto_scrol_div">
                                <ul className="explorebg">
                
                                  <li className="px-3" onClick={() =>
                                    catChange('Recently')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Recently</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('FixedPrice')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Buy Now</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('TimedAuction')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Time Auctions</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('UnLimitedAuction')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Unlimited Auctions</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3"
                                    onClick={() =>
                                      catChange('LowtoHigh')} >
                                    <div className="media">
                                      <div className="media-body">
                                        <div >
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Price:Low to High</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3"  onClick={() =>
                                    catChange('HightoLow')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div >
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Price:High to Low</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                              
                                </ul>
                              </Scrollbars>



                            </div>
                          ]}
                        />
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              
            </div>
            <div className="tab-content explore_tab_content mt-0" id="nav-tabContent">
              <div className="tab-pane fade show active" id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
                <div className="mt-3">
                  <div className="m-0">
                    <div className="eploregrid">
                     <div className="row">
                          
                     {
                        (CatBasedTokenList
                          && CatName
                          && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].list
                          && CatBasedTokenList[CatName].list.length > 0)
                          ? (CatBasedTokenList[CatName].list.map((item) => {
                            //console.log("itemmmmmmm",item);
                            return (
                              (isEmpty(item.tokenowners_current) !== true )?
                            <div className="col-lg-3 col-md-4 col-sm-6 pb-5">
                                    <TokenCard
                                  item={item}
                                  Set_item={Set_item}
                                  LikedTokenList={LikedTokenList}
                                  setLikedTokenList={setLikedTokenList}
                                  // hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                                  
                                  // PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                  // PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                  // PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                  Set_Bids={Set_Bids}
                                  Bids={item.myBid}
                                  Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                  Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                  Set_MyTokenBalance={Set_MyTokenBalance}
                                  Set_MyTokenDetail={Set_MyTokenDetail}
                                  Set_AllowedQuantity={Set_AllowedQuantity}
                                  Set_YouWillPay={Set_YouWillPay}
                                  Set_YouWillPayFee={Set_YouWillPayFee}
                                  Set_YouWillGet={Set_YouWillGet}
                                  // Burn_Click={BurnForwardRef.current.Burn_Click}
                                  // CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                  // SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                  // ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                  setConvertVal={setConvertVal}
                                  convertVal={convertVal}
                                />
                                  </div>
                             :("")
                             )
                           })) :
                           // <div className="proposal_panel_overall text-center">
                           <div className="text-center py-5 col-12 notfound">
                             <div className="text-center py-3  no_items_row">
                               <p className="not_found_text">No items found</p>
                               <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                               <div className="mt-3">
                                 {/* <Button className="create_btn"><Link to="/">Browse Marketplace</Link></Button> */}
 
                               </div>
                             </div>
                           </div>
                       }
                     </div>
                    </div>
                </div>

              </div>

            </div>



          </div>
         
              <div className="text-center mt-4 w-100 ">
               
                <div id="spinButs" className="overflow-hidden">
                  <Button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" disabled={LoadDisable}  onClick={()=>onLoadMore()} style={{ display: "flex" }}>
                    Load More <span>
                   
                    </span>

                  </Button>
                </div>
              </div>
            </div>
        </section>


        <div id="logo_overlay" className="logo_ovelay">
          <Footer />
        </div>
      </div>
    </div>

  );
}
