

import React from "react";

import config from '../../lib/config';

import Avatars from "views/Avatar";
import { Link } from "react-router-dom";


export default function UserCard(props) {

  var {
    User
  } = props;

  return (

    <div className="card my-0">
      <div className="card-body px-3">
        <div className="media follow_media">
          <div className="img_media_new  mr-3">

          {User && User.image !== "" ?
              <Link to={User.customurl !== "" ? `/${User.customurl}` : `/user/${User.curraddress}`}     >
                <img src={`${config.Back_URL}/images/${User._id}/${User.image}`} alt="User" className="img-fluid" onClick={User.customurl !== "" ? `/${User.customurl}` : `/user/${User.curraddress}`} />
              </Link>
              :
              <Link to={`/user/${User.curraddress}`}     >
                <div onClick={User.customurl !== "" ? `/${User.customurl}` : `/user/${User.curraddress}`}>
                  <Avatars item="img-fluid" /></div>
              </Link>
            }
   
                {/* <div >
                  <Avatars item="img-fluid" /></div> */}
           
          </div>
          <div className="media-body flex_body">
            <div>
             
                {/* <p className="my-0 media_text" title="">0x123456789000</p> */}
                <Link href={User.customurl !== "" ? `/${User.customurl}` : `/user/${User.curraddress}`}     >
                <p className="my-0 media_text" title={'User :' + User.name !== "" ? User.name : User.curraddress}>{User.name !== "" ? User.name : User.curraddress}</p>
              </Link>

            </div>

          </div>
        </div>


      </div>
    </div>

  )
}
