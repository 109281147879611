import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Create(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const location = useLocation()

  const [location_pathname, Set_location_pathname] = useState(location.pathname.split('/')[1]);

  useEffect(()=>{
    if(location !== location_pathname){
      Set_location_pathname(location.pathname.split('/')[1]);
    }
  },[location.pathname])
  console.log('jfksjgjskgs',location_pathname,location_pathname)
  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
            <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid"/></Link></div>
            </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <Link to={location_pathname == 'create-polymultiple' ? "/create" : "/" }>
                    <i className="fas fa-arrow-left mr-3 arrow_back"></i>
                  {/* <img src={require("../assets/images/arrow_icon.png")} alt="logo" className="arrow_icon"/> */}
                </Link>
                <h3 className="section-head mb-0">Create NFT</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-4 create_block">
          <GridContainer>
            { location_pathname == 'create-polymultiple' ?
            <GridItem xs={12} sm={12} md={12}>
              <p className="create_para">Here, you can mint your bulk NFTs either as image alone, image with audio, video, or in layers. </p>
              <div className="create_box_panel">
                <Link to="/bulkmint/image" className="create_box">
                  <h2>Image</h2>
                  <img src={require("../assets/images/single_icon.png")} alt="logo" className="img-fluid"/>
                </Link>
                <Link to="/bulkmint/audio" className="create_box">
                  <h2>Audio</h2>
                  <img src={require("../assets/images/multiple_icon.png")} alt="logo" className="img-fluid"/>
                </Link>
                <Link to="/bulkmint/video" className="create_box createCollection">
                  <h2>Video</h2>
                  <img src={require("../assets/images/create-collections.png")} alt="logo" className="img-fluid"/>
                </Link>
                <Link to="/bulkmint/layer" className="create_box createCollection">
                  <h2>Layer By Layer</h2>
                  <img src={require("../assets/images/create-collections.png")} alt="logo" className="img-fluid"/>
                </Link>
              </div>
              <p className="create_para mt-3">We do not own your private keys and cannot access your funds without your confirmation</p>
              {/* <p className="create_para mt-3">If you'd like to create, create collections then click 'Create Collection'</p> */}
            </GridItem>
            :
            <GridItem xs={12} sm={12} md={12}>
              <p className="create_para">Choose “Single” if you want your collectible to be one of a kind or “Multiple” if you want to sell one collectible multiple times</p>
              <div className="create_box_panel">
                <Link to="/create-single" className="create_box">
                  <h2>Single</h2>
                  <img src={require("../assets/images/single_icon.png")} alt="logo" className="img-fluid"/>
                </Link>
                <Link to="/create-multiple" className="create_box">
                  <h2>UniMultiple</h2>
                  <img src={require("../assets/images/multiple_icon.png")} alt="logo" className="img-fluid"/>
                </Link>
                <Link to="/create-polymultiple" className="create_box createCollection">
                  <h2>PolyMultiple</h2>
                  <img src={require("../assets/images/create-collections.png")} alt="logo" className="img-fluid"/>
                </Link>
                {/* <Link to="/addCollections/''/''/" className="create_box createCollection">
                  <h2>Create Collection</h2>
                  <img src={require("../assets/images/create-collections.png")} alt="logo" className="img-fluid"/>
                </Link> */}
              </div>
              <p className="create_para mt-3">We do not own your private keys and cannot access your funds without your confirmation</p>
              <p className="create_para mt-3">If you'd like to create, create collections then click 'Create Collection'</p>
            </GridItem>
            }
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
