import React, { useEffect,useState,useRef } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import TokenCard from '../views/separate/TokenCard'


const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #3d2524;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #3d2524;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Following(props) {
 

  const classes = useStyles();


  const { ...rest } = props;


  return (
    <div className="inner_header">
       
    
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
            <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid"/></Link></div>
            </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className="bg_red_1">
        <div className="container-fluid container-theme">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <h2 className="inner_title">Following</h2>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        </div>
        <div className="container-fluid container-theme mt-3">
        <div className=" masonry myitemcards m-0 ma_no_gap">
        {
			<div className="row">
                      
      <div className="col-lg-3 col-md-4 col-sm-6">
      <TokenCard
      />
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6">
      <TokenCard
      />
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6">
      <TokenCard
      />
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6">
      <TokenCard
      />
      </div>
</div>
            
           					
            }
                   </div>

        </div>
      </div>
      <Footer/>
         </div>
  );
}
