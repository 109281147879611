import React, { useState, useEffect } from "react";
import DETH_ABI from '../ABI/DETH_ABI.json';
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import { getUserCollection } from '../actions/v1/user'
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useHistory, useLocation } from "react-router-dom";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
// import masonary1 from 'assets/images/masonary_04.png'
// import Loader from 'assets/images/loader.png';
import $ from 'jquery';
import '@metamask/legacy-web3'
import Select from 'react-select'
import CONFIG from '../lib/config'
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Convert from 'views/separate/Convert';
import isEmpty from "lib/isEmpty";
import Web3 from 'web3';
import Web3Utils from 'web3-utils'
import MULTIPLE from '../ABI/MULTIPLE.json';
import SINGLE from '../ABI/SINGLE.json';
import { getReceipt } from '../actions/v1/getReceiptFunc';
import {
  ipfsImageHashGet,
} from '../actions/v1/token';
import { connectWallet, WalletConnect } from '../views/hooks/useWallet';
import {
  GetCategoryAction,
  CreateTokenValidationAction,
  TokenAddItemAction,
  TokenAddOwnerAction,
  WenlamboConvert,
} from '../actions/v1/token';
import { Account_Connect, Account_disConnect } from "actions/redux/action";
//redux
import { AddressUserDetails_GetOrSave_Action } from "../actions/v1/user";
import { network } from "./network"
import Market from "../ABI/market"
import LazyLoader from "./lazyloader";
import LazyLoad from "react-lazyload";


var initialformvalue = {
  currencySymbol: '',
  tokenSymbol: '',
  tokenAddr: {},
  tokenABI: {},
  singleContract: "",
  multipleContract: "",
  BNBPROVIDER: "",
  networkVersion: '',
  chainId: '',
  Chainid: 0,
  trade: "",
}

toast.configure();
const dashboardRoutes = [];
let toasterOption = CONFIG.toasterOption;
const useStyles = makeStyles(styles);
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function CreateSingle(props) {
  const dispatch = useDispatch();


  const Wallet_Details = useSelector(state => state.wallet_connect_context);

  // const networkoption = [
  //   { value: config.currencySymbol, label: config.currencySymbol },
  //   { value: config.tokenSymbol, label: config.tokenSymbol },
  // ];

  const classes = useStyles()
  const { ...rest } = props;
  const history = useHistory();
  



  var pathVal = '';
  const location = useLocation();
  const state = location.state.state

  const [location_pathname, Set_location_pathname] = useState(state?.type);

  const [CollectibleType, Set_CollectibleType] = useState(state?.type);
  const [TokenFilePreUrl, setTokenFilePreUrl] = useState("");
  const [imgfilename, setimgfilename] = useState('');
  const [fileSizes, setfilesize] = useState(0)
  const [TokenFile, setTokenFile] = useState(state.image);
  const [TokenFilePreReader, setTokenFilePreReader] = useState("");
  const [ValidateError, setValidateError] = useState({ TokenPrice: '' });
  const [PutOnSale, setPutOnSale] = useState(false);
  const [PutOnSaleType, setPutOnSaleType] = useState('UnLimitedAuction');
  const [TokenBid, setTokenBid] = useState(true);
  const [Unlockoncepurchased, Set_Unlockoncepurchased] = useState(false);
  const [MinimumBid, Set_MinimumBid] = useState(0);
  const [Clocktime, set_Clocktime] = useState('');
  const [MintHashVal, Set_MintHashVal] = useState('');
  const [EndClocktime, set_EndClocktime] = useState("");
  const [TokenPrice, setTokenPrice] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [TokenName, setTokenName] = useState(state.name);
  const [TokenDescription, setTokenDescription] = useState('');
  const [TokenRoyalities, setTokenRoyalities] = useState('0');
  const [TokenProperties, setTokenProperties] = useState('');
  const [UnLockcontent, Set_UnLockcontent] = useState("");
  const [TokenQuantity, Set_TokenQuantity] = useState(state.amount);
  const [CoinName, setCoinNames] = useState("");
  const [ApproveCallStatus, setApproveCallStatus] = useState('init');
  const [ApproveTrade, setApproveTrade] = useState('init');

  const [StartDate, Set_StartDate] = useState('Select Start Date');
  const [EndDate, Set_EndDate] = useState('Select End Date');
  const [priceoption, setPriceoption] = React.useState([]);
  const [FormSubmitUserClicked, Set_FormSubmitUserClicked] = useState(false);
  const [UserAccountBal, Set_UserAccountBal] = useState(0);
  const [TokenCategory, setTokenCategory] = useState({ label: '' });
  const [UserCollection, setUserCollection] = useState('');
  const [CollectionList, setCollectionList] = useState([]);
  const [CategoryOption, setCategoryOption] = useState([]);
  const [TokenImages, setTokenImage] = useState({});
  const [ipfshash, setIpfsHash] = useState(state.ipfshash);
  const [ipfshashurl, setipfshashurl] = useState('');
  const [additionalImage, setAdditionalImage] = useState('') 
  const [ipfsmetatag, set_ipfsmetatag] = useState(state.meta);
  const [MintCallStatus, setMintCallStatus] = useState('init');
  const [ContractAddressUser, set_ContractAddressUser] = useState(ContractAddressUser_val);
  const [networkoption, setnetworkoption] = React.useState([]);
  const [NetworkID, setNetworkID] = React.useState(Wallet_Details.networkConfiguration.Chainid);
  
  var networkinit;
  
  const [config, setconfig] = React.useState(initialformvalue);
  const [iscurrency, setiscurrency] = React.useState();
const[accountDetailssss,setaccountDetailssss]=useState({})
const [decimal, setdecimal] = React.useState();
const[check,setcheck]= React.useState(false);
const [originalImage, setoriginalImagee] = useState('')
const [approvecheck, setapprovecheck] = useState(false);
const [metfile,setMetfile] = useState(state?.metadata);
const [fullcategory, setFullcategory] = useState({});
const [CategorywithSubcategory,setCategorywithSubcategory] = useState({})
const [category,setCategory] = React.useState(false);

if((Wallet_Details.networkConfiguration.Chainid == 97 || Wallet_Details.networkConfiguration.Chainid == 56) && config?.Chainid === 0){
  setconfig(network.BSC);
  setNetworkID(Wallet_Details.networkConfiguration.Chainid)
}
else if((Wallet_Details.networkConfiguration.Chainid == 1 || Wallet_Details.networkConfiguration.Chainid == 5) && config.Chainid === 0){
  setconfig(network.ETH);
  setNetworkID(Wallet_Details.networkConfiguration.Chainid);
}

//console.log("dfgadsgd",state,Wallet_Details);



  var ContractAddressUser_val = (location_pathname === '721') ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract;


  useEffect(() => {
     getCollection()
  }, [Wallet_Details.UserAccountAddr]);


  async function getCollection() {


    var Singlee = Wallet_Details.networkConfiguration.singleContract
    var Multiplee =  Wallet_Details.networkConfiguration.multipleContract
    var payload = {
      userAddress: Wallet_Details.UserAccountAddr
    }
    if (location_pathname === '1155') {
      payload.NFTType = 'MULTIPLE'
      payload.Type = Multiplee
    }
    else {
      payload.NFTType = 'SINGLE'
      payload.Type = Singlee
    }
    var userCollectiondata = await getUserCollection(payload)
    // //console.log("gmfjdgjuhfbgv",userCollectiondata.data);
    if (userCollectiondata && userCollectiondata.message &&userCollectiondata.message.data&& isEmpty(userCollectiondata.message.data.collections)===false) {
      var Collectionlist = [];
      // //console.log("setUserCollection",userCollectiondata.data);
      userCollectiondata.message.data.collections.map((item, index) => {
        Collectionlist.push({
          name: 'userCollection',
          value: item._id,
          label: item.collectionName,
          // custon:item.customurl
        })
      })
      setCollectionList(Collectionlist)
    }


  }



  useEffect(() => {



//console.log("dfgaerghar",Wallet_Details,Wallet_Details.tokenAddress);
    if (Wallet_Details&&Wallet_Details.tokenAddress.length >0) {
      setPriceoption(Wallet_Details.tokenAddress)
    }
  },[Wallet_Details.tokenAddress])

  useEffect(() => {

    if (Wallet_Details&&Wallet_Details.networkoption.length >0 ) {
      var staticnetwork = [];
      Wallet_Details.networkoption.map((val)=>{
        if(Wallet_Details.networkConfiguration.Chainid == 97 || Wallet_Details.networkConfiguration.Chainid == 56){
          if(val.value == 'binance'){
            staticnetwork.push(val)
          }
        }
        else if(Wallet_Details.networkConfiguration.Chainid == 1 || Wallet_Details.networkConfiguration.Chainid == 5){
          if(val.vaue == 'ethereum'){
            staticnetwork.push(val)
          }
        }
      })
      setnetworkoption(staticnetwork)
    }
    // if (CoinName == "") {
    //   if (Wallet_Details.tokenAddress && Wallet_Details.tokenAddress.length > 0) {
    //     //console.log("consolelog checking",CoinName)
    //     if (PutOnSale && PutOnSaleType == 'TimedAuction') {
    //       var bnbs = (Wallet_Details.tokenAddress).filter((item) => item.label != config.currencySymbol)
    //       //console("kdsladl", bnbs);
    //     }
    //     else var bnbs = (Wallet_Details.tokenAddress)
    //     if (bnbs.length > 0) {
    //       setCoinNames(bnbs[0].label)
    //       //console.log("bnbs",bnbs);
    //       setPriceoption(bnbs)

    //     }
    //   }
    //   if(Wallet_Details?.networkoption)
    //   {
    //   setnetworkoption(Wallet_Details.networkoption)
    //   }

    // }


    GetCategoryCall();
    CreateItemValidation(FormSubmitUserClicked);
  }, [
    Wallet_Details.UserAccountAddr,TokenFilePreReader,CoinName]);


  const selectChange = async(e) => {


   
    //console.log("sdgsdrh", e.value, e.name);


    if (e.name == "networkname") {
      setCoinNames("")

      if (e.name == "networkname" && e.value == "binance") {
        var configdata = network.BSC
        var accountDetails =await  switchNetwork(configdata)
        setconfig(configdata)
      }
      else if (e.name == "networkname" && e.value == "ethereum") {
        var configdata = network.ETH
        var accountDetails =await  switchNetwork(configdata)
        setconfig(configdata)
      }
    }
   
    setaccountDetailssss(accountDetails)
    if (e && e.name && e.label && e.value) {
      //networkname

      switch (e.name) {
        case 'TokenCategory':
          setTokenCategory(e);
          break;
        case "networkname":
          setNetworkID(configdata.Chainid)
          break;
        case 'userCollection':
          setUserCollection(e.label)
        default:
      }

    }
    
    else{
      toast.warning("please connect your wallet",toasterOption)
     }

  }

// async function changeNet(value,name)
// {
//   //console.log("bnbsbinance", Wallet_Details.tokenAddress);
//   setCoinNames(Wallet_Details.tokenAddress[0])
          
//             setPriceoption(Wallet_Details.tokenAddress)


// //   if (CoinName == "") {
// //     if (Wallet_Details.tokenAddress && Wallet_Details.tokenAddress.length > 0) {
// //   if (name == "networkname") {
// //     if (name == "networkname" && value == "binance") {
// //       var configdata = network.BSC
// //           if (PutOnSale && PutOnSaleType == 'TimedAuction') {
// //             var bnbs = (Wallet_Details.tokenAddress).filter((item) => (item.label != config.currencySymbol && item.label != "ETH"))
// //           }
// //           else var bnbs = (Wallet_Details.tokenAddress).filter((item) => item.network != "ethereum")
// //           if (bnbs.length > 0) {
// //             setCoinNames(bnbs[0].label)
// //             //console.log("bnbsbinance", bnbs);
// //             setPriceoption(bnbs)

// //           }

// //     }
// //     else if (name == "networkname" && value == "ethereum") {
// //       var configdata = network.ETH

// //           //console.log("consolelog checking", CoinName)
// //           if (PutOnSale && PutOnSaleType == 'TimedAuction') {
// //             var bnbs = (Wallet_Details.tokenAddress).filter((item) => (item.label != config.currencySymbol && item.label != "BNB"))
// //             //console("kdsladl", bnbs);
// //           }
// //           else var bnbs = (Wallet_Details.tokenAddress).filter((item) => item.network != "binance")
// //           if (bnbs.length > 0) {
// //             setCoinNames(bnbs[0].label)
// //             //console.log("bnbsethereum", bnbs);
// //             setPriceoption(bnbs)

// //           }
        

// //     }
// //     if (configdata != '') {
// //      var values = configdata
// //      setconfig(values)
// //     }
   
  
  
  
  
  
  
  
// //   }
// // }
// // }
// }




  // async function getBuyTokensList(TokenList) {
  //    var TokenList = await getListOfToken(config.currencySymbol)

  //   //console.log("TokenList",TokenList,config.priceoptionETH);
  //   if (TokenList) {

  //     var get_token_details = await Promise.all(TokenList.data.data.data.map(async (item) => {
  //       //console.log("item tokenlist",item);
  //       var web3 = new Web3(config.BNBPROVIDER);
  //       var get_data_tokens = 0;
  //       if(String(item.value).toLowerCase() != String(config.currencySymbol).toLocaleLowerCase()){
  //         //console.log("fdhbdfhbn",item);
  //         try{
  //           //console.log("tokennnn",item.address);
  //         var tokenObj = new web3.eth.Contract(
  //           DETH_ABI, item.address
  //         );
  //         get_data_tokens = await tokenObj
  //           .methods
  //           .decimals()
  //           .call();
  //         }
  //         catch(e){
  //           //console.log("fgnjdgkmyj",e);
  //           get_data_tokens = false
  //         }
  //       }
  //       return ({
  //         label: item.label,
  //         value: item.value,
  //         Address: item.address,
  //         Decimal: Number(get_data_tokens)
  //       })
  //     }))

  //     //console.log("get_token_details",get_token_details);

  //     dispatch({
  //       type: Account_Connect,
  //       Account_Detail: {
  //         tokenAddress: get_token_details
  //       }
  //     })
  //   }

  //   var cover = get_token_details;
  //   var TokenUSDArray = []
  //   //console("sdegadsgr",cover)
  //   if(cover != null || cover != undefined)
  //   {
  //   var newArr = cover.filter(item => item.label !== config.currencySymbol);
  //   }
  //   //console("NewArryr", newArr)
  //   for (let i = 0; i < newArr.length; i++) {
  //     //console("token in lloop --1212", newArr[i].label)
  //     var ans = await WenlamboConvert(newArr[i].label);
  //     if (ans && ans.data && !isEmpty(ans.data)) {
  //       //console("tghgfdhfdghfdg", { [newArr[i].label]: ans.data.USD })
  //       TokenUSDArray.push({ USD: ans.data.USD, label: newArr[i].label })
  //       //console.log("mgfbnjfiojgjog", TokenUSDArray);
  //     }
  //   }
  //   dispatch({

  //     type: Account_Connect,
  //     Account_Detail: {
  //       Token_convertion: TokenUSDArray


  //     }
  //   })
  //   //console.log("kgkjhgfkujf",TokenUSDArray);
  // }








  async function GetCategoryCall() {
    var resp = await GetCategoryAction()
    //console.log("fgjndghkgh",resp);
    if (resp && resp.data && isEmpty(resp.data.list) === false) {
      var CategoryOption = [];
      var ind = null
      if(resp&&resp.data&&isEmpty(resp.data.list) === false)
      {
      resp.data.list.map((item, index) => {
        ind = ((isEmpty(TokenImages) || TokenImages.activate === false) && String(item.name).toLowerCase() === "film") ? index : -1
        CategoryOption.push({
          name: 'TokenCategory',
          value: item._id,
          label: item.name
        })
      })
    }
      if (ind > -1) {
        delete CategoryOption[ind]
      }
      //console.log("Category Options", CategoryOption)
      setCategoryOption(CategoryOption)
      setFullcategory(resp.data.list)
    }
  }


  async function CreateItemValidation(chk) {

    var approvedata ={curradd:Wallet_Details.UserAccountAddr,
     tradeadd: Wallet_Details.networkConfiguration.TradeContract}
    var check = await orderApprovecheck(approvedata);
    // setapprovecheck(check)
    //console.log("DFnhsfgnj",CoinName);
    if (chk) {
      var ValidateError = {};
      //console.log("dghnsdfxgj",config,config.Chainid,CoinName);
      if(config.Chainid === 0)
      {
        ValidateError.network = '"network" is not allowed to be empty';
      }
      if (CoinName === "" &&PutOnSaleType !=="UnLimitedAuction") {
        ValidateError.TokenPrice = 'Currency or Token" must be required';
      }
      if (TokenName === '') {
        ValidateError.TokenName = '"Name" is not allowed to be empty';
      }
      if (TokenName !== "") {
        if ((CONFIG.nameFormat).test(TokenName)) {
          ValidateError.TokenName = '"Emoji" is not allowed';
        }
      }
      if (isEmpty(CategorywithSubcategory)) {
        ValidateError.TokenCategory = '"Category" is required';
      }

      if (Unlockoncepurchased === true && UnLockcontent === '') {
        ValidateError.UnLockcontent = '"Locked content" is required';
      }
      if (PutOnSale === true && PutOnSaleType === 'FixedPrice') {
        if (TokenPrice === '' || isNaN(TokenPrice) === true && Number(TokenPrice) === 0) {
          ValidateError.TokenPrice = '"Price" must be a number';
        }
        else if (Number(TokenPrice) === 0) {
          ValidateError.TokenPrice = '"Price" must be greater than zero';
        }
        else if (CoinName === "") {
          ValidateError.TokenPrice = 'Currency or Token" must be required';
        }
        if (YouWillGet === 0) {
          ValidateError.TokenPrice = 'only allowed 4 digits  after decimal point';
        }

      }
      if (PutOnSale === true && PutOnSaleType === 'TimedAuction') {
        if (MinimumBid === '') {
          ValidateError.MinimumBid = '"Bid Price" must be a number';
        }
        if (Clocktime === '') {
          ValidateError.Clocktime = '"Start Clock Time " cant be a number';
        }
        if (EndClocktime === '') {
          ValidateError.EndClocktime = '"End Clock Time " cant be a number';
        }
        if (Clocktime === 'Select Start Date') {
          ValidateError.Clocktime = '"Start Clock Time " cant be a number';
        }
        if (EndClocktime === 'Select End Date') {
          ValidateError.EndClocktime = '"End Clock Time " cant be a number';
        }
        if (Clocktime === 'Invalid Date') {
          ValidateError.Clocktime = '"Start Clock Time " cant be a number';
        }
        if (EndClocktime === 'Invalid Date') {
          ValidateError.EndClocktime = '"End Clock Time " cant be a number';
        }
      }
      if (TokenQuantity === '' || isNaN(TokenQuantity) === true && TokenQuantity === 0) {
        ValidateError.TokenQuantity = '"Number of copies" must be a number';
      }
      //console.log("DNzfgnbfgn",ValidateError,PutOnSaleType);
      setValidateError(ValidateError);
      return ValidateError;
    }
    else {
      return {};
    }
  }






  const PriceCalculate = async (data = {}) => {
    var price = (typeof data.price != 'undefined') ? data.price : TokenPrice;
    var weii = price * CONFIG.decimalvalues;
    var per = (weii * Wallet_Details.Service_Fee_seller) / 1e20;
    var mulWei = parseFloat(weii - per);
    var getVal = (mulWei / CONFIG.decimalvalues);
    // if(CoinName=="BNB"){
    Set_YouWillGet(getVal.toFixed(4));
    // }
    //   if(CoinName=="WENLAMBO"){
    //     Set_YouWillGet(getVal.toFixed(2));}
    ////////console.log("qowpoqpwpoqpwop",getVal)
  }




  const selectFileChange = (e) => {
    if(Wallet_Details.UserAccountAddr=='')
    {
      toast.warning(" connect your wallet",toasterOption)
    }
    var validExtensions = ["png", 'gif', 'webp', 'mp4', 'PNG', 'jpg', 'JPEG', 'jpeg', 'JPG', 'mp3', 'aac', 'AAC', 'flac', 'FLAC', 'WEBM', 'webm', 'ogv', 'OGV']; //array of valid extensions
    if (e.target && e.target.files) {
      var reader = new FileReader()
      var file = e.target.files[0];
      //console.log("dakldjasdkasjdjasd", file.name)
      setimgfilename(file.name)

      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      if ($.inArray(fileNameExt, validExtensions) === -1) {
        toast.error("Only these file types are accepted : " + validExtensions.join(', '), toasterOption);
        return false;
      }
      const fileSize = file.size;
      if (30000000 < fileSize) {
        toast.error("File size exceeds 30 MB", toasterOption);
        return false;
      }
      else {
        //console.log("fileSize,file", fileSize, file);
        setfilesize(fileSize)
        //console.log("dghnfgjngdhj",file);
        setTokenFile(file);
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          if (reader.result) {

            //console.log("reader.result", reader.result);
            setTokenFilePreReader(reader.result);
          }
        }.bind(this);
        //console.log("e.target.value", e.target.value);
        setTokenFilePreUrl(e.target.value);
      }
    }
  }


  const CheckedChange = (e) => {
   
    if (e && e.target && e.target.name) {
      if (e.target.name === 'putonsale') {
        if (PutOnSale === false) {
          setPutOnSale(true);
          if (PutOnSaleType === '') {
            setPutOnSaleType('FixedPrice')
          }
        }
        else {
          setPutOnSale(false);
          setPutOnSaleType('')
          setTokenBid(true)
        }
      }
      else if (e.target.name === 'unlockoncepurchased') {
        if (Unlockoncepurchased === false) {
          Set_Unlockoncepurchased(true);
        }
        else {
          Set_Unlockoncepurchased(false);
        }
      }
    }
  };



  const changePutOnSaleType = (type) => {

   if(type == 'TimedAuction')
   {
if(Wallet_Details&&isEmpty(Wallet_Details.tokenAddress)===false)
{
    var bnbs =(Wallet_Details.tokenAddress).filter((item) => item.label != Wallet_Details.networkConfiguration.currencySymbol)
       setPriceoption(bnbs)

}

   }
   else if(Wallet_Details?.tokenAddress && isEmpty(Wallet_Details.tokenAddress)===false) {
// if(config.Chainid === 4)
// {
// var check = Wallet_Details.tokenAddress[0].label==="ETH"
// setcheck(check)
// }
// else if(config.Chainid === 97)
// {
//   var check = Wallet_Details.tokenAddress[0].label==="BNB"
//   setcheck(check)

// }

    setPriceoption(Wallet_Details&&Wallet_Details.tokenAddress&&Wallet_Details.tokenAddress)
   }
    setPutOnSaleType(type);
    if (type === 'FixedPrice') {
      Set_MinimumBid(0)
      set_Clocktime('')
      set_EndClocktime("")
    }
    else if (type == 'TimedAuction') {
      setTokenPrice(0)
    }
    else if (type == 'UnLimitedAuction') {
      Set_MinimumBid(0)
      set_Clocktime('')
      set_EndClocktime("")
      setTokenPrice(0)
    }
  };
  const priceoptionfunc = (e) => {
    //console.log("nfrymntfryju",e.label);
    setCoinNames(e.label)
    setdecimal( e.Decimal)
    // setiscurrency(e.currency)
  };

  const inputChange = (e) => {
    //console.log("dfgndgfn",CoinName);
    if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
      var value = e.target.value;
      const re = /^[0-9]+([.][0-9]+)?$/; //with decimal
      const re1 = /^[0-9\b]+$/; // only [0-9]
      switch (e.target.name) {
        case 'TokenPrice':
          if (value !== '' && isNaN(value) === false && value > 0) {
            setTokenPrice(value);
            PriceCalculate({ price: value });
          }
          else {
            setValidateError(ValidateError);
            setTokenPrice('0');
            PriceCalculate({ price: 0 });
          }
          break;
        case 'TokenName':
          setTokenName(value);
          break;
        case 'TokenDescription':
          setTokenDescription(value);
          break;
        case 'TokenRoyalities':
          setTokenRoyalities(value);
          break;
        case 'TokenProperties':
          setTokenProperties(value);
          break;
        case 'UnLockcontent':
          Set_UnLockcontent(value);
          break;
        case 'MinimumBid':
          Set_MinimumBid(value);
          break;
        case 'TokenQuantity':
          Set_TokenQuantity(value);
          break;
        default:
        // code block
      }
    }
  }




  // const networkoptionfunc = (e) => {

  //   setNetworkName(e.value)
  // };



  async function DateChange(from, val) {
    if (from === 'StartDateDropDown') {
      if (val === 'PickStartDate') {
        ModalAction('calendar_modal1', 'show');
      }
      else {
        Set_StartDate(val);
        var myDate = new Date();
        if (val === 'RightAfterListing') {
          var newdat = myDate.setDate(myDate.getDate());
        }
        else {
          var newdat = myDate.setDate(myDate.getDate() + parseInt(val));
        }
        var newdate = new Date(newdat);
        //////console.log("Date",newdate)
        set_Clocktime(newdate);
      }
    }
    else if (from === 'EndDateDropDown') {
      if (val === 'PickEndDate') {
        ModalAction('calendar_modal_expire', 'show');
      }
      else {
        Set_EndDate(val);
        var myDate = new Date();
        var newdat = myDate.setDate(myDate.getDate() + parseInt(val));
        var newdate = new Date(newdat)
        set_EndClocktime(newdate)
      }
    }
  }



  async function ModalAction(id, type) {
    window.$('#' + id).modal(type);
    if (id === 'calendar_modal') {
      if (Clocktime) {
        var dt = new Date(Clocktime);
        var dt1 = dt.toLocaleString();
        Set_StartDate(dt1);
      }
    }
    else if (id === 'calendar_modal_expire') {
      if (EndClocktime) {
        var dt = new Date(EndClocktime);
        var dt1 = dt.toLocaleString();
        Set_EndDate(dt1);
      }
    }
  }


  async function CreateItem() {

  //  var accountDetails =await  switchNetwork()

//console.log("dfbgszdhbsf",CoinName);
if(Wallet_Details.UserAccountAddr!=="")
{
  var check = await orderApprovecheck();

      //console.log("Wallet_Details.providerss", Wallet_Details);
      setapprovecheck(check)
      Set_FormSubmitUserClicked(true);
      var errors = await CreateItemValidation(true);
      //console.log('errrorrrsss',errors)
      var errorsSize = Object.keys(errors).length;
      //console.log("FBGdfbdfbdfb",errorsSize);
      if (errorsSize !== 0) {
        toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
        return false
      }

      else if (Wallet_Details.providerss) {
        if (config.Chainid === Wallet_Details.networkConfiguration.Chainid) {

        if (Wallet_Details.providerss == null) {
          toast.error("Please Connect to BINANCE Network", toasterOption)
          // Set_WalletConnected("false");
        }
        else {
          // var currAddr = window.web3.eth.defaultAccount;
          // window.web3.eth.getBalance(currAddr, async (errors, balance) => {
          //   var usercurbal = 0;
          if (typeof Wallet_Details.UserAccountBal === 'undefined' || Wallet_Details.UserAccountBal === null || Wallet_Details.UserAccountBal === 0) {
            Set_UserAccountBal(0);
          }
          else {
            // usercurbal = balance / config.decimalvalues;
            Set_UserAccountBal(Wallet_Details.UserAccountBal);
          }
          if (Wallet_Details.UserAccountBal === 0) {
            toast.error("Insufficient balance", toasterOption);
            return false;
          }
          var TokenCategory_label = TokenCategory.label;
          let payload = {
            TokenName,
            TokenRoyalities,
            image: TokenFile,
            TokenCategory_label,
            PutOnSaleType,
            TokenPrice,
          }
          //console.log("payload", payload);
          const resp = await CreateTokenValidationAction(payload);
          if (resp && resp.data) {
            //console.log("iffff in createissue");
            if (resp.data.errors) {
              var errors = resp.data.errors;
              var errorsSize = Object.keys(errors).length;
              if (errorsSize !== 0) {
                setValidateError(errors);
                toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
              }
              else {
                //console.log("else in createissue");
                setValidateError({});
                // setTimeout(() => {

                  if(Wallet_Details.networkConfiguration.Chainid===config.Chainid)
                  {
                  window.$('#create_item_modal1').modal('show');
                  }
                  else{
                    toast.warn("CoiName is not Valid")
                  }

                // }, 3000);

              }
            }
          }
          }
          // })
        }
      }
      else {

if(config.Chainid ===0)
{
  toast.error("Please select network ");

}else{
  toast.error("Please switch network", toasterOption);
  setTimeout(() => {
    window.location="/"
  }, 1000);
}

       
        // Set_WalletConnected(false);
      }
    }
    else{
      toast.warning("please connect your wallet")

      // var register = localStorage.getItem('registedEmail');
      // //console.log("dgfbnsfdgbn",localStorage.registedEmail,localStorage.registedEmail ===undefined);
      // if(register==='')
      // {
      // toast.warning("please register and connect your wallet")
      // }
    }
    // }


    // var ReqData = {
    //   addr: String(accountDetails.accountAddress).toLowerCase()
    // }
    // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
    // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
    //   return Resp.data.data.User
    // } else {
    //   // return null
    // }
  }



  // const TokenApproveCall = async () => {
  //   SetTokenBtn("process");
  //   const id = toast.loading("Miniting Processing");
  //   const cont = await ContractCall.SetApproveStatus(location,FormValue.ContractAddress);
  //   toast.update(id, {
  //     render: cont ? "Approved Successfully" : "Approved Failed",
  //     type: cont ? "success" : "error",
  //     isLoading: false,
  //     autoClose: 1000,
  //   });
  //   if (cont.status) {
  //     SetTokenBtn("done");
  //     SetBtnData("process");
  //   } else SetTokenBtn("try");
  // };





async function switchNetwork(configdata)
{
  var type = ""
var networkConfiguration ={}
if(configdata)
{
    if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

      type = "MetaMask"
    }
    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
      type = "WalletConnect"
    }
    // //console.log("dthstrhrtjhsrt",type,config.Chainid);

    //     var accountDetails = await connectWallet(type,config.Chainid)

    // //console.log("accountDetailscreateeeeeeeeeee",accountDetails);

    window.$('#connect_modal').modal('hide');
    //console.log("connecttype............", type)
    var accountDetails = await connectWallet(type, configdata.Chainid)
    //console.log("accountDetailsin create page......................", accountDetails)
    //  const id=toast.loading("Wallet Connecting...")
 var web3 = new Web3(window.ethereum);
  //console.log("dfghrtfh",web3);
  if(window.ethereum.isMetaMask == true){
    const chainId = await web3.eth.getChainId();
    //console.log("fghdtgj",chainId);

    if(chainId===network.ETH.Chainid)
    {
          networkConfiguration=network.ETH
    }
    else if(chainId===network.BSC.Chainid){
      networkConfiguration=network.BSC
    }


  }
  //setPriceoption()

    if (accountDetails != '' &&accountDetails?.web3?._provider!='') {
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          UserAccountAddr: accountDetails.accountAddress,
          UserAccountBal: accountDetails.coinBalance,
          WalletConnected: "true",
          Wen_Bln: accountDetails.tokenBalance,
          Accounts: accountDetails.accountAddress,
          providerss: accountDetails.web3._provider,
          networkConfiguration:networkConfiguration
        }
      })
    }
    else{
      toast.warning("please connect your wallet")
    }
  }



  //   var ReqData = {
  //     addr: String(accountDetails.accountAddress).toLowerCase()
  // }
  // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
  // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
  //     return Resp.data.data.User
  // } else {
  //     return null
  // }





return accountDetails
}

  async function ApproveCall() {
    try {
      if (Wallet_Details.UserAccountAddr === "") {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        return false;
      }
      else {
        //console.log('jdgfjdaf',state,metfile)
        setApproveCallStatus('processing');
        var senddata = {
          Image: metfile.image,
          name: TokenName,
          tokenCounts: location.pathname.split("/")[3],
          contractAddress: state.contractAddress,
          // image: config.IPFS_IMG + "/" + ipfsimghashget.data.ipfsval,
          desc: JSON.stringify(metfile),
          Creator: Wallet_Details.UserAccountAddr.toLowerCase(),
          type:"list"
        }
        //console.log("senddata", senddata);
        try {
          var ipfsimghashget = await ipfsImageHashGet(senddata);
        } catch (err) {
          //console.log("err in approve", err);
        }
        //console.log("ipfsimghashget1111", ipfsimghashget.data)
        if (ipfsimghashget && ipfsimghashget.data && ipfsimghashget.data.medadata) {
          if (ipfsimghashget.data.medadata.image) {
            //console.log("dsfgnhdfgjnfgjh",ipfsimghashget.data.medadata.image);
            setoriginalImagee(ipfsimghashget.data.medadata.image)

            //console.log("ipfsimghashget.data.medadata.ipfsval", ipfsimghashget.data.medadata.ipfsval);
            //console.log("${config.IPFS_IMG}/${ipfsimghashget.data.medadata.ipfsval}", `${CONFIG.IPFS_IMG}/${ipfsimghashget.data.medadata.ipfsval}`);
            //console.log("ipfsimghashget.data.medadata.additionalImage", ipfsimghashget.data.medadata.additionalImage);
            setAdditionalImage(ipfsimghashget.data.medadata.additionalImage)
            if (ipfsimghashget.data.medadata.MetFile) {
              var ipfsurls = ipfsimghashget.data.medadata.ipfsmetadata
              //console.log("ipfsurls", ipfsurls);
              toast.success("Approve Successfully", toasterOption);
              setApproveCallStatus('done');
            }
            else {
              setApproveCallStatus('tryagain');
              toast.error("Try Again", CONFIG.toasterOption)
            }
          }
          else {
            setApproveCallStatus('tryagain');
            toast.error("Try Again", CONFIG.toasterOption)
          }

        }
        else {
          setApproveCallStatus('tryagain');
          toast.error("Try Again", CONFIG.toasterOption)
        }
      }
    }
    catch (error) {
      setApproveCallStatus('tryagain');
      toast.error("Try Again", CONFIG.toasterOption)
    }
  }


  const MintCall = async () => {

    var web3 = new Web3(Wallet_Details.providerss);
    if (Wallet_Details.UserAccountAddr === "") {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return false;
    }
    var CoursetroContract = null;
    var contractCall = null;
    var TokenPriceInStr = (PutOnSaleType === "FixedPrice")? (window.web3.toWei(TokenPrice)).toString():'0'

    var mintCall = null;
      var receipt = null;
      var handle = null;
      if(PutOnSaleType === "FixedPrice"){
        var gas;
        await web3.eth.getGasPrice().then(async (result) => {
          gas = result;
        });
        //console.log("gas", gas);
        CoursetroContract = new web3.eth.Contract(Market, Wallet_Details.networkConfiguration.TradeContract);
      try {
        contractCall = await CoursetroContract.methods.orderPlace(
          state.tokenCounts,
          TokenPriceInStr,
          state.contractAddress,
          state.type,
          'order'
        )
      } catch (err) {
        //console.log("adfsbdhbdfgtnh", err);
      }
      //console.log("contractCall", contractCall);
    try {
      
      ////////console.log("wqywuyquyw",typeof TokenPriceInStr)
      setMintCallStatus('processing')
      if (contractCall != null) {
        await contractCall
          .send({
            from: Wallet_Details.UserAccountAddr
          })
          .on('transactionHash', (transactionHash) => {
            ////console.log("testing all 3@123", transactionHash)
            mintCall = transactionHash
            if (mintCall) {
              handle = setInterval(async () => {
                receipt = await getReceipt(web3, transactionHash)
                //console.log("XGFnbfgnfxdgn",receipt);

                clr1();
              }, 8000)
            }
          })
      }
    }
    catch (e) {
      //console.log("dfsfdsfdsef", e)
      toast.error("Mint not Successfully", toasterOption);
      setMintCallStatus('tryagain');
    }
      }
      else{
        //console.log('elseeeee')
        clr1();
      }
      
    async function clr1() {
      //console.log('clrrr1111',PutOnSaleType,receipt != null || PutOnSaleType === "FixedPrice")
      if (receipt != null || PutOnSaleType !== "FixedPrice") {
        clearInterval(handle);
        if (receipt?.status === true || PutOnSaleType !== "FixedPrice") {
          Set_MintHashVal(mintCall);
          toast.success("Your Token Added Successfully", toasterOption);
          var Status = "true";
          //console.log("dbsdfbhsfgb",);
          var TokenAddItemPayload = {
            Image: originalImage,
            ipfsimage: ipfshash,
            Name: TokenName,
            Count: state.tokenCounts,
            Description: TokenDescription,
            Price: TokenPrice,
            Royalities: TokenRoyalities,
            Category_label: CategorywithSubcategory.category,
            Bid: TokenBid,
            Properties: TokenProperties,
            Owner: Wallet_Details.UserAccountAddr,
            Creator: Wallet_Details.UserAccountAddr,
            CategoryId: CategorywithSubcategory.id,
            Quantity: TokenQuantity,
            Balance: TokenQuantity,
            // ContractAddress:Wallet_Details.networkConfiguration.TradeContract,
            ContractAddress:state.contractAddress,
            Status: Status,
            HashValue: PutOnSaleType === "FixedPrice" ? mintCall : "",
            Type: CollectibleType,
            MinimumBid: 0,
            Clocktime: '',
            EndClocktime: '',
            UnLockcontent: '',
            PutOnSale: PutOnSale,
            PutOnSaleType: PutOnSaleType,
            CoinName: CoinName,
            additionalImage: additionalImage,
            collection: UserCollection,
            SelectedNetwork: NetworkID,
            decimal:decimal,
            action:"list",
            Subcategory: CategorywithSubcategory.subcategory,
          };


           //console.log("lkjhg", TokenAddItemPayload,NetworkID,Wallet_Details.networkConfiguration.Chainid)
          if (Unlockoncepurchased === true) {
            TokenAddItemPayload.UnLockcontent = UnLockcontent;
          }

          if (PutOnSale === true) {
            if (PutOnSaleType === 'FixedPrice') {
              TokenAddItemPayload.Price = TokenPrice;
            }
            else if (PutOnSaleType === 'TimedAuction') {
              TokenAddItemPayload.MinimumBid = MinimumBid;
              TokenAddItemPayload.Clocktime = Clocktime;
              TokenAddItemPayload.EndClocktime = EndClocktime;
              //TokenAddItemPayload.CoinName = Wallet_Details.networkConfiguration.tokenSymbol;
            }
          }
          //console.log("bdfbrhderh", TokenAddItemPayload);
          await TokenAddItemAction(TokenAddItemPayload);
          var TokenAddOwnerPayload = {
            'Count': state.tokenCounts,
            'Price': TokenPrice,
            'Owner': Wallet_Details.UserAccountAddr,
            'Balance': TokenQuantity,
            'Quantity': TokenQuantity,
            'ContractAddress': state.contractAddress,
            // "ContractAddress":Wallet_Details.networkConfiguration.TradeContract,
            'Type': CollectibleType,
            'tokenCreator': Wallet_Details.UserAccountAddr,
            // 'previousPrice':TokenPrice,
            'HashValue': PutOnSaleType === "FixedPrice" ? mintCall : "",
            'CoinName': CoinName,
            'Status': Status,
            'collection': UserCollection,
            'SelectedNetwork': NetworkID,
            "decimal":decimal,
            "PutOnSaleType": PutOnSaleType,
            "action":"list",
            "putonsalequantity":TokenQuantity,
            // "currencyCheck": iscurrency,
          };
          if (PutOnSaleType === 'TimedAuction') {
            TokenAddOwnerPayload.MinimumBid = MinimumBid;
            TokenAddOwnerPayload.Clocktime = Clocktime;
            TokenAddOwnerPayload.EndClocktime = EndClocktime;
            //TokenAddOwnerPayload.CoinName = Wallet_Details.networkConfiguration.tokenSymbol
          }
          //console.log("dafhbgsfrtjh", TokenAddOwnerPayload);
          await TokenAddOwnerAction(TokenAddOwnerPayload);

          setMintCallStatus('done');
          setTimeout(() => window.$('#create_item_modal1').modal('hide'), 1000);
          setTimeout(() => history.push("/my-items"), 1200);
          
          //window.location="/my-items"
          // setTimeout(() => history.push("/my-items"), 1200);
          // setTimeout(() => window.history.pushState({}, "", "/my-items"))






        }
      }

    }
  }



async function getapproveFun()
{
  setApproveTrade("processing")
  var web3 = new Web3(Wallet_Details.providerss);

          var address = (location_pathname === '721') ? Wallet_Details.networkConfiguration.singleContract:Wallet_Details.networkConfiguration.multipleContract


          try {
            var ConnectContract = await new web3.eth.Contract(  CollectibleType == '721' ? SINGLE : MULTIPLE,state.contractAddress );
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .setApprovalForAll(Wallet_Details.networkConfiguration.TradeContract, true)
                    .send({
                        from: Wallet_Details.UserAccountAddr
                    }).on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
           var  HashValue=contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash
            var receipt = await web3.eth.getTransactionReceipt(HashValue);

            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
//console.log("Fdbnhsfgnsfg",need_data);
if(need_data.status === true)
{
  setApproveTrade("done")

}
else{
  setApproveTrade("tryagain")
}
            return need_data;
      
        }
        catch (e) {
            //console(data, e)
            return false
        }

}

// async function getappcall(contractadd)
// {
//   var ConnectContract = await contrat_connection(location_pathname === 'create-single' ? SINGLE  : MULTIPLE,contractadd)
//   var contract_Method_Hash = await
//       ConnectContract
//           .methods
//           .GetApproveStatus(Wallet_Details.UserAccountAddr, Wallet_Details.networkConfiguration.TradeContract)
//           .call()
//   return contract_Method_Hash
// }




async function orderApprovecheck(item) {
  if (Wallet_Details.providerss == null) {
    toast.warning("OOPS!..connect Your Wallet", toasterOption);
    return false;
  }
  var web3 = new Web3(Wallet_Details.providerss);
  try {
    var MultiContract = new web3.eth.Contract(
      (item.type === 721 ? SINGLE : MULTIPLE),
      state.contractAddress
    )
    var status = await MultiContract.methods.isApprovedForAll(
      Wallet_Details.UserAccountAddr,
      Wallet_Details.networkConfiguration.TradeContract
    ).call();
    ////console.log("OrderApprove Check", status);
    return status
  }
  catch (e) {
    //console.log("OrderApprove Check", e);

    return false

  }

}

async function orderApprovecheck(item, tokenOwnerInfo) {
  if (Wallet_Details.providerss == null) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return false;
  }
  var web3 = new Web3(Wallet_Details.providerss);
  try {

      //   //console.log("OrderApprove Check", item,tokenOwnerInfo);
      var MultiContract = new web3.eth.Contract(
          (item.type === 721 ? SINGLE : MULTIPLE),
          item.contractAddress
      );
      var status = await MultiContract.methods.isApprovedForAll(
          tokenOwnerInfo.tokenOwner,
          Wallet_Details.networkConfiguration.TradeContract
      ).call();
      return status;
  }
  catch (e) {
      // //console.log("OrderApprove Check", e);
      return false

  }
}

var SettokenCategory =(cat,subcat)=>{
  //console.log('ctfastfdtsd',cat,subcat)
  setCategorywithSubcategory({category:cat.name,subcategory:subcat,id:cat._id,display:cat.name+'-'+subcat})
  setCategory(!category)
}

  return (
    <div className="inner_header">

      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
          <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
        </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <Link to="/create">
                  <i className="fas fa-arrow-left mr-3 arrow_back"></i>
                </Link>
                <h3 className="section-head mb-0 sec_he_sm">{location_pathname === "721" && 'Create Single NFT'}{location_pathname === "create-multiple" && 'Create Multiple NFTs'}</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-5">
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <label className="primary_label">Preview</label>
              <div className="single_collectible masonry mx-0">
                <div className="item">
                  <div className="card_inner_item">
                    <div className="d-flex justify-content-between">

                      <div>

                      </div>
                    </div>
                    <div className="remaintime mt-3">
                      <div className="item_inner_img">

                      <div className="ethactios">
                    <LazyLoad height={200} placeholder={<LazyLoader />} offset={[-200, 0]} debounce={500}>
                    {
      (((state?.image && TokenFile)?.includes('ipfs://') === true || (TokenFile)?.includes('ipfs/') === true || (TokenFile)?.includes('ipfs:/') === true)
        ? (

          <object type="image/webp" data={"https://ipfs.io/ipfs/" + (((TokenFile)?.split('ipfs://').pop()).split('ipfs/').pop()).split('ipfs:/').pop()}
          >
          </object>

        )
        :

        (state?.image &&

          <object type="image/webp" data={TokenFile}
          >
          </object>
        ))}
                  </LazyLoad>
                  </div>

                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-end">
                      <div>
                        {/* <h3 className="my-2 ">
                          <span className="text-gray">
                            { <span className="mr-1">Not for sale </span>}
                           
                          </span> </h3> */}


                        <button className="btn btn_purple_sm bg-theme" data-toggle="modal" data-target="#place_bid_multiple_modal"> <span>
                          Open for Bids 1 of 1</span></button>


                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </GridItem>
            <GridItem xs={12} sm={8} md={8}>
              <form className="formCls">
                {/* <div className="form-row">
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Upload file</label>
                        <p className="form_note">JPG, JPEG, PNG, GIF, WEBP, WEPM, OGV, MP3, FLAC, AAC or MP4 . Max 30mb.</p>
                      </div>
                      <div className="file_btn btn primary_btn">Upload
                        <input
                          className="inp_file"
                          type="file"
                          name="file"
                          accept="audio/*,video/*,image/*"
                        />
                      </div>
                      {ValidateError.photo && <span className="text-danger img-file">{ValidateError.photo}</span>}
                    </div>
                  </div>

                </div> */}
                {
                <div className="form-row">
                  <label className="primary_label" htmlFor="price_new">Network</label>

                  <div className="form-row w-100">
                    <div className="form-group col-md-12">
                      <div className="input-group input_grp_style_1">


                        <Select
                          className="yes1 typecontrol3 form-control primary_inp typecontrol"
                          id="networkname1"
                          name="networkname"
                          // onChange={networkoptionfunc}
                          onChange={selectChange}
                          options={networkoption}
                          label="Select price"
                          classNamePrefix="react-select"
                          value={networkoption[0]}
                          isDisabled = {true}
                        // formControlProps={{
                        //   fullWidth: true
                        // }}
                        />
                            {ValidateError.network && <span className="text-danger">{ValidateError.network}</span>}


                      </div>

                    </div>
                  </div>
                </div>
                
                
}
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start grid_toggle">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Put on Sale</label>
                        <p className="form_note">You’ll receive bids on this item</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox"
                          id="putonsale1"
                          name="putonsale"
                          onChange={CheckedChange}
                          checked={PutOnSale}

                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                    {
                      (PutOnSale === false) ? ('') : (
                        <>
                          <div className="row connect_row mt-3 putonsale_sec">
                            <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                              <div className={"create_box create_sing_bx " + ((PutOnSaleType === 'FixedPrice') ? 'active' : 'inactive')} id="fixedprice1" onClick={() => { changePutOnSaleType('FixedPrice') }}>
                                <img src={require("../assets/images/price_svg.svg")} alt="Fixed Price" className="img-fluid" />
                                <p>Fixed Price</p>
                              </div>
                            </div>
                            {(CollectibleType === 721) &&
                              <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                                <div className={"create_box create_sing_bx " + ((PutOnSaleType === 'TimedAuction') ? 'active' : 'inactive')} onClick={() => { changePutOnSaleType('TimedAuction') }}>
                                  <img src={require("../assets/images/timed_svg.svg")} alt="Timed Auction" className="img-fluid" />
                                  <p>Timed Auction</p>
                                </div>
                              </div>}
                            <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3" onClick={() => { changePutOnSaleType('UnLimitedAuction') }}>
                              <div className={"create_box create_sing_bx " + ((PutOnSaleType === 'UnLimitedAuction') ? 'active' : 'inactive')}>
                                <img src={require("../assets/images/unlimited_svg.svg")} alt="Unlimited Auction" className="img-fluid" />

                                <p>Unlimited Auction</p>
                              </div>
                            </div>

                          </div>

                        </>)}
                    {(PutOnSale === true && PutOnSaleType === 'FixedPrice') &&
                      <div className="row mx-0 mt-3 fixed_price_sec">
                        <label className="primary_label" htmlFor="price_new">Price</label>

                        <div className="form-row w-100">
                          <div className="form-group col-md-6">
                            <div className="input-group input_grp_style_1">

                              <input
                                type="text"
                                className="form-control selct_form_input_h"
                                placeholder="0"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                name="TokenPrice"
                                id="TokenPrice1"
                                step="0.01"
                                maxLength={15}
                                autoComplete="off"
                                onChange={inputChange}
                              />
                              <div className="input-group-append">
                                <Select
                                  className="yes1 form-control primary_inp select1 selxet_app"
                                  id="basic-addon21"
                                  name="coinname"
                                  onChange={priceoptionfunc}
                                  options={priceoption}
                                  label="Select price"
                                // formControlProps={{
                                //   fullWidth: true
                                // }}
                                />

                              </div>
                            </div>
                            {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                          </div>

                        </div>
                        <p className="form_note">Service fee
                          <span className="font_we_700_note_txt">{Wallet_Details.Service_Fee_seller / 1e18}% </span><br />
                          You will receive <span className="font_we_700_note_txt">
                            {/* {YouWillGet} */}
                            <Convert
                              item={Number(YouWillGet)}

                              coinName={CoinName}
                              convertVal={1}
                            />
                            {CoinName}
                          </span>
                          <span className="font_we_700_note_txt">
                            <br />


                            {/* $( {CoinName == "CAKE" ? Wallet_Details.Token_convertion[0].USD * TokenPrice : Wallet_Details.Token_convertion[0].USD * TokenPrice}) */}
                          </span>
                        </p>
                      </div>
                    }
                    {(PutOnSale === true && PutOnSaleType === 'TimedAuction') &&
                      <div className="row mt-3 timed_sec">
                        <div className="col-12 mb-3">
                          <label className="primary_label" htmlFor="price_new">Minimum bid</label>
                          <div className="input-group mb-1">
                            <input
                              type="text"
                              className="form-control selct_form_input_h"
                              placeholder="Enter minimum bid"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon3"
                              name="MinimumBid"
                              id="MinimumBid1"
                              maxLength={CONFIG.maxLength}
                              onChange={inputChange}
                              autoComplete="off"
                            />
                            {ValidateError.MinimumBid && <span className="text-danger">{ValidateError.MinimumBid}</span>}
                            <div className="input-group-append">
                            

<Select
                                  className="yes1 form-control primary_inp select1 selxet_app"
                                  id="basic-addon21"
                                  isSearchable={false}
                                  name="coinname"
                                //  value={{ label: CoinName == config.currencySymbol ? priceoption[1].label : CoinName }}
                                onChange={priceoptionfunc}
                                  options={priceoption&&priceoption.filter(item => item?.label != Wallet_Details?.networkConfiguration?.currencySymbol)}
                                  label="Select price"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                />

                              {/* <p className="yes1 form-control primary_inp selxet_app symboldbo">{Wallet_Details.networkConfiguration.tokenSymbol
                              // NetworkName == 'binance' ? network.BSC.tokenSymbol : network.ETH.tokenSymbol
                              }</p> */}

                            </div>
                          </div>
                          {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}

                          <p className="form_note">   Bids below this amount won't be allowed. If you not enter any amount we will consider as 0</p>
                        </div>
                        <div className="col-12 col-lg-6  mb-3">
                          <div className="single_dd_1">
                            <label className="primary_label" htmlFor="start_date">Starting Date</label>
                            <div className="dropdown">
                              <button className="btn btn-secondary dropdown-toggle filter_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                {StartDate}<i className="fas fa-chevron-down"></i>
                              </button>
                              <div className="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                <div >Select Date</div>
                                <div id="RightAfterListing1" onClick={() => { DateChange('StartDateDropDown', 'RightAfterListing') }}>Right after listing</div>
                                <div id="PickStart1" onClick={() => { DateChange('StartDateDropDown', 'PickStartDate') }}>Pick specific date</div>
                              </div>
                            </div>
                            {ValidateError.Clocktime && <span className="text-danger">{ValidateError.Clocktime}</span>}
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <div className="single_dd_1">
                            <label className="primary_label" htmlFor="start_date">Expiration Date</label>
                            <div className="dropdown">
                              <button className="btn btn-secondary dropdown-toggle filter_btn" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {EndDate}<i className="fas fa-chevron-down"></i>
                              </button>
                              <div className="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                <div>Select Date</div>
                                <div onClick={() => { DateChange('EndDateDropDown', '1 Day') }}>1 day</div>
                                <div onClick={() => { DateChange('EndDateDropDown', '3 Day') }}>3 days</div>
                                <div onClick={() => { DateChange('EndDateDropDown', 'PickEndDate') }}>Pick specific date</div>

                              </div>
                            </div>
                            {ValidateError.EndClocktime && <span className="text-danger">{ValidateError.EndClocktime}</span>}
                          </div>

                        </div>
                        <div className="col-12">
                          <p className="form_note_link_boild" data-toggle="modal" data-target="#learn_modal1">Learn more how timed auctions work</p>

                        </div>

                      </div>


                    }</div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Name</label>
                    <input type="text"
                      className="form-control primary_inp"
                      name="TokenName"
                      id="TokenName1"
                      onChange={inputChange}
                      value={TokenName}
                      disabled
                      placeholder="e.g. Redeemable" />
                    {ValidateError.TokenName && <span className="text-danger">{ValidateError.TokenName}</span>}

                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Description <span className="text-muted">(Optional)</span></label>
                    <textarea type="text"
                      className="form-control primary_inp"
                      id="desccription1"
                      name="TokenDescription"
                      onChange={inputChange}
                      placeholder="Description about your NFTs..."
                      autoComplete="off"
                      value={TokenDescription}
                      disabled
                    ></textarea>

                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="Collection">Select Collection</label>
                    <Select
                      className="yes1 typecontrol3 form-control primary_inp typecontrol"
                      id="userCollection1"
                      name="userCollection"
                      onChange={selectChange}
                      maxMenuHeight={220}
                      options={CollectionList}
                      label="Select Collection"
                      classNamePrefix="react-select"
                      placeholder="Select Collection"
                    // formControlProps={{
                    //   fullWidth: true
                    // }}
                    />


                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="category">Category</label>
                    {/* <Select
                      className="yes1 typecontrol3 form-control primary_inp typecontrol"
                      id="tokenCategory1"
                      name="tokenCategory"
                      onChange={selectChange}
                      maxMenuHeight={220}
                      options={CategoryOption}
                      classNamePrefix="react-select"
                      label="Select or type name"
                      placeholder="Select or type name"
                    // formControlProps={{
                    //   fullWidth: true
                    // }}
                    /> */}
                    
                    <input type="text" placeholder="Select Category" className="form-control" 
                    value={CategorywithSubcategory?.display ? CategorywithSubcategory?.display : ""}
                    onClick={() => setCategory(!category)}/>
                    {category == true ?
                    (
                    <><div className="dropdown">
                      
                      <ul className="dropdown-menu1 p-3">
                      {fullcategory?.length > 0  &&
                      fullcategory.map((val)=>{
                        return(
                        <li className="dropdown-sub">
                          {val.name}
                          <ul className="sub-dropdown">
                            {val.subcategory.map((sub)=>{
                              return(
                              <li onClick={()=>SettokenCategory(val,sub)}>{sub}</li>
                              )
                            })}
                          </ul>
                        </li>)
                      })
                      }
                      </ul>
                      </div></>):(<></>)}
                    {ValidateError.TokenCategory && <span className="text-danger"><br />{ValidateError.TokenCategory}</span>}

                  </div>
                </div>
                <div>

                  <Button className="create_btn" data-toggle="model" data-target="#create_item_modal1" onClick={CreateItem} >List</Button>

                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
      {/* Choose Collection Modal */}
      <div className="modal fade primary_modal" id="choose_collection_modal1" tabIndex="-1" role="dialog" aria-labelledby="choose_collection_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="choose_collection_modalLabel1">Collection</h5>

              <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex align-items-center flex_block_mob">
                <div className="hex center">
                  <div className="hex__shape">
                    <div className="hex__shape">
                      <div className="hex__shape">
                        <img src={require("../assets/images/img_01.png")} alt="logo" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="font_14 font_600 line_20 mb-1 text_brown mb-3">We recommend an image of at least 400x400. Gifs work too.</p>
                  <div className="file_btn primary_btn d-inline-block btn_small">Choose File
                    <input className="inp_file" type="file" name="file" />
                  </div>
                </div>
              </div>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="name">Display Name <span className="text-muted">(30 available)</span></label>
                    <input type="text" className="form-control primary_inp" id="name1" placeholder="Enter token name" />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="desccription">Symbol <span className="text-muted">(required)</span></label>
                    <input type="text" className="form-control primary_inp" id="desccription1" placeholder="Enter token symbol" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="name">Description <span className="text-muted">(Optional)</span></label>
                    <input type="text" className="form-control primary_inp" id="name1" placeholder="Spread some words about token collection" />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="desccription">Short url</label>
                    <input type="text" className="form-control primary_inp" id="desccription1" defaultValue="Fayre.io/" />
                  </div>
                </div>
                <div className="text-center">
                  <Button className="create_btn btn-block">Create Collection</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* ens choose collection modal */}
      {/* create_item Modal */}
      <div className="modal fade primary_modal" id="create_item_modal1" tabIndex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="create_item_modalLabel1">Follow Steps</h5>

              <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="media approve_media">

                  {ApproveCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                  {ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                  {ApproveCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                  {ApproveCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}


                  <div className="media-body">
                    <p className="mt-0 approve_text">Download Image From Other Source</p>
                    <p className="mt-0 approve_desc">Download meta contents to Marketplace</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="create_btn btn-block" disabled={(ApproveCallStatus === 'processing' || ApproveCallStatus === 'done')} onClick={ApproveCall}>     {ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {ApproveCallStatus === 'init' && 'Approve'}
                    {ApproveCallStatus === 'processing' && 'In-progress...'}
                    {ApproveCallStatus === 'done' && 'Done'}
                    {ApproveCallStatus === 'tryagain' && 'Try Again'}
                  </Button>



                </div>



{approvecheck &&
                <div className="media approve_media">

{ApproveTrade === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
{ApproveTrade === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
{ApproveTrade === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
{ApproveTrade === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}


<div className="media-body">
  <p className="mt-0 approve_text">Get Approve </p>
  {/* <p className="mt-0 approve_desc">Upload meta contents to ipfs</p> */}
</div>
</div>
}
{approvecheck&&
<div className="text-center my-3">
<Button className="create_btn btn-block" disabled={(ApproveCallStatus !== 'done'||ApproveTrade === 'processing' || ApproveTrade === 'done')} onClick={getapproveFun}>     {ApproveTrade === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
  {ApproveTrade === 'init' && 'Approve'}
  {ApproveTrade === 'processing' && 'In-progress...'}
  {ApproveTrade === 'done' && 'Done'}
  {ApproveTrade === 'tryagain' && 'Try Again'}
</Button>



</div>
}










                <div className="media approve_media">


                  {MintCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                  {MintCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                  {MintCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                  {MintCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}


                  <div className="media-body">
                    <p className="mt-0 approve_text">Listing token</p>
                    <p className="mt-0 approve_desc">Call contract method</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="create_btn btn-block" disabled={(ApproveCallStatus !== 'done'&&(ApproveTrade !== 'done'||approvecheck===false) &&( MintCallStatus === 'processing' || MintCallStatus === 'done'))} onClick={MintCall}>


                    {MintCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {MintCallStatus === 'init' && 'Start'}
                    {MintCallStatus === 'processing' && 'In-progress...'}
                    {MintCallStatus === 'done' && 'Done'}
                    {MintCallStatus === 'tryagain' && 'Try Again'}



                  </Button>
                </div>

                <div className="media approve_media">

                </div>
                <div className="text-center my-3">

                </div>

                <div className="media approve_media">

                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end create_item modal */}


      {/* learn Modal */}
      <div className="modal fade primary_modal" id="learn_modal1" tabIndex="-1" role="dialog" aria-labelledby="learn_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="learn_modalLabel1">How timed auction work</h5>

              <button type="button" className="close"  data-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>When you put your item on timed auction, you choose currency, minimum bid, starting and ending dates of your auction.</p>

              <p>The bidder can only place a bid which satisfies the following conditions:</p>
              <ol>
                <li>
                  It is at least minimum bid set for the auction
                </li>
                <li>
                  It is at least 5% higher than the current highest bid

                </li>
              </ol>

              <p>Note that some bids may disappear with time if the bidder withdraws their balance. At the same time, some bids may reappear if the bidder has topped up their balance again.</p>

              <p>Auction cannot be cancelled after any valid bid was made. Any bid placed in the last 10 minutes extends the auction by 10 minutes.</p>

              <p>In the 48 hours after the auction ends you will only be able to accept the highest available bid placed during the auction. As with regular bids, you will need to pay some gas to accept it.Note that you can always decrease the price of your listing for free, without making a transaction or paying gas. You can view all your items here.</p>


            </div>
          </div>
        </div>
      </div>
      {/* end learn modal */}

      {/* calendar Modal */}
      <div className="modal fade primary_modal" id="calendar_modal1" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>

              <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="pb-3">

                <Datetime
                  input={false}
                  value={Clocktime}
                  onChange={(value) => set_Clocktime(value)} />
              </div>
              <div className="text-center pb-3">
                <Button className="btn btn_blue" onClick={() => ModalAction('calendar_modal1', 'hide')} id="doneStartDate">Done</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end calendar modal */}
      {/* calendar Modal */}
      <div className="modal fade primary_modal" id="calendar_modal_expire1" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>

              <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="pb-3">
                <Datetime
                  input={false}
                  value={EndClocktime}
                  onChange={(value) => set_EndClocktime(value)}
                />
              </div>
            </div>
            <div className="text-center">
              <Button className="primary_btn" id="doneEndDate" onClick={() => ModalAction('calendar_modal_expire1', 'hide')}>Done</Button>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
