import React, { useState, useEffect, useRef } from "react";
import DETH_ABI from '../ABI/DETH_ABI.json';
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import { getUserCollection } from '../actions/v1/user'
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useHistory, useLocation } from "react-router-dom";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import randomInteger from 'random-int';
import AddIcon from '@mui/icons-material/Add';
import  delete_png from '../assets/images/del.png'

// import masonary1 from 'assets/images/masonary_04.png'
// import Loader from 'assets/images/loader.png';
import $ from 'jquery';
import '@metamask/legacy-web3'
import Select from 'react-select'
import CONFIG from '../lib/config'
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Convert from 'views/separate/Convert';
import isEmpty from "lib/isEmpty";
import Web3 from 'web3';
import Web3Utils from 'web3-utils'
import MULTIPLE from '../ABI/MULTIPLE.json';
import SINGLE from '../ABI/SINGLE.json';
import { getReceipt } from '../actions/v1/getReceiptFunc';
import {
  ipfsImageHashGet,
} from '../actions/v1/token';
import { connectWallet, WalletConnect } from '../views/hooks/useWallet';
import {
  GetCategoryAction,
  CreateTokenValidationAction,
  TokenAddItemAction,
  TokenAddOwnerAction,
  WenlamboConvert,
} from '../actions/v1/token';
import { Account_Connect, Account_disConnect } from "actions/redux/action";
//redux
import { AddressUserDetails_GetOrSave_Action } from "../actions/v1/user";
import { network } from "./network"
import Market from "../ABI/market"

import { AddCollectionRef } from "./separate/create_collection";


var initialformvalue = {
  currencySymbol: '',
  tokenSymbol: '',
  tokenAddr: {},
  tokenABI: {},
  singleContract: "",
  multipleContract: "",
  BNBPROVIDER: "",
  networkVersion: '',
  chainId: '',
  Chainid: 0,
  trade: "",
}

const mapdata = [
  { value: 'Discord', label: 'Discord', classname:"fab fa-discord" },
  { value: 'Instagram', label: 'Instagram', classname:"fab fa-instagram" },
  { value: 'Linkedin', label: 'Linkedin', classname:'fab fa-linkedin' },
  { value: 'Whatsapp', label: 'Whatsapp', classname:"fab fa-whatsapp" },
  { value: 'Twitter', label: 'Twitter', classname:"fab fa-twitter" },
  { value: 'Slack', label: 'Slack', classname:"fab fa-slack" },
  { value: 'Telegram', label: 'Telegram', classname:"fab fa-telegram" },
  { value: 'Github', label: 'Github', classname:"fab fa-github" },
  { value: 'Facebook', label: 'Facebook', classname:"fab fa-facebook-f" },
  { value: 'Others', label: 'Others', classname:"fas fa-hashtag" },
  // Add more data items here as needed
];

toast.configure();
const dashboardRoutes = [];
let toasterOption = CONFIG.toasterOption;
const useStyles = makeStyles(styles);
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function CreateSingle(props) {
  const dispatch = useDispatch();


  const Wallet_Details = useSelector(state => state.wallet_connect_context);

  // console.log("drhesrth", Wallet_Details);
  // const networkoption = [
  //   { value: config.currencySymbol, label: config.currencySymbol },
  //   { value: config.tokenSymbol, label: config.tokenSymbol },
  // ];

  const classes = useStyles()
  const { ...rest } = props;
  const history = useHistory();

  const [category,setCategory] = React.useState(false);

  var pathVal = '';
  const location = useLocation();
  if (location.pathname) {
    if (location.pathname.split('/').length >= 2) {
      pathVal = location.pathname.split('/')[1];
    }
  }


  const [location_pathname, Set_location_pathname] = useState(pathVal);
  var CollectibleType_val = (location_pathname === 'create-single') ? CONFIG.singleType : CONFIG.multipleType;

  const [CollectibleType, Set_CollectibleType] = useState(CollectibleType_val);
  const [TokenFilePreUrl, setTokenFilePreUrl] = useState("");
  const [imgfilename, setimgfilename] = useState('');
  const [fileSizes, setfilesize] = useState(0)
  const [TokenFile, setTokenFile] = useState("");
  const [TokenFilePreReader, setTokenFilePreReader] = useState("");
  const [ValidateError, setValidateError] = useState({ TokenPrice: '' });
  const [PutOnSale, setPutOnSale] = useState(false);
  const [PutOnSaleType, setPutOnSaleType] = useState('UnLimitedAuction');
  const [TokenBid, setTokenBid] = useState(true);
  const [Unlockoncepurchased, Set_Unlockoncepurchased] = useState(false);
  const [MinimumBid, Set_MinimumBid] = useState(0);
  const [Clocktime, set_Clocktime] = useState('');
  const [MintHashVal, Set_MintHashVal] = useState('');
  const [EndClocktime, set_EndClocktime] = useState("");
  const [TokenPrice, setTokenPrice] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [TokenName, setTokenName] = useState('');
  const [TokenDescription, setTokenDescription] = useState('');
  const [TokenRoyalities, setTokenRoyalities] = useState('');
  const [TokenProperties, setTokenProperties] = useState('');
  const [UnLockcontent, Set_UnLockcontent] = useState("");
  const [TokenQuantity, Set_TokenQuantity] = useState(1);
  const [CoinName, setCoinNames] = useState("");
  const [ApproveCallStatus, setApproveCallStatus] = useState('init');
  const [ApproveTrade, setApproveTrade] = useState('init');

  const [StartDate, Set_StartDate] = useState('Select Start Date');
  const [EndDate, Set_EndDate] = useState('Select End Date');
  const [priceoption, setPriceoption] = React.useState([]);
  const [FormSubmitUserClicked, Set_FormSubmitUserClicked] = useState(false);
  const [UserAccountBal, Set_UserAccountBal] = useState(0);
  const [TokenCategory, setTokenCategory] = useState({ label: '' });
  const [UserCollection, setUserCollection] = useState('');
  const [CollectionList, setCollectionList] = useState([]);
  const [CategoryOption, setCategoryOption] = useState([]);
  const [TokenImages, setTokenImage] = useState({});
  const [ipfshash, setIpfsHash] = useState("");
  const [ipfshashurl, setipfshashurl] = useState('');
  const [additionalImage, setAdditionalImage] = useState('')
  const [ipfsmetatag, set_ipfsmetatag] = useState('');
  const [MintCallStatus, setMintCallStatus] = useState('init');
  const [ContractAddressUser, set_ContractAddressUser] = useState(ContractAddressUser_val);
  const [networkoption, setnetworkoption] = React.useState([]);
  const [NetworkID, setNetworkID] = React.useState('');
  const [config, setconfig] = React.useState(initialformvalue);
  const [iscurrency, setiscurrency] = React.useState();
const[accountDetailssss,setaccountDetailssss]=useState({})
const [decimal, setdecimal] = React.useState();
const[check,setcheck]= React.useState(false);
const [originalImage, setoriginalImagee] = useState('')
const [approvecheck, setapprovecheck] = useState(false)
const [fullcategory, setFullcategory] = useState({});
const [CategorywithSubcategory,setCategorywithSubcategory] = useState({})
const [agree, SetAgree] = useState({original:false,'termsandcondition':false});
const [admincheck,SetAdmincheck] = useState(false);

const [ListNFT,SetListNFT] = useState(false);
const [ListedQuantity,SetListedQuantity] = useState(0)
const [PutOnSaleQuantity, SetPutOnSaleQuantity] = useState(0)

const [Randomname, setRandomName] = useState("") 
const [_nonce, setNonceHash] = useState("")
const [signature, setSignatureHash] = useState("")

const CreateCollectionForwardRef = useRef();

const [socialLinks, SetSocialLinks] = useState([]);
const [filterRows, setFilterRows] = useState([]);

const mappedData = mapdata.map(item => ({
  value: item.value,
  label: item.label,
  classname: item.classname,
}));

//console.log("dfgadsgd",config);



  var ContractAddressUser_val = (location_pathname === 'create-single') ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract;


  useEffect(() => {
     getCollection();
     GetAdmin();
    //console.log("jhvhgchgf", Wallet_Details);
  }, [Wallet_Details.UserAccountAddr]);


  async function getCollection() {


    var Singlee = Wallet_Details.networkConfiguration.singleContract
    var Multiplee =  Wallet_Details.networkConfiguration.multipleContract
    var payload = {
      userAddress: Wallet_Details.UserAccountAddr
    }
    if (location_pathname === 'create-multiple') {
      payload.NFTType = 'MULTIPLE'
      payload.Type = Multiplee
    }
    else {
      payload.NFTType = 'SINGLE'
      payload.Type = Singlee
    }
    var userCollectiondata = await getUserCollection(payload)
    console.log("gmfjdgjuhfbgv",userCollectiondata.data,payload);
    var Collectionlist = [];
    if (userCollectiondata && userCollectiondata.message &&userCollectiondata.message.data&& isEmpty(userCollectiondata.message.data.collections)===false) {
      
      // //console.log("setUserCollection",userCollectiondata.data);
      userCollectiondata.message.data.collections.map((item, index) => {
        Collectionlist.push({
          ...item,
          ...{
            name: 'userCollection',
            value: item._id,
            label: item.collectionName,
            // custon:item.customurl
          }
        })
      })
      console.log('kjkjsfjkfjf',Collectionlist)
      
    }
    Collectionlist.push({	
      	
      name: 'userCollection',	
      value: 'create',	
      label: <div className="drop_static_create"><Link onClick={()=> CreateCollectionForwardRef.current.AddCollection_Click()}>Create Collections</Link></div>,	
        
    })
    setCollectionList(Collectionlist)


  }



  useEffect(() => {



//console.log("dfgaerghar",Wallet_Details,Wallet_Details.tokenAddress);
    if (Wallet_Details&&Wallet_Details.tokenAddress.length >0) {
      setPriceoption(Wallet_Details.tokenAddress)
    }
  },[Wallet_Details.tokenAddress])

  useEffect(() => {

    if (Wallet_Details&&Wallet_Details.networkoption.length >0 ) {
      setnetworkoption(Wallet_Details.networkoption)
    }

    // if (CoinName == "") {
    //   if (Wallet_Details.tokenAddress && Wallet_Details.tokenAddress.length > 0) {
    //     //console.log("consolelog checking",CoinName)
    //     if (PutOnSale && PutOnSaleType == 'TimedAuction') {
    //       var bnbs = (Wallet_Details.tokenAddress).filter((item) => item.label != config.currencySymbol)
    //       //console("kdsladl", bnbs);
    //     }
    //     else var bnbs = (Wallet_Details.tokenAddress)
    //     if (bnbs.length > 0) {
    //       setCoinNames(bnbs[0].label)
    //       //console.log("bnbs",bnbs);
    //       setPriceoption(bnbs)

    //     }
    //   }
    //   if(Wallet_Details?.networkoption)
    //   {
    //   setnetworkoption(Wallet_Details.networkoption)
    //   }

    // }


    GetCategoryCall();
    CreateItemValidation(FormSubmitUserClicked);
  }, [
    Wallet_Details.UserAccountAddr,TokenFilePreReader,CoinName]);


  const selectChange = async(e) => {


   
    //console.log("sdgsdrh", e.value, e.name);


    if (e.name == "networkname") {
      setCoinNames("")
//console.log("fgndgfnfghnj",e.name == "networkname" && e.value == CONFIG.binance);
//console.log("dfhsdfghbndfghydgtyjty",e.name , "networkname" , e.value ,CONFIG);
      if (e.name == "networkname" && e.value == CONFIG.binance) {
        var configdata = network.BSC
        var accountDetails =await  switchNetwork(configdata)
        setconfig(configdata)
      }
      else if (e.name == "networkname" && e.value == CONFIG.etherium) {
        var configdata = network.ETH
        var accountDetails =await  switchNetwork(configdata)
        setconfig(configdata)
      }
    }
    // if (configdata != '') {
    
    //  }
    
    //var accountDetails =await  switchNetwork(configdata)
  
    setaccountDetailssss(accountDetails)
    console.log("sdfvsdfsdf",e);
    if (e && e.name && e.label && e.value) {
      //networkname

      switch (e.name) {
        case 'TokenCategory':
          setTokenCategory(e);
          break;
        case "networkname":
          setNetworkID(configdata.Chainid)
          break;
        case 'userCollection':
          {
            if(e.value == 'create'){
              setUserCollection("")
            }
            else{
              setUserCollection(e.label)
              console.log('kjdfkdkf',CategorywithSubcategory?.collection,e.label,CategorywithSubcategory?.collection == UserCollection)
              if(e?.SubCategory && e?.CategoryId && e?.Category){
                setCategorywithSubcategory({category:e.Category,subcategory:e.SubCategory,id:e.CategoryId,display:e.Category+'-'+e.SubCategory,disabecategory:true, collection:e.label})
              }
              else if(CategorywithSubcategory?.collection == UserCollection){
                setCategorywithSubcategory({});
              }
            }
            
          }
        default:
      }

    }
    
    else{
      toast.warning("please connect your wallet",toasterOption)
     }

  }

// async function changeNet(value,name)
// {
//   //console.log("bnbsbinance", Wallet_Details.tokenAddress);
//   setCoinNames(Wallet_Details.tokenAddress[0])
          
//             setPriceoption(Wallet_Details.tokenAddress)


// //   if (CoinName == "") {
// //     if (Wallet_Details.tokenAddress && Wallet_Details.tokenAddress.length > 0) {
// //   if (name == "networkname") {
// //     if (name == "networkname" && value == "binance") {
// //       var configdata = network.BSC
// //           if (PutOnSale && PutOnSaleType == 'TimedAuction') {
// //             var bnbs = (Wallet_Details.tokenAddress).filter((item) => (item.label != config.currencySymbol && item.label != "ETH"))
// //           }
// //           else var bnbs = (Wallet_Details.tokenAddress).filter((item) => item.network != "ethereum")
// //           if (bnbs.length > 0) {
// //             setCoinNames(bnbs[0].label)
// //             //console.log("bnbsbinance", bnbs);
// //             setPriceoption(bnbs)

// //           }

// //     }
// //     else if (name == "networkname" && value == "ethereum") {
// //       var configdata = network.ETH

// //           //console.log("consolelog checking", CoinName)
// //           if (PutOnSale && PutOnSaleType == 'TimedAuction') {
// //             var bnbs = (Wallet_Details.tokenAddress).filter((item) => (item.label != config.currencySymbol && item.label != "BNB"))
// //             //console("kdsladl", bnbs);
// //           }
// //           else var bnbs = (Wallet_Details.tokenAddress).filter((item) => item.network != "binance")
// //           if (bnbs.length > 0) {
// //             setCoinNames(bnbs[0].label)
// //             //console.log("bnbsethereum", bnbs);
// //             setPriceoption(bnbs)

// //           }
        

// //     }
// //     if (configdata != '') {
// //      var values = configdata
// //      setconfig(values)
// //     }
   
  
  
  
  
  
  
  
// //   }
// // }
// // }
// }




  // async function getBuyTokensList(TokenList) {
  //    var TokenList = await getListOfToken(config.currencySymbol)

  //   //console.log("TokenList",TokenList,config.priceoptionETH);
  //   if (TokenList) {

  //     var get_token_details = await Promise.all(TokenList.data.data.data.map(async (item) => {
  //       //console.log("item tokenlist",item);
  //       var web3 = new Web3(config.BNBPROVIDER);
  //       var get_data_tokens = 0;
  //       if(String(item.value).toLowerCase() != String(config.currencySymbol).toLocaleLowerCase()){
  //         //console.log("fdhbdfhbn",item);
  //         try{
  //           //console.log("tokennnn",item.address);
  //         var tokenObj = new web3.eth.Contract(
  //           DETH_ABI, item.address
  //         );
  //         get_data_tokens = await tokenObj
  //           .methods
  //           .decimals()
  //           .call();
  //         }
  //         catch(e){
  //           //console.log("fgnjdgkmyj",e);
  //           get_data_tokens = false
  //         }
  //       }
  //       return ({
  //         label: item.label,
  //         value: item.value,
  //         Address: item.address,
  //         Decimal: Number(get_data_tokens)
  //       })
  //     }))

  //     //console.log("get_token_details",get_token_details);

  //     dispatch({
  //       type: Account_Connect,
  //       Account_Detail: {
  //         tokenAddress: get_token_details
  //       }
  //     })
  //   }

  //   var cover = get_token_details;
  //   var TokenUSDArray = []
  //   //console("sdegadsgr",cover)
  //   if(cover != null || cover != undefined)
  //   {
  //   var newArr = cover.filter(item => item.label !== config.currencySymbol);
  //   }
  //   //console("NewArryr", newArr)
  //   for (let i = 0; i < newArr.length; i++) {
  //     //console("token in lloop --1212", newArr[i].label)
  //     var ans = await WenlamboConvert(newArr[i].label);
  //     if (ans && ans.data && !isEmpty(ans.data)) {
  //       //console("tghgfdhfdghfdg", { [newArr[i].label]: ans.data.USD })
  //       TokenUSDArray.push({ USD: ans.data.USD, label: newArr[i].label })
  //       //console.log("mgfbnjfiojgjog", TokenUSDArray);
  //     }
  //   }
  //   dispatch({

  //     type: Account_Connect,
  //     Account_Detail: {
  //       Token_convertion: TokenUSDArray


  //     }
  //   })
  //   //console.log("kgkjhgfkujf",TokenUSDArray);
  // }




  const GetAdmin = async() => {
    if(!isEmpty(Wallet_Details?.UserAccountAddr && Wallet_Details.networkConfiguration.TradeContract && Wallet_Details.providerss)){
      let web3 = new Web3(Wallet_Details.providerss);
      let CoursetroContract = new web3.eth.Contract(Market, Wallet_Details.networkConfiguration.TradeContract);
      try {
        let controwner = await CoursetroContract.methods.owner().call();
        console.log('ksjdkjsds',controwner)
        if(Wallet_Details?.UserAccountAddr.toLowerCase()==controwner.toLowerCase()){
          SetAdmincheck(true);
        }
        else{
          SetAdmincheck(false);
        }
      } catch (err) {
        //console.log("adfsbdhbdfgtnh", err);
      }
    }
    
  }



  async function GetCategoryCall() {
    var resp = await GetCategoryAction()
    //console.log("fgjndghkgh",resp);
    if (resp && resp.data && isEmpty(resp.data.list) === false) {
      var CategoryOption = [];
      var ind = null
      if(resp&&resp.data&&isEmpty(resp.data.list) === false)
      {
      resp.data.list.map((item, index) => {
        ind = ((isEmpty(TokenImages) || TokenImages.activate === false) && String(item.name).toLowerCase() === "film") ? index : -1
        CategoryOption.push({
          name: 'TokenCategory',
          value: item._id,
          label: item.name
        })
      })
    }
      if (ind > -1) {
        delete CategoryOption[ind]
      }
      //console.log("Category Options", CategoryOption)
      setCategoryOption(CategoryOption)
      setFullcategory(resp.data.list)
    }
  }


  async function CreateItemValidation(chk) {

    var approvedata ={type:CollectibleType,
      contractAddress: (location_pathname === 'create-single') ? Wallet_Details.networkConfiguration.singleContract:Wallet_Details.networkConfiguration.multipleContract}
    var check = await orderApprovecheck(approvedata);
    //console.log("hmgdghmgdfhmfghm",check);
    setapprovecheck(check)
    //console.log("DFnhsfgnj",CoinName,PutOnSaleType);
    if (chk) {
      var ValidateError = {};
      //console.log("dghnsdfxgj",config.Chainid,CoinName);
      if(config.Chainid === 0)
      {
        ValidateError.network = '"network" is not allowed to be empty';

      }
      if (CoinName === "" &&PutOnSaleType !=="UnLimitedAuction") {
        ValidateError.TokenPrice = 'Currency or Token" must be required';
      }
      if (TokenName === '') {
        ValidateError.TokenName = '"Name" is not allowed to be empty';
      }
      if (TokenName !== "") {
        if ((CONFIG.nameFormat).test(TokenName)) {
          ValidateError.TokenName = '"Emoji" is not allowed';
        }
      }
      if (TokenRoyalities === '') {
        ValidateError.TokenRoyalities = '"Royalties" is not allowed to be empty';
      }
      else if (isNaN(TokenRoyalities) === true) {
        ValidateError.TokenRoyalities = '"Royalties" must be a number';
      }
      else if (TokenRoyalities > 20) {
        ValidateError.TokenRoyalities = '"Royalties" must be less than or equal to 20';
      }
      if (TokenFilePreUrl === '') {
        ValidateError.photo = '"File" is required';
      }
      if (30000000 < fileSizes) {
        ValidateError.photo = '"File" Must be below 30mb';
      }
      if (isEmpty(CategorywithSubcategory)) {
        ValidateError.TokenCategory = '"Category" is required';
      }
      if(isEmpty(UserCollection)){
        ValidateError.userCollection = 'Collection is not allowed to be Empty';
      }

      if (Unlockoncepurchased === true && UnLockcontent === '') {
        ValidateError.UnLockcontent = '"Locked content" is required';
      }
      console.log('llsklfkflskfs',TokenQuantity,ListedQuantity,)
      if(ListNFT){
        if(isEmpty(ListedQuantity)){
          ValidateError.ListedQuantity = 'List Quantity must be greater than 0';
        }
        else if(Number(ListedQuantity)%1 !== 0){
          ValidateError.ListedQuantity = 'List Quantity must be Whole Number';
        }
        else if(Number(ListedQuantity) > TokenQuantity){
          ValidateError.ListedQuantity = 'List Quantity must be less than '+TokenQuantity;
        }
      }
      if (PutOnSale === true && PutOnSaleType === 'FixedPrice') {
        if (TokenPrice === '' || isNaN(TokenPrice) === true && Number(TokenPrice) === 0) {
          ValidateError.TokenPrice = '"Price" must be a number';
        }
        else if (Number(TokenPrice) === 0 && !admincheck) {
          ValidateError.TokenPrice = '"Price" must be greater than zero';
        }
        else if (CoinName === "") {
          ValidateError.TokenPrice = 'Currency or Token" must be required';
        }
        if (YouWillGet === 0) {
          ValidateError.TokenPrice = '"Price" must be Greater than 0';
        }
        if(Number(ListedQuantity) < 1){
          ValidateError.ListedQuantity = 'Quantity must be Greater than 0';
        }
        
      }
      if (PutOnSale === true && PutOnSaleType === 'TimedAuction') {
        if (MinimumBid === '') {
          ValidateError.MinimumBid = '"Bid Price" must be a number';
        }
        if (Clocktime === '') {
          ValidateError.Clocktime = '"Start Clock Time " cant be a number';
        }
        if (EndClocktime === '') {
          ValidateError.EndClocktime = '"End Clock Time " cant be a number';
        }
        if (Clocktime === 'Select Start Date') {
          ValidateError.Clocktime = '"Start Clock Time " cant be a number';
        }
        if (EndClocktime === 'Select End Date') {
          ValidateError.EndClocktime = '"End Clock Time " cant be a number';
        }
        if (Clocktime === 'Invalid Date') {
          ValidateError.Clocktime = '"Start Clock Time " cant be a number';
        }
        if (EndClocktime === 'Invalid Date') {
          ValidateError.EndClocktime = '"End Clock Time " cant be a number';
        }
      }
      if(PutOnSale){
        if(isEmpty(PutOnSaleQuantity)){
          ValidateError.PutOnSaleQuantity = 'Quantity is Required';
        }
        else if(Number(PutOnSaleQuantity) > Number(TokenQuantity)){
          ValidateError.PutOnSaleQuantity = 'Quantity must be less than Copies';
        }
        else if(Number(PutOnSaleQuantity) > Number(ListedQuantity)){
          ValidateError.PutOnSaleQuantity = 'Quantity must be less than Listed Quantity';
        }
        else if(Number(PutOnSaleQuantity)%1 !== 0){
          ValidateError.PutOnSaleQuantity = 'Quantity must be whole number';
        }
      }
      if (TokenQuantity === '' || isNaN(TokenQuantity) === true && TokenQuantity === 0) {
        ValidateError.TokenQuantity = '"Number of copies" must be a number';
      }
      if(TokenQuantity % 1 !== 0){
        ValidateError.TokenQuantity = 'Quantity must be whole number';
      }
      if(!agree?.original){
        ValidateError.original = 'This field is required'
      }
      if(!agree?.termsandcondition){
        ValidateError.termsandcondition = 'This field is required'
      }
      if(socialLinks?.length > 0){
        socialLinks.map((val,ind)=>{
          if(isEmpty(val.url)){
            ValidateError['url'+ind] = "Url cannot be Empty";
          }
          if(isEmpty(val.urlfor)){
            ValidateError['urlfor'+ind] = "Url cannot be Empty";
          }
        })
      }
      //console.log("DNzfgnbfgn",ValidateError,PutOnSaleType);
      setValidateError(ValidateError);
      return ValidateError;
    }
    else {
      return {};
    }
  }






  const PriceCalculate = async (data = {}) => {
    var price = (typeof data.price != 'undefined') ? data.price : TokenPrice;
    var weii = price * CONFIG.decimalvalues;
    var per = (weii * Wallet_Details.Service_Fee_seller) / 1e20;
    var mulWei = parseFloat(weii - per);
    var getVal = (mulWei / CONFIG.decimalvalues);
    // if(CoinName=="BNB"){
    Set_YouWillGet(getVal.toFixed(4));
    // }
    //   if(CoinName=="WENLAMBO"){
    //     Set_YouWillGet(getVal.toFixed(2));}
    ////////console.log("qowpoqpwpoqpwop",getVal)
  }




  const selectFileChange = (e) => {
    if(Wallet_Details.UserAccountAddr=='')
    {
      toast.warning(" connect your wallet",toasterOption)
    }
    var validExtensions = ["png", 'gif', 'webp', 'mp4', 'PNG', 'jpg', 'JPEG', 'jpeg', 'JPG', 'mp3', 'aac', 'AAC', 'flac', 'FLAC', 'WEBM', 'webm', 'ogv', 'OGV']; //array of valid extensions
    if (e.target && e.target.files) {
      var reader = new FileReader()
      var file = e.target.files[0];
      //console.log("dakldjasdkasjdjasd", file.name)
      setimgfilename(file.name)

      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      if ($.inArray(fileNameExt, validExtensions) === -1) {
        toast.error("Only these file types are accepted : " + validExtensions.join(', '), toasterOption);
        return false;
      }
      const fileSize = file.size;
      if (30000000 < fileSize) {
        toast.error("File size exceeds 30 MB", toasterOption);
        return false;
      }
      else {
        //console.log("fileSize,file", fileSize, file);
        setfilesize(fileSize)
        //console.log("dghnfgjngdhj",file);
        setTokenFile(file);
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          if (reader.result) {

            //console.log("reader.result", reader.result);
            setTokenFilePreReader(reader.result);
          }
        }.bind(this);
        //console.log("e.target.value", e.target.value);
        setTokenFilePreUrl(e.target.value);
      }
    }
  }


  const CheckedChange = (e) => {
   
    if (e && e.target && e.target.name) {
      if (e.target.name === 'putonsale') {
        if (PutOnSale === false) {
          setPutOnSale(true);
          if (PutOnSaleType === '') {
            setPutOnSaleType('FixedPrice')
          }
          SetPutOnSaleQuantity(1)
        }
        else {
          setPutOnSale(false);
          setPutOnSaleType("UnLimitedAuction")
          setTokenBid(true)
          SetPutOnSaleQuantity(0)
          setTokenPrice(0)
        }
      }
      else if (e.target.name === 'ListNFT') {
        if (ListNFT === false) {
          SetListedQuantity(1);
          SetListNFT(true);
          setPutOnSale(true);
          setPutOnSaleType('FixedPrice')
          SetPutOnSaleQuantity(1)
        }
        else {
          SetListedQuantity(0)
          SetListNFT(false);
          setPutOnSale(false);
          setPutOnSaleType('UnLimitedAuction')
          setTokenBid(true)
          SetPutOnSaleQuantity(0)
        }
      }
      else if (e.target.name === 'unlockoncepurchased') {
        if (Unlockoncepurchased === false) {
          Set_Unlockoncepurchased(true);
        }
        else {
          Set_Unlockoncepurchased(false);
        }
      }
    }
  };



  const changePutOnSaleType = (type) => {

   if(type == 'TimedAuction')
   {
if(Wallet_Details&&isEmpty(Wallet_Details.tokenAddress)===false)
{
    var bnbs =(Wallet_Details.tokenAddress).filter((item) => item.label != Wallet_Details.networkConfiguration.currencySymbol)
       setPriceoption(bnbs)

}

   }
   else if(Wallet_Details?.tokenAddress && isEmpty(Wallet_Details.tokenAddress)===false) {
// if(config.Chainid === 4)
// {
// var check = Wallet_Details.tokenAddress[0].label==="ETH"
// setcheck(check)
// }
// else if(config.Chainid === 97)
// {
//   var check = Wallet_Details.tokenAddress[0].label==="BNB"
//   setcheck(check)

// }

    setPriceoption(Wallet_Details&&Wallet_Details.tokenAddress&&Wallet_Details.tokenAddress)
   }
    setPutOnSaleType(type);
    if (type === 'FixedPrice') {
      Set_MinimumBid(0)
      set_Clocktime('')
      set_EndClocktime("")
      if(CollectibleType==721) SetListedQuantity(1);
    }
    else if (type == 'TimedAuction') {
      setTokenPrice(0)
      SetListedQuantity(0)
    }
    else if (type == 'UnLimitedAuction') {
      SetListedQuantity(0)
      Set_MinimumBid(0)
      set_Clocktime('')
      set_EndClocktime("")
      setTokenPrice(0)
    }
  };
  const priceoptionfunc = (e) => {
    //console.log("nfrymntfryju",e.label);
    setCoinNames(e.label)
    setdecimal( e.Decimal)
    // setiscurrency(e.currency)
  };

  const inputChange = (e) => {
    //console.log("dfgndgfn",CoinName);
    if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
      var value = e.target.value;
      const re = /^[0-9]+([.][0-9]+)?$/; //with decimal
      const re1 = /^[0-9\b]+$/; // only [0-9]
      switch (e.target.name) {
        case 'TokenPrice':
          if (value !== '' && isNaN(value) === false && value > 0) {
            setTokenPrice(value);
            PriceCalculate({ price: value });
          }
          else {
            setValidateError(ValidateError);
            setTokenPrice('0');
            PriceCalculate({ price: 0 });
          }
          break;
        case 'TokenName':
          setTokenName(value);
          break;
        case 'TokenDescription':
          setTokenDescription(value);
          break;
        case 'TokenRoyalities':
          setTokenRoyalities(value);
          break;
        case 'TokenProperties':
          setTokenProperties(value);
          break;
        case 'UnLockcontent':
          Set_UnLockcontent(value);
          break;
        case 'MinimumBid':
          Set_MinimumBid(value);
          break;
        case 'TokenQuantity':
          Set_TokenQuantity(value);
          break;
        case 'ListedQuantity':
          SetListedQuantity(value);
          break;
        case 'PutOnSaleQuantity':
          SetPutOnSaleQuantity(value);
          break;
        default:
        // code block
      }
    }
  }




  // const networkoptionfunc = (e) => {

  //   setNetworkName(e.value)
  // };



  async function DateChange(from, val) {
    if (from === 'StartDateDropDown') {
      if (val === 'PickStartDate') {
        ModalAction('calendar_modal', 'show');
      }
      else {
        Set_StartDate(val);
        var myDate = new Date();
        if (val === 'RightAfterListing') {
          var newdat = myDate.setDate(myDate.getDate());
        }
        else {
          var newdat = myDate.setDate(myDate.getDate() + parseInt(val));
        }
        var newdate = new Date(newdat);
        //////console.log("Date",newdate)
        set_Clocktime(newdate);
      }
    }
    else if (from === 'EndDateDropDown') {
      if (val === 'PickEndDate') {
        ModalAction('calendar_modal_expire', 'show');
      }
      else {
        Set_EndDate(val);
        var myDate = new Date();
        var newdat = myDate.setDate(myDate.getDate() + parseInt(val));
        var newdate = new Date(newdat)
        set_EndClocktime(newdate)
      }
    }
  }



  async function ModalAction(id, type) {
    window.$('#' + id).modal(type);
    if (id === 'calendar_modal') {
      if (Clocktime) {
        var dt = new Date(Clocktime);
        var dt1 = dt.toLocaleString();
        Set_StartDate(dt1);
      }
    }
    else if (id === 'calendar_modal_expire') {
      if (EndClocktime) {
        var dt = new Date(EndClocktime);
        var dt1 = dt.toLocaleString();
        Set_EndDate(dt1);
      }
    }
  }


  async function CreateItem() {

  //  var accountDetails =await  switchNetwork()

//console.log("dfbgszdhbsf",CoinName);
if(Wallet_Details.UserAccountAddr!=="")
{
  var check = await orderApprovecheck();

      //console.log("Wallet_Details.providerss", Wallet_Details);
      Set_FormSubmitUserClicked(true);
      var errors = await CreateItemValidation(true);
      var errorsSize = Object.keys(errors).length;
      //console.log("FBGdfbdfbdfb",errorsSize);
      if (errorsSize !== 0) {
        toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
        return false
      }

      else if (Wallet_Details.providerss) {
        //console.log("fgndfgndgfhngh",config.Chainid === Wallet_Details.networkConfiguration.Chainid);
        if (config.Chainid === Wallet_Details.networkConfiguration.Chainid) {

        if (Wallet_Details.providerss == null) {
          toast.error("Please Connect to BINANCE Network", toasterOption)
          // Set_WalletConnected("false");
        }
        else {
          // var currAddr = window.web3.eth.defaultAccount;
          // window.web3.eth.getBalance(currAddr, async (errors, balance) => {
          //   var usercurbal = 0;
          if (typeof Wallet_Details.UserAccountBal === 'undefined' || Wallet_Details.UserAccountBal === null || Wallet_Details.UserAccountBal === 0) {
            Set_UserAccountBal(0);
          }
          else {
            // usercurbal = balance / config.decimalvalues;
            Set_UserAccountBal(Wallet_Details.UserAccountBal);
          }
          if (Wallet_Details.UserAccountBal === 0) {
            toast.error("Insufficient balance", toasterOption);
            return false;
          }
          var TokenCategory_label = TokenCategory.label;
          let payload = {
            TokenName,
            TokenRoyalities,
            image: TokenFile,
            TokenCategory_label,
            PutOnSaleType,
            TokenPrice,
          }
          //console.log("payload", payload);
          const resp = await CreateTokenValidationAction(payload);
          if (resp && resp.data) {
            //console.log("iffff in createissue");
            if (resp.data.errors) {
              var errors = resp.data.errors;
              var errorsSize = Object.keys(errors).length;
              if (errorsSize !== 0) {
                setValidateError(errors);
                toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
              }
              else {
                //console.log("else in createissue");
                setValidateError({});
                // setTimeout(() => {
//console.log("fgndfgnhfgngfh",Wallet_Details.networkConfiguration.Chainid,config.Chainid,Wallet_Details.networkConfiguration.Chainid===config.Chainid);
                  if(Wallet_Details.networkConfiguration.Chainid===config.Chainid)
                  {
                    
                      var generator = require('generate-password');
                      console.log("qweqweqwewqeqweqwezxccvc", Wallet_Details,);
                      
                      var web3 = new Web3(Wallet_Details.providerss)
                      if (web3) {
                        // var web3Rpc = new Web3(config.RPC_URL)
                        // console.log("qweqwewqewqewqewqqwvbv", web3Rpc);
                        var randomNum = randomInteger(10000000, 100000000);
                        console.log("sdcfsdfsdfsdvsdfdsf", randomNum);
                        var password = generator.generate({
                          length: 10,
                          numbers: true
                        });
                        console.log("asdasdasdas", password);
                        if (web3) {
                          // console.log("checking window.ethereum", web3.givenProvider,Wallet_Details.providerss)
                          var web3RpcPro = new Web3(web3.providers);
                          console.log("asddfsfsdvbvbvcb", web3RpcPro, Wallet_Details.UserAccountAddr);
                          const to = Wallet_Details.UserAccountAddr
                          const _amount = (TokenPrice == "" || TokenPrice == undefined) ? 0 : web3RpcPro.utils.toWei(String(TokenPrice));
                          console.log("ajhghjas", _amount);
                          const _nonce = Date.now();
                          console.log("qwewqewqreqwrqrq", password);
                  
                          setRandomName(password)
                          var tot = _nonce + Number(randomNum);
                          setNonceHash(tot);
                          console.log("qwewqeqwewqeqweasdas", to, _amount, password, tot);
                          const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
                          console.log("asdsadasfdafaf", result);
                          const signhash = await web3.eth.personal.sign(result, to);
                          console.log("Signature", result, signhash)
                          if (signhash) {
                            setSignatureHash(signhash);
                          }
                        }
                      }
                  window.$('#create_item_modal').modal('show');

                  // window.$('#create_item_modal').modal({backdrop:'static', keyboard:false});

                  // window.$('#create_item_modal').data('bs.modal')._config.backdrop = 'static'; 
                  }
                  else{
                    toast.warn("CoiName is not Valid")
                  }

                // }, 3000);

              }
            }
          }
          }
          // })
        }
      }
      else {

if(config.Chainid ===0)
{
  toast.error("Please select network ");

}else{
  toast.error("Please switch network", toasterOption);
  setTimeout(() => {
    window.location="/"
  }, 1000);
}

       
        // Set_WalletConnected(false);
      }
    }
    else{
      toast.warning("please connect your wallet")

      // var register = localStorage.getItem('registedEmail');
      // //console.log("dgfbnsfdgbn",localStorage.registedEmail,localStorage.registedEmail ===undefined);
      // if(register==='')
      // {
      // toast.warning("please register and connect your wallet")
      // }
    }
    // }


    // var ReqData = {
    //   addr: String(accountDetails.accountAddress).toLowerCase()
    // }
    // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
    // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
    //   return Resp.data.data.User
    // } else {
    //   // return null
    // }
  }



  // const TokenApproveCall = async () => {
  //   SetTokenBtn("process");
  //   const id = toast.loading("Miniting Processing");
  //   const cont = await ContractCall.SetApproveStatus(location,FormValue.ContractAddress);
  //   toast.update(id, {
  //     render: cont ? "Approved Successfully" : "Approved Failed",
  //     type: cont ? "success" : "error",
  //     isLoading: false,
  //     autoClose: 1000,
  //   });
  //   if (cont.status) {
  //     SetTokenBtn("done");
  //     SetBtnData("process");
  //   } else SetTokenBtn("try");
  // };





async function switchNetwork(configdata)
{
  var type = ""
var networkConfiguration ={}
//console.log("dfhbsdfthsfth",configdata);
if(configdata)
{
  //console.log("drgsrgaerghaerg",localStorage.walletConnectType , localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask');
    if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

      type = "MetaMask"
    }
    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
      type = "WalletConnect"
    }
    // //console.log("dthstrhrtjhsrt",type,config.Chainid);

    //     var accountDetails = await connectWallet(type,config.Chainid)

    // //console.log("accountDetailscreateeeeeeeeeee",accountDetails);

    window.$('#connect_modal').modal('hide');
    //console.log("connecttype............", type)
    var accountDetails = await connectWallet(type, configdata.Chainid)
    //console.log("accountDetailsin create page......................", accountDetails)
    //  const id=toast.loading("Wallet Connecting...")
 var web3 = new Web3(window.ethereum);
  //console.log("dfghrtfh",web3);
  if(window.ethereum.isMetaMask == true){
    const chainId = await web3.eth.getChainId();
    //console.log("fghdtgj",chainId);

    if(chainId===network.ETH.Chainid)
    {
          networkConfiguration=network.ETH
    }
    else if(chainId===network.BSC.Chainid){
      networkConfiguration=network.BSC
    }


  }
  //setPriceoption()
//console.log("fgjnxfstjsfrtjrst",accountDetails?.web3?._provider);
    if (accountDetails != '' &&accountDetails?.web3?._provider!='') {
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          UserAccountAddr: accountDetails?.accountAddress,
          UserAccountBal: accountDetails?.coinBalance,
          WalletConnected: "true",
          Wen_Bln: accountDetails?.tokenBalance,
          Accounts: accountDetails?.accountAddress,
          providerss: accountDetails?.web3?._provider,
          networkConfiguration:networkConfiguration
        }
      })
    }
    else{
      toast.warning("please connect your wallet")
    }
  }



  //   var ReqData = {
  //     addr: String(accountDetails.accountAddress).toLowerCase()
  // }
  // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
  // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
  //     return Resp.data.data.User
  // } else {
  //     return null
  // }





return accountDetails
}

  async function ApproveCall() {
    try {
      if (Wallet_Details.UserAccountAddr === "") {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        return false;
      }
      else {
        setApproveCallStatus('processing');
        var senddata = {
          Image: TokenFile,
          name: TokenName,
          // image: config.IPFS_IMG + "/" + ipfsimghashget.data.ipfsval,
          desc: (TokenDescription !== "" ? TokenDescription : 'This Token was Created in EDAFACE NFT platform'),
          Creator: Wallet_Details.UserAccountAddr.toLowerCase()
        }
        //console.log("senddata", senddata);
        try {
          var ipfsimghashget = await ipfsImageHashGet(senddata);
        } catch (err) {
          //console.log("err in approve", err);
        }
        //console.log("ipfsimghashget", ipfsimghashget)
        if (ipfsimghashget && ipfsimghashget.data && ipfsimghashget.data.medadata) {
          if (ipfsimghashget.data.medadata.ipfsval !== "") {
            //console.log("dsfgnhdfgjnfgjh",ipfsimghashget.data.medadata.image);
            setoriginalImagee(ipfsimghashget.data.medadata.image)

            //console.log("ipfsimghashget.data.medadata.ipfsval", ipfsimghashget.data.medadata.ipfsval);
            setIpfsHash(ipfsimghashget.data.medadata.ipfsval)
            //console.log("${config.IPFS_IMG}/${ipfsimghashget.data.medadata.ipfsval}", `${CONFIG.IPFS_IMG}/${ipfsimghashget.data.medadata.ipfsval}`);
            setipfshashurl(`${CONFIG.IPFS_IMG}/${ipfsimghashget.data.medadata.ipfsval}`)
            //console.log("ipfsimghashget.data.medadata.additionalImage", ipfsimghashget.data.medadata.additionalImage);
            setAdditionalImage(ipfsimghashget.data.medadata.additionalImage)
            if (ipfsimghashget.data.medadata.ipfsmetadata) {
              var ipfsurls = ipfsimghashget.data.medadata.ipfsmetadata
              //console.log("ipfsurls", ipfsurls);
              set_ipfsmetatag(`${CONFIG.IPFS_IMG}/${ipfsurls}`)
              toast.success("Approve Successfully", toasterOption);
              setApproveCallStatus('done');
            }
            else {
              setApproveCallStatus('tryagain');
              toast.error("Try Again", CONFIG.toasterOption)
            }
          }
          else {
            setApproveCallStatus('tryagain');
            toast.error("Try Again", CONFIG.toasterOption)
          }

        }
        else {
          setApproveCallStatus('tryagain');
          toast.error("Try Again", CONFIG.toasterOption)
        }
      }
    }
    catch (error) {
      setApproveCallStatus('tryagain');
      toast.error("Try Again", CONFIG.toasterOption)
    }
  }

console.log('kkjfksjfjskjf',TokenQuantity,Wallet_Details)
  const MintCall = async () => {

    //console.log("mintcalllllllllll");
    setMintCallStatus('processing')
    var web3 = new Web3(Wallet_Details.providerss);
    if (Wallet_Details.UserAccountAddr === "") {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return false;
    }
    var gas;
    await web3.eth.getGasPrice()
      .then(async (result) => {
        gas = result;


      });
    //console.log("gas", gas);
    var CoursetroContract = null;
    var contractCall = null;
    var TokenPriceInStr = (PutOnSaleType === "FixedPrice")? (window.web3.toWei(TokenPrice)).toString():'0'
//console.log("jvjhgcjgfcv",   ipfsmetatag, Wallet_Details.UserAccountAddr, Wallet_Details.UserAccountAddr, TokenQuantity, CollectibleType, TokenRoyalities.toString(), TokenPriceInStr);
let testfees = web3.utils.fromWei(Wallet_Details.Minting_fee)
console.log("fgbndfgbdfgndfg",ipfsmetatag, TokenQuantity,CollectibleType,TokenRoyalities,ListedQuantity,ListNFT,Wallet_Details.Minting_fee,web3.utils.toBN(Wallet_Details.Minting_fee));
      CoursetroContract = new web3.eth.Contract(Market, Wallet_Details.networkConfiguration.TradeContract);
      try {
        contractCall = await CoursetroContract.methods.minting(
          ipfsmetatag,
          [TokenQuantity,
          CollectibleType,
          TokenRoyalities.toString(),
          ListedQuantity],
          ListNFT,
        )
      } catch (err) {
        console.log("adfsbdhbdfgtnh", err);
      }
      //console.log("contractCall", contractCall);
    try {
      var mintCall = null;
      var receipt = null;
      var handle = null;

      if (contractCall != null) {
        await contractCall
          .send({
            from: Wallet_Details.UserAccountAddr,
            value: Wallet_Details?.Minting_fee,
          })
          .on('transactionHash', (transactionHash) => {
            ////console.log("testing all 3@123", transactionHash)
            mintCall = transactionHash;
            if (mintCall) {
              handle = setInterval(async () => {
                receipt = await getReceipt(web3, transactionHash)
                //console.log("XGFnbfgnfxdgn",receipt);
                clr1();
              }, 8000)
            }
          })
      }
    }
    catch (e) {
      console.log("dfsfdsfdsef", e)
      toast.error("Mint not Successfully", toasterOption);
      setMintCallStatus('tryagain');
    }
    async function clr1() {
      if (receipt != null) {
        clearInterval(handle);
        if (receipt.status === true) {
          Set_MintHashVal(mintCall);
          toast.success("Your Token Added Successfully", toasterOption);
          var Status = "true";
          var tokenid ;
          if(Number(CollectibleType) == 1155 && ListNFT == true && ListedQuantity != TokenQuantity){
            tokenid = receipt?.logs[2]?.topics[2] && receipt.logs[2].topics[2];
          }
          else{
            tokenid = receipt?.logs[1]?.topics[2] && receipt.logs[1].topics[2];
          }
          
          
          const someString = Web3Utils.hexToNumber(tokenid);
          console.log("dfhbsfgnsfg",receipt,tokenid,someString);
          //console.log("dbsdfbhsfgb",);
          var TokenAddItemPayload = {
            Image: originalImage,
            ipfsimage: ipfshash,
            Name: TokenName,
            Count: someString,
            Description: TokenDescription,
            Price: TokenPrice,
            Royalities: TokenRoyalities,
            Category_label: CategorywithSubcategory.category,
            Bid: TokenBid,
            Properties: TokenProperties,
            Owner: Wallet_Details.UserAccountAddr,
            Creator: Wallet_Details.UserAccountAddr,
            CategoryId: CategorywithSubcategory.id,
            Quantity: TokenQuantity,
            Balance: TokenQuantity,
            // ContractAddress:Wallet_Details.networkConfiguration.TradeContract,
            ContractAddress:(location_pathname === 'create-single') ? Wallet_Details.networkConfiguration.singleContract:Wallet_Details.networkConfiguration.multipleContract,
            Status: Status,
            HashValue: mintCall,
            Type: CollectibleType,
            MinimumBid: 0,
            Clocktime: '',
            EndClocktime: '',
            UnLockcontent: '',
            PutOnSale: PutOnSale,
            PutOnSaleType: PutOnSaleType,
            CoinName: CoinName,
            additionalImage: additionalImage,
            collection: UserCollection,
            SelectedNetwork: NetworkID,
            decimal: decimal,
            Subcategory: CategorywithSubcategory.subcategory,
            TermsandConditions: agree.termsandcondition,
            Randomname:Randomname,
            _nonce:_nonce,
            signature:signature,
            SocialLinks: JSON.stringify(socialLinks),
          };
           //console.log("lkjhg", TokenAddItemPayload)
          if (Unlockoncepurchased === true) {
            TokenAddItemPayload.UnLockcontent = UnLockcontent;
          }

          if (PutOnSale === true) {
            if (PutOnSaleType === 'FixedPrice') {
              TokenAddItemPayload.Price = TokenPrice;
            }
            else if (PutOnSaleType === 'TimedAuction') {
              TokenAddItemPayload.MinimumBid = MinimumBid;
              TokenAddItemPayload.Clocktime = Clocktime;
              TokenAddItemPayload.EndClocktime = EndClocktime;
              //TokenAddItemPayload.CoinName = Wallet_Details.networkConfiguration.tokenSymbol;
            }
          }
          //console.log("bdfbrhderh", TokenAddItemPayload);
          var resp = await TokenAddItemAction(TokenAddItemPayload);
          //console.log('tokkkkkeeee',resp)
          var TokenAddOwnerPayload = {
            'Count': someString,
            'Price': TokenPrice,
            'Owner': Wallet_Details.UserAccountAddr,
            'Balance': TokenQuantity,
            'Quantity': TokenQuantity,
            'ContractAddress': (location_pathname === 'create-single') ? Wallet_Details.networkConfiguration.singleContract:Wallet_Details.networkConfiguration.multipleContract,
            // "ContractAddress":Wallet_Details.networkConfiguration.TradeContract,
            'Type': CollectibleType,
            'tokenCreator': Wallet_Details.UserAccountAddr,
            // 'previousPrice':TokenPrice,
            'HashValue': mintCall,
            'CoinName': CoinName,
            'Status': Status,
            'collection': UserCollection,
            'SelectedNetwork': NetworkID,
            "decimal":decimal,
            "PutOnSaleType": PutOnSaleType,
            Randomname: Randomname,
            _nonce: _nonce,
            signature: signature,
            ListedQuantity: ListedQuantity,
            ListNFT: ListNFT,
            putonsalequantity: PutOnSaleQuantity,
            // "currencyCheck": iscurrency,
          };
          if (PutOnSaleType === 'TimedAuction') {
            TokenAddOwnerPayload.MinimumBid = MinimumBid;
            TokenAddOwnerPayload.Clocktime = Clocktime;
            TokenAddOwnerPayload.EndClocktime = EndClocktime;
            //TokenAddOwnerPayload.CoinName = Wallet_Details.networkConfiguration.tokenSymbol
          }
          //console.log("dafhbgsfrtjh", TokenAddOwnerPayload);
          await TokenAddOwnerAction(TokenAddOwnerPayload);

          setMintCallStatus('done');
          setTimeout(() => window.$('#create_item_modal').modal('hide'), 1000);
          setTimeout(() => history.push("/my-items"), 1200);
          // setTimeout(() => window.history.pushState({}, "", "/my-items"))
          // setTimeout(() => history.push("/my-items"), 1200);
          //window.location="/my-items"






        }
      }

    }
  }



async function getapproveFun()
{
  setApproveTrade("processing")
  var web3 = new Web3(Wallet_Details.providerss);

          var address = (location_pathname === 'create-single') ? Wallet_Details.networkConfiguration.singleContract:Wallet_Details.networkConfiguration.multipleContract


          try {
            var ConnectContract = await new web3.eth.Contract(  CollectibleType == '721' ? SINGLE : MULTIPLE,address );
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .setApprovalForAll(Wallet_Details.networkConfiguration.TradeContract, true)
                    .send({
                        from: Wallet_Details.UserAccountAddr
                    }).on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
           var  HashValue=contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash
            var receipt = await web3.eth.getTransactionReceipt(HashValue);

            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
//console.log("Fdbnhsfgnsfg",need_data);
if(need_data.status === true)
{
  setApproveTrade("done")

}
else{
  setApproveTrade("tryagain")
}
            return need_data;
      
        }
        catch (e) {
            //console(data, e)
            return false
        }

}

// async function getappcall(contractadd)
// {
//   var ConnectContract = await contrat_connection(location_pathname === 'create-single' ? SINGLE  : MULTIPLE,contractadd)
//   var contract_Method_Hash = await
//       ConnectContract
//           .methods
//           .GetApproveStatus(Wallet_Details.UserAccountAddr, Wallet_Details.networkConfiguration.TradeContract)
//           .call()
//   return contract_Method_Hash
// }




async function orderApprovecheck(item) {
  //console.log('OrderApprove Check start',item)
  if (Wallet_Details.providerss == null) {
    toast.warning("OOPS!..connect Your Wallet", toasterOption);
    return false;
  }
  var web3 = new Web3(Wallet_Details.providerss);
  try {
    var MultiContract = new web3.eth.Contract(
      (item.type === 721 ? SINGLE : MULTIPLE),
      item.contractAddress
    )
    var status = await MultiContract.methods.isApprovedForAll(
      Wallet_Details.UserAccountAddr,
      Wallet_Details.networkConfiguration.TradeContract
    ).call();
    //console.log("OrderApprove Check", status);
    return !status
  }
  catch (e) {
    //console.log("OrderApprove Check", e);

    return false

  }

}

// async function orderApprovecheck(item, tokenOwnerInfo) {
//   if (Wallet_Details.providerss == null) {
//       toast.warning("OOPS!..connect Your Wallet", toasterOption);
//       return false;
//   }
//   var web3 = new Web3(Wallet_Details.providerss);
//   try {

//       //   //console.log("OrderApprove Check", item,tokenOwnerInfo);
//       var MultiContract = new web3.eth.Contract(
//           (item.type === 721 ? SINGLE : MULTIPLE),
//           item.contractAddress
//       );
//       var status = await MultiContract.methods.isApprovedForAll(
//           tokenOwnerInfo.tokenOwner,
//           Wallet_Details.networkConfiguration.TradeContract
//       ).call();
//       //console.log("OrderApprove Check 2", status);
//       return status;
//   }
//   catch (e) {
//       //console.log("OrderApprove Check 2", e);
//       return false

//   }
// }

var SettokenCategory =(cat,subcat)=>{
  //console.log('ctfastfdtsd',cat,subcat)
  setCategorywithSubcategory({category:cat.name,subcategory:subcat,id:cat._id,display:cat.name+'-'+subcat})
  setCategory(!category)
}

const Oncancel = async()=>
{
  setimgfilename('')
  setfilesize(0)
  setTokenFile('')
  setTokenFilePreReader('')
  setTokenFilePreUrl('')
  toast.success("Cancelled successfully")
}

const addFilters = () => {
  if(filterRows.length < 10){
    setFilterRows([...filterRows, { filters: "" }])
    SetSocialLinks([...socialLinks, { url: "" }])
  }
  else{
    toast.error('Already added 10 links',1000)
  }
};

const removeFilters = (index) => {
  const list = [...filterRows]
  let currlink = socialLinks;
  list.splice(index, 1)
  currlink.splice(index,1)
  setFilterRows(list)
  SetSocialLinks([...currlink])
};

const OnChange = (e,index) => {
  console.log('jdgdlgldgd',e,index)
  let currlinks = socialLinks;
  setValidateError({})
  if(e?.target?.id){
    
    const {id,value} = e.target;
    
    filterRows.map((val,ind)=>{
      if(ind == index){
        currlinks[ind][id] = value
      }
      
    })
  }
  else if(e?.value){
    const {classname,label,value} = e;
    
    filterRows.map((val,ind)=>{
      if(ind == index){
        currlinks[ind].urlfor = value;
        currlinks[ind].classname = classname;
      }
      
    })
  }
  console.log('kdlkkgdlkgd',currlinks)
  SetSocialLinks([...currlinks])
}

  return (
    <div className="inner_header">

      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
          <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
        </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />

        <AddCollectionRef
          ref={CreateCollectionForwardRef}
          GetCollection={getCollection}
        />

      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <Link to="/create">
                  <i className="fas fa-arrow-left mr-3 arrow_back"></i>
                </Link>
                <h3 className="section-head mb-0 sec_he_sm">{location_pathname === "create-single" && 'Create Single NFT'}{location_pathname === "create-multiple" && 'Create Multiple NFTs'}</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-5">
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <label className="primary_label">Preview</label>
              <div className="single_collectible masonry mx-0">
                <div className="item">
                  <div className="card_inner_item">
                    <div className="d-flex justify-content-between">

                      <div>

                      </div>
                    </div>
                    <div className="remaintime mt-3">
                      <div className="item_inner_img">

                        {((TokenFilePreUrl.split('.').pop() === 'mp4') ||
                          (TokenFilePreUrl.split('.').pop() === 'webm') ||
                          (TokenFilePreUrl.split('.').pop() === 'WEBM') ||
                          (TokenFilePreUrl.split('.').pop() === 'OGV') ||
                          (TokenFilePreUrl.split('.').pop() === 'ogv'))
                          &&
                          <video
                            id="imgPreview"
                            className="img-fluid"
                            alt="img"
                            autoPlay
                            controls
                            muted
                            playsInline
                            loop
                            src={TokenFilePreReader !== "" ? TokenFilePreReader : require('../assets/images/noimage.png')} type="video/mp4"
                          />

                        }

                        {(TokenFilePreUrl.split('.').pop() === 'mp3')
                          &&
                          <>
                            <img src={CONFIG.AudioImg} className="img-fluid" />

                            <audio
                              muted
                              id="imgPreview"
                              className="img-fluid"
                              alt="img"
                              autoPlay
                              controls
                              playsInline
                              loop
                              src={TokenFilePreReader !== "" ? TokenFilePreReader : require('../assets/images/noimage.png')} type="video/mp3"
                            >
                            </audio>
                          </>

                        }






                        {(TokenFilePreUrl.split('.').pop() === "webp"
                          || TokenFilePreUrl.split('.').pop() === "WEBP"
                          || TokenFilePreUrl.split('.').pop() === "gif"
                          || TokenFilePreUrl.split('.').pop() === "jpg"
                          || TokenFilePreUrl.split('.').pop() === "GIF"
                          || TokenFilePreUrl.split('.').pop() === "JPG"
                          || TokenFilePreUrl.split('.').pop() === "JPEG"
                          || TokenFilePreUrl.split('.').pop() === "jpeg"
                          || TokenFilePreUrl.split('.').pop() === "png"
                          || TokenFilePreUrl.split('.').pop() === "PNG")
                          &&
                          <img src={TokenFilePreReader !== "" ? TokenFilePreReader : require('../assets/images/noimage.png')} id="imgPreview" alt="Collections" className="img-fluid" />
                        }
                        {TokenFilePreReader === "" &&
                          <img src={require('../assets/images/noimage.png')} id="imgPreview" alt="Collections" className="img-fluid" />
                        }




                        {/* 

                        <img src={require('../assets/images/noimage.png')} id="imgPreview" alt="Collections" className="img-fluid" /> */}


                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-end">
                      <div>
                        {/* <h3 className="my-2 ">
                          <span className="text-gray">
                            { <span className="mr-1">Not for sale </span>}
                           
                          </span> </h3> */}


                        <button className="btn btn_purple_sm bg-theme" data-toggle="modal" data-target="#place_bid_multiple_modal"> <span>
                          Open for Bids 1 of 1</span></button>


                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </GridItem>
            <GridItem xs={12} sm={8} md={8}>
              <form className="formCls">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Upload file</label>
                        <p className="form_note mt-1">JPG, JPEG, PNG, GIF, WEBP, WEPM, OGV, MP3, FLAC, AAC or MP4. Max 30 megabytes
                        </p> 
                      </div>
                      <div className="file_btn btn primary_btn">Upload
                        <input
                          className="inp_file"
                          type="file"
                          name="file"
                          accept="audio/*,video/*,image/*"
                          onChange={selectFileChange}
                        />
                      </div>
                      <div className="file_btn btn primary_btn" onClick={Oncancel}>Cancel
                      </div>
                      {ValidateError.photo && <span className="text-danger img-file">{ValidateError.photo}</span>}
                    </div>
                  </div>

                </div>
                {
                <div className="form-row">
                  <label className="primary_label" htmlFor="price_new">Network</label>

                  <div className="form-row w-100">
                    <div className="form-group col-md-12">
                      <div className="input-group input_grp_style_1">


                        <Select
                          className="yes1 typecontrol3 form-control primary_inp typecontrol"
                          id="networkname"
                          name="networkname"
                          // onChange={networkoptionfunc}
                          onChange={selectChange}
                          options={networkoption}
                          label="Select price"
                          classNamePrefix="react-select"
                        // formControlProps={{
                        //   fullWidth: true
                        // }}
                        />
                        
                            {ValidateError.network && <span className="text-danger">{ValidateError.network}</span>}


                      </div>
                      <p className="form_note">Supported blockchains are BNB Smartchain and Ethereum Smartchain</p>
                    </div>
                  </div>
                </div>
                
                
}
                <div className="form-row">
                  <div className="form-group col-md-12">
                  <div className="d-flex justify-content-between align-items-start grid_toggle">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">List NFT</label>
                        <p className="form_note">List Your NFT in the MarketPlace</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox"
                          id="ListNFT"
                          name="ListNFT"
                          onChange={CheckedChange}
                          checked={ListNFT}

                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                    { ListNFT && 
                    <div className="d-flex justify-content-between align-items-start grid_toggle">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Put on Sale</label>
                        <p className="form_note">Decide how you want to sell your minted NFT by turning on the button.</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox"
                          id="putonsale"
                          name="putonsale"
                          onChange={CheckedChange}
                          checked={PutOnSale}

                        />
                        <span className="slider"></span>
                      </label>
                    </div>}
                    {
                      (PutOnSale === false) ? ('') : (
                        <>
                          <div className="row connect_row mt-3 putonsale_sec">
                            <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                              <div className={"create_box create_sing_bx " + ((PutOnSaleType === 'FixedPrice') ? 'active' : 'inactive')} id="fixedprice" onClick={() => { changePutOnSaleType('FixedPrice') }}>
                                <img src={require("../assets/images/price_svg.svg")} alt="Fixed Price" className="img-fluid" />
                                <p>Fixed Price</p>
                              </div>
                            </div>
                           
                            {(CollectibleType === 721) &&
                              <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                                <div className={"create_box create_sing_bx " + ((PutOnSaleType === 'TimedAuction') ? 'active' : 'inactive')} onClick={() => { changePutOnSaleType('TimedAuction') }}>
                                  <img src={require("../assets/images/timed_svg.svg")} alt="Timed Auction" className="img-fluid" />
                                  <p>Timed Auction</p>
                                </div>
                                
                              </div>}
                            
                            <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3" onClick={() => { changePutOnSaleType('UnLimitedAuction') }}>
                              <div className={"create_box create_sing_bx " + ((PutOnSaleType === 'UnLimitedAuction') ? 'active' : 'inactive')}>
                                <img src={require("../assets/images/unlimited_svg.svg")} alt="Unlimited Auction" className="img-fluid" />

                                <p>Unlimited Auction</p>
                              </div>
                              
                            </div>
                          </div>
                          <div className="content">
                          {PutOnSaleType == "FixedPrice" ? 
                          <div className="">
                            <p className="form-note"><b>Fixed Price</b></p>
                            <p className="form-note">This means you will only receive one price offer from purchasers</p>
                            </div> :<></>
                          }
                          {PutOnSaleType == "TimedAuction" ? 
                              <div className="">
                                <p className=""><b>Timed Auction:</b></p>
                              <p className="form-note">This means you will receive:</p>
                                <ul>
                                  <li>different bid price offers from purchasers </li>
                                  <li>a bid offer is valid at or above the Minimum Bid price set by the creator</li>
                                  <li>a bid offer is valid within a specified time frame set by the creator.</li>
                                  <li>The highest bid offer after the auction ends is the winning bid.</li>
                                </ul>
                              </div> :<></>
                          }
                          {PutOnSaleType == "UnLimitedAuction" ? 
                              <div>
                                <p><b>UnLimitedAuction</b></p>
                                <p className="form-note">This means there is no Minimum Bid and no Specified Duration for the auction to take place. As the minter of the NFT, you determine the Minimum Bid and the Specified Duration of the auction.</p>
                              </div> :<></>
                            }
                          </div>
                        </>)}
                    {(PutOnSale === true && PutOnSaleType === 'FixedPrice') &&
                      <div className="row mx-0 mt-3 fixed_price_sec">
                        <label className="primary_label" htmlFor="price_new">Price</label>

                        <div className="form-row w-100">
                          <div className="form-group col-md-6">
                            <div className="input-group input_grp_style_1">

                              <input
                                type="text"
                                className="form-control selct_form_input_h"
                                placeholder="0"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                name="TokenPrice"
                                id="TokenPrice"
                                step="0.01"
                                maxLength={15}
                                autoComplete="off"
                                onChange={inputChange}
                              />
                              <div className="input-group-append">
                                <Select
                                  className="yes1 form-control primary_inp select1 selxet_app"
                                  id="basic-addon2"
                                  name="coinname"
                                  onChange={priceoptionfunc}
                                  options={priceoption}
                                  label="Select price"
                                // formControlProps={{
                                //   fullWidth: true
                                // }}
                                />

                              </div>
                            </div>
                            {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                          </div>
                        </div>
                        <p className="form_note">Service fee
                          <span className="font_we_700_note_txt">{Wallet_Details.Service_Fee_seller / 1e18}% </span><br />
                          You will receive <span className="font_we_700_note_txt">
                            {/* {YouWillGet} */}
                            <Convert
                              item={Number(YouWillGet)}

                              coinName={CoinName}
                              convertVal={1}
                            />
                            {CoinName}
                          </span>
                          <span className="font_we_700_note_txt">
                            <br />


                            {/* $( {CoinName == "CAKE" ? Wallet_Details.Token_convertion[0].USD * TokenPrice : Wallet_Details.Token_convertion[0].USD * TokenPrice}) */}
                          </span>
                        </p>
                      </div>
                    }
                    {(PutOnSale === true && PutOnSaleType === 'TimedAuction') &&
                      <div className="row mt-3 timed_sec">
                        <div className="col-12 mb-3">
                          <label className="primary_label" htmlFor="price_new">Minimum bid</label>
                          <div className="input-group mb-1">
                            <input
                              type="text"
                              className="form-control selct_form_input_h"
                              placeholder="Enter minimum bid"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon3"
                              name="MinimumBid"
                              id="MinimumBid"
                              maxLength={CONFIG.maxLength}
                              onChange={inputChange}
                              autoComplete="off"
                            />
                            {ValidateError.MinimumBid && <span className="text-danger">{ValidateError.MinimumBid}</span>}
                            <div className="input-group-append">
                            

<Select
                                  className="yes1 form-control primary_inp select1 selxet_app"
                                  id="basic-addon2"
                                  isSearchable={false}
                                  name="coinname"
                                //  value={{ label: CoinName == config.currencySymbol ? priceoption[1].label : CoinName }}
                                onChange={priceoptionfunc}
                                  options={priceoption&&priceoption.filter(item => item?.label != Wallet_Details?.networkConfiguration?.currencySymbol)}
                                  label="Select price"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                />

                              {/* <p className="yes1 form-control primary_inp selxet_app symboldbo">{Wallet_Details.networkConfiguration.tokenSymbol
                              // NetworkName == 'binance' ? network.BSC.tokenSymbol : network.ETH.tokenSymbol
                              }</p> */}

                            </div>
                          </div>
                          {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}

                          <p className="form_note">   Bids below this amount won't be allowed. If you not enter any amount we will consider as 0</p>
                        </div>
                        <div className="col-12 col-lg-6  mb-3">
                          <div className="single_dd_1">
                            <label className="primary_label" htmlFor="start_date">Starting Date</label>
                            <div className="dropdown">
                              <button className="btn btn-secondary dropdown-toggle filter_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                {StartDate}<i className="fas fa-chevron-down"></i>
                              </button>
                              <div className="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                <div >Select Date</div>
                                <div id="RightAfterListing" onClick={() => { DateChange('StartDateDropDown', 'RightAfterListing') }}>Right after listing</div>
                                <div id="PickStart" onClick={() => { DateChange('StartDateDropDown', 'PickStartDate') }}>Pick specific date</div>
                              </div>
                            </div>
                            {ValidateError.Clocktime && <span className="text-danger">{ValidateError.Clocktime}</span>}
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <div className="single_dd_1">
                            <label className="primary_label" htmlFor="start_date">Expiration Date</label>
                            <div className="dropdown">
                              <button className="btn btn-secondary dropdown-toggle filter_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {EndDate}<i className="fas fa-chevron-down"></i>
                              </button>
                              <div className="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                <div>Select Date</div>
                                <div onClick={() => { DateChange('EndDateDropDown', '1 Day') }}>1 day</div>
                                <div onClick={() => { DateChange('EndDateDropDown', '3 Day') }}>3 days</div>
                                <div onClick={() => { DateChange('EndDateDropDown', 'PickEndDate') }}>Pick specific date</div>

                              </div>
                            </div>
                            {ValidateError.EndClocktime && <span className="text-danger">{ValidateError.EndClocktime}</span>}
                          </div>

                        </div>
                        <div className="col-12">
                          <p className="form_note_link_boild" data-toggle="modal" data-target="#learn_modal">Learn more how timed auctions work</p>

                        </div>

                      </div>


                    }</div>
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start grid_toggle">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Unlock Once Purchased</label>
                        <p className="form_note">Content will be unlocked after successful transaction.</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input
                          type="checkbox"
                          id="unlockoncepurchased"
                          name="unlockoncepurchased"
                          onChange={CheckedChange}
                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                    {(Unlockoncepurchased) &&
                      <div className="form-group unlock_sec">
                        <input type="text"
                          className="form-control primary_inp mb-1"
                          name="UnLockcontent"
                          id="UnLockcontent"
                          onChange={inputChange}
                          placeholder="Digital key, code to redeem or link to a file.." />
                        <p className="form_note">Tip: Markdown syntax is supported</p>
                      </div>
                    }

                    {ValidateError.UnLockcontent && <span className="text-danger">{ValidateError.UnLockcontent}</span>}

                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Name</label>
                    <input type="text"
                      className="form-control primary_inp"
                      name="TokenName"
                      id="TokenName"
                      onChange={inputChange}
                      placeholder="e.g. Redeemable" />
                    {ValidateError.TokenName && <span className="text-danger">{ValidateError.TokenName}</span>}

                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Description <span className="text-muted">(Optional)</span></label>
                    <textarea type="text"
                      className="form-control primary_inp"
                      id="desccription"
                      name="TokenDescription"
                      onChange={inputChange}
                      placeholder="You have an option to give description of your NFT"
                      autoComplete="off"
                    ></textarea>

                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="Collection">Select Collection</label>
                    <Select
                      className="yes1 typecontrol3 form-control primary_inp typecontrol"
                      id="userCollection"
                      name="userCollection"
                      onChange={selectChange}
                      maxMenuHeight={220}
                      options={CollectionList}
                      label="Select Collection"
                      classNamePrefix="react-select"
                      placeholder="Select Collection"
                      value={
                        UserCollection ? {
                          label: UserCollection,
                          value: UserCollection
                        } : ""
                      }
                    // formControlProps={{
                    //   fullWidth: true
                    // }}
                    />
                    {ValidateError.userCollection && <span className="text-danger">{ValidateError.userCollection}</span>}

                  </div>
                  <div className="form-group col-md-6 category">
                    <label className="primary_label" htmlFor="category">Category</label>
                    {/* <Select
                      className="yes1 typecontrol3 form-control primary_inp typecontrol"
                      id="tokenCategory"
                      name="tokenCategory"
                      onChange={selectChange}
                      maxMenuHeight={220}
                      options={CategoryOption}
                      classNamePrefix="react-select"
                      label="Select or type name"
                      placeholder="Select or type name"
                    // formControlProps={{
                    //   fullWidth: true
                    // }}
                    /> */}
                    <input type="text" placeholder="Select Category" className="form-control" 
                    value={CategorywithSubcategory?.display ? CategorywithSubcategory?.display : ""}
                    onClick={() => setCategory(!category)}
                    disabled={CategorywithSubcategory?.disabecategory ? CategorywithSubcategory.disabecategory : false }
                    />
                    {category == true ?
                    (
                    <><div className="dropdown">
                      
                      <ul className="dropdown-menu1 p-3">
                      {fullcategory?.length > 0  &&
                      fullcategory.map((val)=>{
                        return(
                        <li className="dropdown-sub">
                          {val.name}
                          <ul className="sub-dropdown">
                            {val.subcategory.map((sub)=>{
                              return(
                              <li onClick={()=>SettokenCategory(val,sub)}>{sub}</li>
                              )
                            })}
                          </ul>
                        </li>)
                      })
                      }
                        {/* <li className="dropdown-sub">Gaming
                          <ul className="sub-dropdown">
                            <li>Sports</li>
                            <li>Wearables</li>
                            <li>Accessories</li>
                          </ul>
                        </li>
                        <li className="dropdown-sub">Collectible
                          <ul className="sub-dropdown">
                            <li>Kiddies</li>
                            <li>Religion</li>
                            <li>Military</li>
                            <li>Households</li>
                            <li>Fashion</li>
                          </ul>
                        </li> */}
                      </ul>
                    </div></>):(<></>)}  
                    {ValidateError.TokenCategory && <span className="text-danger"><br />{ValidateError.TokenCategory}</span>}

                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="royalties">Royalties</label>
                    <div className="inp_grp">
                      <input type="text"
                        className="form-control primary_inp"
                        name="TokenRoyalities"
                        onChange={inputChange}
                        id="royalties"
                        placeholder="10%" />
                      <p className="inp_append">%</p>
                      <span>Note that you may choose not to receive royalty. As such, you type in 0% as royalty.</span>
                      {ValidateError.TokenRoyalities && <span className="text-danger"><br />{ValidateError.TokenRoyalities}</span>}

                    </div>

                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="properties">Properties <span className="text-muted">(Optional)</span></label>
                    <input type="text"
                      className="form-control primary_inp"
                      id="properties"
                      name="TokenProperties"
                      onChange={inputChange}
                      placeholder="e.g. size" />
                    <p className="form-note">You may choose to provide in the properties of the NFT for the buyer to get to know more about it.</p>
                  </div>
                  {location_pathname === 'create-multiple' &&
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="desccription">Number of copies</label>
                      <input
                        type="text"
                        className="form-control"
                        id="TokenQuantity"
                        name="TokenQuantity"
                        onChange={inputChange}
                        placeholder="e.g. 1"
                        value={TokenQuantity}
                        maxLength={3}
                        autoComplete="off"
                      />
                      <p className="form-note">Specify the number of copies of the NFT you want to mint.</p>
                      {ValidateError.TokenQuantity && <span className="text-danger"><br />{ValidateError.TokenQuantity}</span>}
                    </div>
                  }

                  { ListNFT &&
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="desccription">List Quantity</label>
                      <input
                        type="text"
                        className="form-control"
                        id="ListedQuantity"
                        name="ListedQuantity"
                        onChange={inputChange}
                        placeholder="e.g. 1"
                        value={ListedQuantity}
                        maxLength={3}
                        disabled={location_pathname === 'create-single'}
                        autoComplete="off"
                      />
                      <p className="form-note">Specify the number of copies you have to list for sale.</p>
                      {ValidateError.ListedQuantity && <span className="text-danger"><br />{ValidateError.ListedQuantity}</span>}
                    </div>
                  }

                { PutOnSale &&
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="desccription">Quantity for Sale</label>
                      <input
                        type="text"
                        className="form-control"
                        id="PutOnSaleQuantity"
                        name="PutOnSaleQuantity"
                        onChange={inputChange}
                        placeholder="e.g. 1"
                        value={PutOnSaleQuantity}
                        maxLength={3}
                        disabled={location_pathname === 'create-single'}
                        autoComplete="off"
                      />
                      <p className="form-note">Specify the number of copies you have to list for sale.</p>
                      {ValidateError.PutOnSaleQuantity && <span className="text-danger"><br />{ValidateError.PutOnSaleQuantity}</span>}
                    </div>
                  }

<div className="form-row w-100">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">
                      Social Media Links
                    </label>
                  </div>
                </div>
                <div className="social_add_sec">
                  <p className="text_title">Interactive media links, e.g. Facebook, Telegram, Twitter, Instagram, etc.</p>
                      
                      <div className="add_social">
                        <div className="btn_sec">
                      <Button type="button" className="btn btn-add-social" onClick={addFilters}> <AddIcon/></Button><span className="add_txt">Click to Add</span>
                      </div>
              
                      </div>
      {console.log('dgldklgkd',socialLinks)}
                      {filterRows.map((val,index) => (
                    <div className="url_card my-3" key={index}>
                     <label className="primary_label" htmlFor="name">
                      URL For
                    </label>
                      <div className="row first_row">
                        <div className="col-lg-3 mb-4 mb-lg-0">
                        <Select 
                          id = "urfor"
                          options={mappedData}
                          classNamePrefix="react-select"
                          className="yes1 typecontrol3 form-control primary_inp typecontrol" 
                          onChange= {(e)=>OnChange(e,index)}
                          value={
                            socialLinks[index]?.urlfor?
                            {
                              label:socialLinks[index].urlfor,
                              value:socialLinks[index].urlfor,
                            }
                            :''
                          }
                        />
                        {ValidateError['urlfor'+index] && (
                      <span className="text-danger">{ValidateError['urlfor'+index]}</span>
                    )}
                        </div>
                        <div className="col-lg-9 mb-4 mb-lg-0">
                          <div className="row">
                            <div className="col-10">
                        <form>
  <div class="form-group">
    <input type="text" class="form-control" id="url" aria-describedby="emailHelp" placeholder="Enter url" onChange={(e)=>OnChange(e,index)} value={socialLinks[index]?.url||''} />
    {ValidateError['url'+index] && (
                      <span className="text-danger">{ValidateError['url'+index]}</span>
                    )}
  </div>
  </form>
  </div>
  <div className="col-2"><img src={delete_png} onClick={()=>removeFilters(index)} alt="delete" className="img-fluid delete_png" width={30}/></div>
  </div>
 
                        </div>
                        </div>
                    </div>
                      ))}
                </div>

                </div>
                <div className="mb-3">
                <div className="d-flex agreement mb-0">
                <input type="checkbox" id="original" name="original" value="Agreement" checked={agree.original} onChange={()=>SetAgree({...agree,...{original:!agree.original}})}/>
                <label for="vehicle1" className="ml-2">I declare that this is an original artwork. I understand that no plagiarism is allowed, and that the artwork can be removed anytime if detected.</label>
               </div>
               {ValidateError.original && <span className="text-danger ms-3">{ValidateError.original}</span>}
                </div>
                <div className="mb-3">
                <div className="d-flex agreement mb-0">
                <input type="checkbox" id="termsandcondition" name="termsandcondition" value="Car" checked={agree.termsandcondition} onChange={()=>SetAgree({...agree,...{termsandcondition:!agree.termsandcondition}})}/>
                <label for="vehicle2" className="ml-2">I have read and I agree to all the NFT Terms & Conditions and all legal requirements of EDA</label>
              
                </div>
                {ValidateError.termsandcondition && <span className="text-danger ms-3">{ValidateError.termsandcondition}</span>}
                </div>
                <div>
                  <Button className="create_btn" data-toggle="model" data-target="#create_item_modal" onClick={CreateItem} >Create item</Button>
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
      {/* Choose Collection Modal */}
      <div className="modal fade primary_modal" id="choose_collection_modal" tabIndex="-1" role="dialog" aria-labelledby="choose_collection_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="choose_collection_modalLabel">Collection</h5>

              <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex align-items-center flex_block_mob">
                <div className="hex center">
                  <div className="hex__shape">
                    <div className="hex__shape">
                      <div className="hex__shape">
                        <img src={require("../assets/images/img_01.png")} alt="logo" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="font_14 font_600 line_20 mb-1 text_brown mb-3">We recommend an image of at least 400x400. Gifs work too.</p>
                  <div className="file_btn primary_btn d-inline-block btn_small">Choose File
                    <input className="inp_file" type="file" name="file" />
                  </div>
                </div>
              </div>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="name">Display Name <span className="text-muted">(30 available)</span></label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Enter token name" />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="desccription">Symbol <span className="text-muted">(required)</span></label>
                    <input type="text" className="form-control primary_inp" id="desccription" placeholder="Enter token symbol" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="name">Description <span className="text-muted">(Optional)</span></label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Spread some words about token collection" />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="desccription">Short url</label>
                    <input type="text" className="form-control primary_inp" id="desccription" defaultValue="Fayre.io/" />
                  </div>
                </div>
                <div className="text-center">
                  <Button className="create_btn btn-block">Create Collection</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* ens choose collection modal */}
      {/* create_item Modal */}
      {/* <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"> */}
      <div className="modal fade primary_modal" id="create_item_modal" tabIndex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-bs-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="create_item_modalLabel">Follow Steps</h5>

              <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="media approve_media">

                  {ApproveCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                  {ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                  {ApproveCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                  {ApproveCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}


                  <div className="media-body">
                    <p className="mt-0 approve_text">Upload Meta Data</p>
                    <p className="mt-0 approve_desc">IFPS refers to Inter Planetary File System. This is where your NFT file will be stored for easy access. </p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="create_btn btn-block" disabled={(ApproveCallStatus === 'processing' || ApproveCallStatus === 'done')} onClick={ApproveCall}>     {ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {ApproveCallStatus === 'init' && 'Approve'}
                    {ApproveCallStatus === 'processing' && 'In-progress...'}
                    {ApproveCallStatus === 'done' && 'Done'}
                    {ApproveCallStatus === 'tryagain' && 'Try Again'}
                  </Button>



                </div>



{approvecheck &&
                <div className="media approve_media">

{ApproveTrade === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
{ApproveTrade === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
{ApproveTrade === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
{ApproveTrade === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}


<div className="media-body">
  <p className="mt-0 approve_text">Get Approve </p>
  {/* <p className="mt-0 approve_desc">Upload meta contents to ipfs</p> */}
</div>
</div>
}
{approvecheck&&
<div className="text-center my-3">
<Button className="create_btn btn-block" disabled={(ApproveCallStatus !== 'done'||ApproveTrade === 'processing' || ApproveTrade === 'done')} onClick={getapproveFun}>     {ApproveTrade === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
  {ApproveTrade === 'init' && 'Approve'}
  {ApproveTrade === 'processing' && 'In-progress...'}
  {ApproveTrade === 'done' && 'Done'}
  {ApproveTrade === 'tryagain' && 'Try Again'}
</Button>



</div>
}










                <div className="media approve_media">


                  {MintCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                  {MintCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                  {MintCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                  {MintCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}

                  <div className="media-body">
                    <p className="mt-0 approve_text">Upload files & Mint token</p>
                    <p className="mt-0 approve_desc">Call contract method</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="create_btn btn-block" disabled={((ApproveCallStatus !== 'done'&& (ApproveTrade !== 'done'||approvecheck===false)) || ( MintCallStatus === 'processing' || MintCallStatus === 'done'))} onClick={MintCall}>


                    {MintCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {MintCallStatus === 'init' && 'Start'}
                    {MintCallStatus === 'processing' && 'In-progress...'}
                    {MintCallStatus === 'done' && 'Done'}
                    {MintCallStatus === 'tryagain' && 'Try Again'}



                  </Button>
                </div>

                <div className="media approve_media">

                </div>
                <div className="text-center my-3">

                </div>

                <div className="media approve_media">

                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end create_item modal */}


      {/* learn Modal */}
      <div className="modal fade primary_modal" id="learn_modal" tabIndex="-1" role="dialog" aria-labelledby="learn_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="learn_modalLabel">How timed auction work</h5>

              <button type="button" className="close"  data-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>When you put your item on timed auction, you choose currency, minimum bid, starting and ending dates of your auction.</p>

              <p>The bidder can only place a bid which satisfies the following conditions:</p>
              <ol>
                <li>
                  It is at least minimum bid set for the auction
                </li>
                <li>
                  It is at least 5% higher than the current highest bid

                </li>
              </ol>

              <p>Note that some bids may disappear with time if the bidder withdraws their balance. At the same time, some bids may reappear if the bidder has topped up their balance again.</p>

              <p>Auction cannot be cancelled after any valid bid was made. Any bid placed in the last 10 minutes extends the auction by 10 minutes.</p>

              <p>In the 48 hours after the auction ends you will only be able to accept the highest available bid placed during the auction. As with regular bids, you will need to pay some gas to accept it.Note that you can always decrease the price of your listing for free, without making a transaction or paying gas. You can view all your items here.</p>


            </div>
          </div>
        </div>
      </div>
      {/* end learn modal */}

      {/* calendar Modal */}
      <div className="modal fade primary_modal" id="calendar_modal" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>

              <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="pb-3">

                <Datetime
                  input={false}
                  value={Clocktime}
                  onChange={(value) => set_Clocktime(value)} />
              </div>
              <div className="text-center pb-3">
                <Button className="btn btn_blue" onClick={() => ModalAction('calendar_modal', 'hide')} id="doneStartDate">Done</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end calendar modal */}
      {/* calendar Modal */}
      <div className="modal fade primary_modal" id="calendar_modal_expire" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>

              <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="pb-3">
                <Datetime
                  input={false}
                  value={EndClocktime}
                  onChange={(value) => set_EndClocktime(value)}
                />
              </div>
            </div>
            <div className="text-center">
              <Button className="primary_btn" id="doneEndDate" onClick={() => ModalAction('calendar_modal_expire', 'hide')}>Done</Button>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
