//kslp
import React, { useEffect, useState, useRef } from "react";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link, useParams } from "react-router-dom";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

// Import css files
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// mysid
import TokenCard from './separate/TokenCard'
import {
    GetCategoryAction
} from '../actions/v1/token';
import isEmpty from "lib/isEmpty";
import '@metamask/legacy-web3'
import { toast } from 'react-toastify';


import Artstar from "components/Artpage/artstar";

import Carouselimg from "../assets/images/artimage/caroo.png";
import proimg from "../assets/images/artimage/proimg.png";

import ReactReadMoreReadLess from "react-read-more-read-less";

toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function Art(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const longText =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus fermentum venenatis pulvinar. Proin vitae lectus urna. Sed erat ipsum, maximus a elit nec, condimentum placerat ex. Ut tincidunt mi eget condimentum mollis. Pellentesque aliquam velit quis est varius, sed molestie dolor ultrices. Pellentesque eget dapibus eros, at blandit arcu. Duis id purus quis mi porttitor viverra vel tempus elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos posuere";

    const classes = useStyles();

    const [starcard, setstarcard] = useState([
        {
            "id": 1
        },
        {
            "id": 2
        },
        {
            "id": 3
        },
        {
            "id": 4
        },
        {
            "id": 5
        },
        {
            "id": 6
        },
        {
            "id": 7
        },
        {
            "id": 8
        },

    ]);

    const optionlist = {
        dots: false,
        infinite: true,
        autoplay: false,
        smartSpeed: 400,
        //loop:true,
        margin: 16,
        nav: true,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        speed: 1500,

        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 1
            },
            992: {
                items: 1
            },
            1000: {
                items: 1
            }
        }

    };

    const [carousels, setcrousels] = useState([
        {
            "id": 1
        },
        {
            "id": 2
        },
        {
            "id": 3
        },
        {
            "id": 4
        },
        {
            "id": 5
        },
        {
            "id": 6
        },
        {
            "id": 7
        },
    ])


    const { ...rest } = props;
    const { Param_category } = useParams()
    return (

        <div>
            <div className="home_header">
                <Header
                    color="transparent"
                    routes={dashboardRoutes}
                    brand={<>
                        <div className="light_logo"><a href="https://edaface.com/" target="_blank">
                            <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
                        <div className="dark_logo"><Link to="/">
                            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
                    </>}
                    rightLinks={<HeaderLinks />}
                    fixed
                    changeColorOnScroll={{
                        height: 20,
                        color: "white"
                    }}
                    {...rest}
                />
                <section>
                    <div className="container-fluid container-theme container-lg">

                        <div className="section-carousel pt-5">
                            <OwlCarousel className='owl-theme' {...optionlist}>
                                {carousels.map((e, i) =>
                                    <div class="item">
                                        <div class="card artdetail">
                                            <img class="card-img-top" src={Carouselimg} alt="Card image cap" />
                                            <button type="button" class="btn btn-primary">View Collection</button>
                                        </div>
                                    </div>
                                )}
                            </OwlCarousel>
                        </div>

                        <div className="profileimg">
                            <img src={proimg} alt="profileicon" />
                        </div>

                        <div className="contents">
                            <h3 className="mb-2">Arcus By Rik Oostenbroek</h3>
                            <p className="mb-0">By Lorem Ipsum</p>
                            <p className="font-weight-bold">40 items . 8088 ETH</p>
                            <ul>
                                <li>Items <span>127</span></li>
                                <li>Created <span>May 29 2023</span> </li>
                                <li>Chain <span>Ethereum</span></li>
                                <li>Category <span>Art</span> </li>
                            </ul>
                            <p className="text">
                            <ReactReadMoreReadLess
                                charLimit={190}
                                readMoreText={"See More ▼"}
                                readLessText={"See Less ▲"}
                                readMoreClassName="read-more-less--more"
                                readLessClassName="read-more-less--less"
                            >
                                {longText}
                            </ReactReadMoreReadLess>
                            </p>
                            {/* <p className="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since</p>
                            <p>See More <i class="fa fa-angle-down"></i></p> */}

                            <div className="columss pt-3">
                                <div><h5 className="mb-1">2ETH</h5><p>Total Volume</p></div>
                                <div><h5 className="mb-1">0.02ETH</h5><p>Floor Price</p></div>
                                <div><h5 className="mb-1">0.0036 WETH</h5><p>Best Offer</p></div>
                                <div><h5 className="mb-1">16%</h5><p>Listed</p></div>
                                <div><h5 className="mb-1">78</h5><p>Owners</p></div>
                            </div>
                        </div>
                        <div className="artcard pt-5">
                            <div className="row">
                                {starcard.map((e, i) =>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                        <Artstar />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
                <div id="logo_overlay" className="logo_ovelay">
                    <Footer />
                </div>
            </div>
        </div>

    );
}
