import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import {faqlists} from '../actions/v1/report'
import ReactHTMLParser from 'react-html-parser'

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #a30726;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #a30726;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Transaction(props) {
  const classes = useStyles();
  const { ...rest } = props;
 

  return (
    <div className="inner_header howItWorks">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
            <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid"/></Link></div>
            </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader resourcesswe"}>
        <div className="bg_red_1">
        <div className="container-fluid container-theme">

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="inner_title">Transactions on EdaFace Marketplace</h2>
              </div>
             
            </GridItem>
          </GridContainer>
        </div>
        </div>
        <div className="container mt-3">
        <div className="accordion" id="accordionExample">
  
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Types of Transactions</button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <p>EdaFace Marketplace is becoming one of the most popular NFT marketplaces. 
</p>
      <p>On EdaFace Marketplace, the following transactions can be carried out:
</p>
     <div className="accordionparalist">
            <h2>(a). Create NFTs</h2>
            <p>Creating an NFT is also referred to as Minting or Mining an NFT.</p>
     </div>
     <div className="accordionparalist">
            <h2>(b). Buy NFTs</h2>
            <p>On EdaFace Marketplace, you can buy an NFT as a Fixed Price or via an Auction.</p>
     </div>
     <div className="accordionparalist">
            <h2>(c). Sell any NFTs</h2>
            <p>You can send your NFTs from any crypto wallet to EdaFace Marketplace for advertising display and
marketing.
</p>
     </div>
   

      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      What You Need for Transaction
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <p>To be able to transact on EdaFace NFT Marketplace, you will need the followings:</p>
    
      <div className="accordionparalist">
                      <h2>(1). Open a Crypto Wallet</h2>
                          <p>The first thing you should have before starting to transact in NFTs is a good non-custodial crypto
wallet. </p>
                 <p>A good number of non-custodial wallets you can use on EdaFace Marketplace are available.
Common ones are:
</p>         
<ul>
    <li>Metamask</li>
    <li>Binance Chain Wallet</li>
    <li>WalletConnect</li>
    <li>Trust wallet</li>
    <li>MyEtherWallet</li>
</ul>
<p>For guidance on how to set up your non-custodial crypto wallet, check the following <span>short videos
here…. (hyperlink please)</span></p>
</div>
<div className="accordionparalist">
                      <h2>(2).  Open an account with EdaFace NFT Marketplace</h2>
                      <p>To Set Up your EdaFace Marketplace Account, follow these simple steps:</p>
      <div className="accordionparalist">
      <p>Step 1: Login to EdaFace NFT Marketplace: <span><a href="">www.nft.edaface.com</a></span>
</p>
      </div>
      <div className="accordionparalist">
      <p>Step 2: Click on “Connect wallet” on the EdaFace NFT Marketplace website and select your wallet. If
your wallet is open, it will be connected.
</p>
<p>What you have done is simply connecting your wallet to the EdaFace NFT Marketplace</p>
      </div>
      <div className="accordionparalist">
      <p> Step 3: Access Your NFTs
</p>
      </div>
      <div className="accordionparalist">
      <p> Step 4: Customise your EdaFace Marketplace account
<p>Your default EdaFace Marketplace account will be “Unnamed.” To customize your account, use the
“Settings” button at the topmost right of the platform.
</p>
<p>Follow the on-screen prompts to set up some extra layers of security and to agree with the terms
and conditions. You can then fill in your username, personal biodata and your email address.
</p>
<p>Congrats, your EdaFace Marketplace account is set up and you are ready to transact with NFTs on
the marketplace.
</p>
</p>
      </div>
</div>

      </div>
    </div>
  </div>
 
 
</div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
