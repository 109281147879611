import React, {useState,
    forwardRef,
    useImperativeHandle
} from 'react';
import { Button } from '@material-ui/core';
import '@metamask/legacy-web3'

import config from '../../lib/config';

import {
    reportFunc
  } from 'actions/v1/report';
import isEmpty  from 'lib/isEmpty';
import { toast } from 'react-toastify';

import { useSelector } from "react-redux";


toast.configure();
let toasterOption = config.toasterOption;

export const ReportNowRef = forwardRef((props, ref) => {

    
    const Wallet_Details = useSelector(state=>state.wallet_connect_context);

    const [description, setdescription] = React.useState("");
    const [item, Set_item] = React.useState({});
  
    const [reports,setreports]=React.useState("");
    const [reportSubmit,setreportSubmit]=React.useState(false);
   
    const [token_owner_detail,set_token_owner_detail]=React.useState({});
    
    var {
        UserAccountAddr
    } = props;

    useImperativeHandle(
        ref,
        () => ({
            async SubmitReport_Click(item,tokenOwn) {
            //console.log("report_item_list", item,tokenOwn.tokenOwner)
                if (item) {
                    Set_item(item);
                    set_token_owner_detail(tokenOwn)
                    //console.log("report_item_list")
                    setReport(false)
                    window.$('#report_modal').modal('show');
                }
            }
        }),
    )

    // ===============================report ==========================
    const reporttoken=async()=>{
        if(!isEmpty(reports)){
          setreportSubmit(false)
        var postdata={
          currAddr:Wallet_Details.UserAccountAddr,
          imageOwner:token_owner_detail.tokenOwner,
          imageName:item.tokenName,
          imagehash:item.ipfsimage,
          report:reports,
          imageContractAddress:item.contractAddress,
          imageType:item.type,
          imageId:item.tokenCounts,
          noofitems:token_owner_detail.balance ,
          burnToken: token_owner_detail.burnToken    
        }
        ////////console.log("vdsgfvdsgf"+JSON.stringify(postdata))
        var reportdata=await reportFunc(postdata)
        ////////console.log("vdsgfvdsgf"+JSON.stringify(reportdata))
        if(reportdata.data){
            window.$('#report_modal').modal('hide');
          toast.success('reported successfully',toasterOption)
        //   document.getElementById('closereport').click()
         }
        else{
          toast.success('Please try again some other time',toasterOption)
        }
        }
        else{
         setreportSubmit(true)
          document.getElementById('reportmessage').innerHTML="Message Can't be empty"
        }
      }


  const[report,setReport]=useState(false);


  
    return (
        <div>
            <div className={report?"d-none modal fade primary_modal":"modal fade primary_modal"} id="report_modal" tabIndex="-1" role="dialog" aria-labelledby="report_modalCenteredLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="report_modalLabel">Report This Profile?</h5>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{window.$('#report_modal').modal('hide');}}>

                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <p className="font_14 font_600 line_20 mb-1 text_drk_bl">Tell us how this user violates the rules of the site</p>
                                <div className="form-row mt-3">
                                    <div className="form-group col-md-12">

                                        <label className="primary_label" htmlFor="name">Message</label>
                                        <textarea
                                          //value={description} 

                                        className="form-control primary_inp"
                                         id="report"
                                         name="report"
                                         rows="3" 
                                         value={reports}
                                         onChange={(e)=>{setreports(e.target.value);
                                            setreportSubmit(false)
                                            }}
                                         placeholder="Tell us some details"
                                         
                                          ></textarea>
                                      
                                    </div>
                                </div>
                                <div className="text-center mt-4">
                                    <Button  className="primary_btn  btn_white btn_big_white w-100" disabled={reportSubmit} onClick={()=>reporttoken()}>Report</Button>
                                    <p className="text-danger" id="reportmessage"></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

