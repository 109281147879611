// import React, {
//     forwardRef,
// } from 'react'
// import { Button } from '@material-ui/core';
// import config from '../../lib/config';
// import { toast } from 'react-toastify';
// import { useSelector } from "react-redux";


import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle
} from 'react';
import {
    useHistory,
    useLocation
} from "react-router-dom";
import { Button } from '@material-ui/core';


import Web3 from 'web3';
import '@metamask/legacy-web3'

// import EXCHANGE from '../../ABI/EXCHANGE.json'
import DETH_ABI from '../../ABI/DETH_ABI.json';
import config from '../../lib/config';

import {

    PurchaseNow_Complete_Action,
    checkOtherPlatformDetais1155,
    PurchaseNow_Complete_Action_Meta,
    PurchaseNow_Price_Action,
} from '../../actions/v1/token';
import Convert from './Convert';
import {

    toFixedNumber
} from '../../actions/v1/user';
import SINGLE from '../../ABI/SINGLE.json';
import MULTIPLE from '../../ABI/MULTIPLE.json';
import isEmpty from 'lib/isEmpty'
import { toast } from 'react-toastify';
// import { GetItemList } from 'actions/items';
import Trade from '../../ABI/Trade.json'
import { getReceipt } from '../../actions/v1/getReceiptFunc'
import { useSelector, useDispatch } from "react-redux";
import { network } from "../../views/network"
import { connectWallet, WalletConnect } from '../../views/hooks/useWallet';
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import Market from '../../ABI/market.json'

toast.configure();
let toasterOption = config.toasterOption;



export const PurchaseNowRef = forwardRef((props, ref) => {

    const dispatch = useDispatch();

    const history = useHistory()
    const Wallet_Details = useSelector(state => state.wallet_connect_context);

    var {
        UserAccountAddr,
        UserAccountBal,
        TokenBalance,
        Wen_Bln,

        MyItemAccountAddr_Details
    } = props;

    const location = useLocation();
    var location_name = location.pathname;


    const [ApproveCallStatus, setApproveCallStatus] = React.useState('init');
    const [PurchaseCallStatus, setPurchaseCallStatus] = React.useState('init');



    // var PurchaseBalance = 0;
    // var PurchaseCurrency = '';
    // if (Wallet_Details.UserAccountBal !== 0
    //     // config.PurchaseTransferType == 'token'
    // ) {
    //     //console.log("frbhafrbh",PurchaseBalance);
    //     PurchaseBalance = TokenBalance;
    //     // PurchaseCurrency = config.tokenSymbol;
    //     PurchaseCurrency = Wallet_Details.networkConfiguration.tokenSymbol;
    // }
    // else {
    //     PurchaseBalance = Wallet_Details.UserAccountBal;
    //     PurchaseCurrency = Wallet_Details.networkConfiguration.currencySymbol;
    // }




    const [MultipleWei, Set_MultipleWei] = React.useState(0);
    const [NoOfToken, Set_NoOfToken] = React.useState(1);

    const [FormSubmitLoadings, Set_FormSubmitLoading] = React.useState('start');

    const [ValidateError, Set_ValidateError] = React.useState({});
    const [YouWillPay, Set_YouWillPay] = React.useState(0);

    const [TokenPrice, Set_TokenPrice] = React.useState(0);
    const [CoinName, set_CoinName] = React.useState(0);
    const [BuyOwnerDetails, set_BuyOwnerDetail] = React.useState({});
    const [Mul_YouWillPay, Set_Mul_YouWillPay] = React.useState(0);

    const [NewTokenUSValue, setNewTokenUSValue] = React.useState(0);

    const [NewTokenAddress, setNewTokenAddresss] = React.useState('');
    const [item, Set_item] = React.useState(props.item);
    const [NewTokenDecimal, setNewTokenDecimal] = React.useState(0);
    const [currentChainId, setcurrentChainId] = useState('')
    const [PurchaseBalance, Set_PurchaseBalance] = React.useState(0);
    useEffect(() => {
        Set_ValidateError({});
        TokenValue()
        ItemValidation({ NoOfToken: NoOfToken, TokenPrice: TokenPrice });
    }, [
        NoOfToken,
        TokenPrice
    ]);


    useEffect(() => {

        PriceCalculate();
        TokenValue()
    }, [NewTokenDecimal, NewTokenAddress]);



    async function FormSubmit() {
        window.$('#PurchaseNow_modal').modal('hide');
        window.$('#PurchaseStep_modal').modal('show');

    }


    const TokenValue = () => {
        if(typeof Wallet_Details.currency_convertion === 'object'){
           
    
            if(!isEmpty(item)){

            var check = Wallet_Details.Token_convertion.filter(items => (items.label == item.tokenowners_current.CoinName))
            var Pricecal = (Wallet_Details.currency_convertion.currency === item.tokenowners_current.CoinName) ?
            (Wallet_Details.currency_convertion.USD * item.tokenowners_current.tokenPrice * NoOfToken)
            :

             (check[0].USD * item.tokenowners_current.tokenPrice * NoOfToken)
            
             //console("Srfhgdsfh",Pricecal);
            //  setusdCal(Pricecal)
             setNewTokenUSValue(Number(Pricecal))
            //  return 
            
            }
        }
    }


    const inputChange = (e) => {
        Set_FormSubmitLoading('start')
        if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
            var value = e.target.value;
            switch (e.target.name) {
                case 'NoOfToken':
                    Set_NoOfToken(value);
                    PriceCalculate({ quantity: Number(value) });
                    //////console.log("PriceCalculate",value)
                    break;
                case 'TokenPrice':
                    Set_TokenPrice(value);
                    if (value !== '' && isNaN(value) === false && value > 0) {
                        PriceCalculate({ price: Number(value) });
                        //////console.log("PriceCalculate",value)
                    }
                    break;
                default:
                // code block
            }
            // ItemValidation({TokenPrice:value});
        }
    }

    const PriceCalculate = async (data = {}) => {
        var web3 = new Web3(Wallet_Details.providerss)
        var price = (typeof data.price != 'undefined') ? data.price : TokenPrice;
        var quantity = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;
        var royalty = (typeof data.tokenRoyality != 'undefined') ? data.tokenRoyality : item.tokenRoyality
        var newPrice = item.type === 721 ? (price * 1000000) : (quantity * (price * 1000000));
        var toMid = newPrice
        //console.log("dngsfgnghm",Wallet_Details.Service_Fee_buyer);
        var serfee = (toMid / 100000000) * ((Wallet_Details.Service_Fee_buyer / config.decimalvalues) * 1000000)
        var totfee = serfee + toMid
       // var tot2cont = web3.utils.toWei(String(Number((Number(totfee)) / 1000000)))
        var tot2cont = null
        var aprrove = null
        var youwillpay = totfee / 1e6
        if(NewTokenDecimal == 18)
        {
                tot2cont = web3.utils.toWei(String(Number((Number(totfee))/1000000)))
                aprrove = web3.utils.toWei(String(Number((Number(totfee))/1000000)))
        }
        else{
                tot2cont = web3.utils.toWei(String(Number((Number(totfee))/1000000)))
                var dec = 18 - (NewTokenDecimal);
               // aprrove = ((Number(totfee))/1000000)*(10**(NewTokenDecimal))
               aprrove = ((tot2cont)/10**dec)
        }
        //console.log("ZDhzdhzd",youwillpay);

        Set_YouWillPay(youwillpay);
        Set_Mul_YouWillPay(aprrove)
        Set_MultipleWei(String(tot2cont));
    }


    const ItemValidation = async (data = {}) => {
        var ValidateError = {};

        var Chk_TokenPrice = (typeof data.TokenPrice != 'undefined') ? data.TokenPrice : TokenPrice;
        var quantity = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;

        var Collectible_balance = 0;
        if (BuyOwnerDetails && BuyOwnerDetails.balance) {
            Collectible_balance = BuyOwnerDetails.balance;

        }

        if (quantity === '') {
            ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
        }
        else if (quantity === 0) {
            ValidateError.NoOfToken = '"Quantity" must be greater than 0';;
        }
        else if (isNaN(quantity) === true) {
            ValidateError.NoOfToken = '"Quantity" must be a number';
        }
        // if(quantity > BuyOwnerDetails.balance) {
        //     ValidateError.NoOfToken = '"Quantity" must be below on '+BuyOwnerDetails.balance;
        // }
        if (quantity > (BuyOwnerDetails.putonsalequantity > 1 ? BuyOwnerDetails.putonsalequantity : BuyOwnerDetails.balance)) {
            ValidateError.NoOfToken = '"Quantity" must be below on ' + BuyOwnerDetails.putonsalequantity;
        }

        if (Chk_TokenPrice === '') {
            ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
        }
        else if (Chk_TokenPrice === 0) {
            ValidateError.TokenPrice = '"Token Price" must be greater than 0';;
        }
        else if (isNaN(Chk_TokenPrice) === true) {
            ValidateError.TokenPrice = '"Token Price" must be a number';
        }


        else if (String(BuyOwnerDetails.CoinName).toLowerCase() === String(Wallet_Details.networkConfiguration.tokenSymbol).toLowerCase()) {
            ////alert('1')
            if (Wallet_Details.Wen_Bln === 0) {
                //////////console.log("Test@321 balance Zero")
                Set_FormSubmitLoading('error');

            }
            else if (Wallet_Details.Wen_Bln < Chk_TokenPrice) {
                //////////console.log("Test@321 balance Zero check")
                Set_FormSubmitLoading('error');

            }
            // ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';

        }
        else if (String(BuyOwnerDetails.CoinName).toLowerCase() !== String(Wallet_Details.networkConfiguration.tokenSymbol).toLowerCase()) {

            if (Wallet_Details.UserAccountBal === 0) {
                //////////console.log("Test@321 balance Zero")
                Set_FormSubmitLoading('errors');

            }
            else if (Wallet_Details.UserAccountBal < Chk_TokenPrice) {
                //////////console.log("Test@321 balance Zero check")
                Set_FormSubmitLoading('errors');

            }
            // ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';

        }

        else {
            // await props.GetUserBal();
            if (Chk_TokenPrice > Wallet_Details.UserAccountBal) {
                ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';
            }
            else {
                delete ValidateError.TokenPrice;
            }
        }

        if (!isEmpty(ValidateError)) {
            Set_FormSubmitLoading('errors1')
        }
        if (NoOfToken > 0) {
            Set_FormSubmitLoading('start')
        }
        Set_ValidateError(ValidateError);
        return ValidateError;
    }


    async function BalanceCalculation(Coin) {
        try{
            if (Wallet_Details.providerss && Coin) {
                //console("You will pay",YouWillPay,singleAddress,Wallet_Details.providerss)
                var web3 = new Web3(Wallet_Details.providerss)
                if (web3) {
                  var web3 = new Web3(Wallet_Details.providerss);
                  if(Coin !== Wallet_Details.networkConfiguration.currencySymbol)
                  {
                  const MultiContract = new web3.eth.Contract(Market, Wallet_Details.networkConfiguration.TradeContract);
                  //console("Contract Address1", Coin)
                  var tokenAddress = await MultiContract.methods.getTokenAddress(Coin).call()
                  // ////console("Contract Address", tokenAddress,Coin)
                  const TokenObj = new web3.eth.Contract(DETH_ABI, tokenAddress);
                  var TokenBalance = Number(await TokenObj.methods.balanceOf(Wallet_Details.UserAccountAddr).call())
                  var TokenDecimal = Number(await TokenObj.methods.decimals().call())
                  Set_PurchaseBalance(TokenBalance / (10 ** (TokenDecimal)))
                  //console.log("dfbghgrhgthtgbh", TokenDecimal,tokenAddress)
                  setNewTokenDecimal(TokenDecimal)
                  setNewTokenAddresss(tokenAddress)
                  console.log("fghgtrhgfthgfh", typeof TokenBalance)
                  }
                  else{
                      Set_PurchaseBalance(Wallet_Details.UserAccountBal)
                  }
                  // setNewTokenBalance(Token_balance)
          
          
                }
              }
        }
        catch(error){
            console.log('BalanceCalculation error',error)
        }
        
    
      }


    useImperativeHandle(
        ref,
        () => ({
            async PurchaseNow_Click(item, BuyOwnerDetail = {}) {
                if (Wallet_Details.UserAccountAddr !== "" ||Wallet_Details.UserAccountAddr!==null ) {

                var chainid =item?.tokenowners_current[0]?.SelectedNetwork
                //console.log("fgndfgn",chainid);
                var accountDetailsss=''
//console.log("dfbhsfdbn",item,Wallet_Details.networkConfiguration.Chainid,item.tokenowners_current[0].SelectedNetwork);

if(Wallet_Details.networkConfiguration.Chainid==item.tokenowners_current[0].SelectedNetwork)
{
    accountDetailsss=true
}
else{
    var accountDetailsss =await  switchNetwork(chainid)
}
// setTimeout(async() => {
 //console.log("dfbhsfdbhsfg",chainid , Wallet_Details.networkConfiguration.Chainid);
    
    if (chainid === Wallet_Details.networkConfiguration.Chainid) {
            var web3 = new Web3(Wallet_Details.providerss)

            if (BuyOwnerDetail && typeof BuyOwnerDetail.tokenOwner != 'undefined') {
                item.tokenowners_current = {};
                item.tokenowners_current = BuyOwnerDetail;
            }

            if (item) {
                var data ={
                    Singleaddress:Wallet_Details.networkConfiguration.singleContract,
                    multipleContract:Wallet_Details.networkConfiguration.multipleContract
                }
                var balance = await checkOtherPlatformDetais1155(item, BuyOwnerDetail, item.type, web3,data,Wallet_Details.networkConfiguration.TradeContract);
                console.log('zdxvbalanczxcve>>>zxvc>>>>>zxv>', balance,BuyOwnerDetail)
                if (Number(balance) !== BuyOwnerDetail.balance) {
                    toast.warning("You won't buy at this moment please refresh you data", toasterOption);
                    setTimeout(() => {
                        history.push("/")
                    }, 1000);
                    return false;
                }
                else {
                    Set_item(item);
                    if (BuyOwnerDetail && BuyOwnerDetail.tokenPrice) {
                        Set_TokenPrice(BuyOwnerDetail.tokenPrice);
                    }
                    set_BuyOwnerDetail(BuyOwnerDetail)
                    if (BuyOwnerDetail && BuyOwnerDetail.CoinName) {
                        set_CoinName(BuyOwnerDetail.CoinName)
                        BalanceCalculation(BuyOwnerDetail.CoinName)
                    }

                    Set_NoOfToken(1);
                    PriceCalculate({ quantity: 1, price: BuyOwnerDetail.tokenPrice, tokenRoyality: item.tokenRoyality });
                    window.$('#PurchaseNow_modal').modal('show');
                }
            }
        }

        else {
            if(Wallet_Details.UserAccountAddr==="")
            {
            window.$('#connect_modal').modal('show');
            }

        }
     }


// }, 1000);
              
            }
        }))




        async function switchNetwork(configdata)
        {
          var type = ""
        var networkConfiguration ={}
        if(configdata)
        {
            if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {
        
              type = "MetaMask"
            }
            else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
              type = "WalletConnect"
            }

         window.$('#connect_modal').modal('hide');
            //console.log("connecttype............", type,configdata.Chainid,configdata)
            var accountDetails = await connectWallet(type,configdata)
            //console.log("accountDetailsin create page......................", accountDetails)
            //  const id=toast.loading("Wallet Connecting...")
         var web3 = new Web3(window.ethereum);
          //console.log("dfghrtfh",web3);
          if(window.ethereum.isMetaMask == true){
            const chainId = await web3.eth.getChainId();
            //console.log("fghdtgj",chainId);
        
            if(chainId===network.ETH.Chainid)
            {
                  networkConfiguration=network.ETH
            }
            else if(chainId===network.BSC.Chainid){
              networkConfiguration=network.BSC
            }
        
        
          }
         // setPriceoption()
        
            if (accountDetails != '') {
              //console.log("Wallet Connecting...increate", accountDetails.web3._provider);
              dispatch({
                type: Account_Connect,
                Account_Detail: {
                  UserAccountAddr: accountDetails.accountAddress,
                  UserAccountBal: accountDetails.coinBalance,
                  WalletConnected: "true",
                  Wen_Bln: accountDetails.tokenBalance,
                  Accounts: accountDetails.accountAddress,
                  providerss: accountDetails.web3._provider,
                  networkConfiguration:networkConfiguration
                }
              })
            }
          }
        
        
        
          //   var ReqData = {
          //     addr: String(accountDetails.accountAddress).toLowerCase()
          // }
          // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
          // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
          //     return Resp.data.data.User
          // } else {
          //     return null
          // }
        
        
        
        
        
        return accountDetails
        }



    // async function switchNetwork(NFTChainid) {
    //     var type = ""
    //     //console.log("hgmdghkmyhmk",NFTChainid)
    //     if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

    //         type = "MetaMask"
    //     }
    //     else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
    //         type = "WalletConnect"
    //     }


    //     window.$('#connect_modal').modal('hide');
     
    //     var accountDetails = await connectWallet(type, NFTChainid)
    //     //console.log("accountDetailsin create page......................", accountDetails)



    //     if (accountDetails != '') {
    //         //console.log("Wallet Connecting...", accountDetails.web3._provider);
    //         dispatch({
    //             type: Account_Connect,
    //             Account_Detail: {
    //                 UserAccountAddr: accountDetails.accountAddress,
    //                 UserAccountBal: accountDetails.coinBalance,
    //                 WalletConnected: "true",
    //                 Wen_Bln: accountDetails.tokenBalance,
    //                 Accounts: accountDetails.accountAddress,
    //                 providerss: accountDetails.web3._provider
    //             }
    //         })
    //     }




    //     return accountDetails



    // }


console.log('jfksjflsls',item)
    async function FormSubmit_StepTwo() {
        setPurchaseCallStatus('start')
    
        setApproveCallStatus('process')
        if (Wallet_Details.providerss) {
            var web3 = new Web3(Wallet_Details.providerss)
            if (web3 ) {
               
                 var web3 = new Web3(Wallet_Details.providerss);
                var Approves = null
                var handle = null;
                var receipt = null;
                var senfCon = (Number(Mul_YouWillPay) / 1e6)
              var SendMntCon =null
var tokenAdd = Wallet_Details.networkConfiguration.tokenAddr[Wallet_Details.networkConfiguration.tokenSymbol]
                try{
                var bidvalue = new web3.eth.Contract(DETH_ABI,NewTokenAddress);
                var getAllowance = await bidvalue
                .methods
                .allowance(
                  Wallet_Details.UserAccountAddr,
                  Wallet_Details.networkConfiguration.TradeContract 
                ).call()
                
//console.log("sdfnhbsfgt",Mul_YouWillPay,getAllowance);
                if(NewTokenDecimal == 18){
                     SendMntCon=web3.utils.toWei(String(Number(web3.utils.fromWei(String(Mul_YouWillPay)))+Number(web3.utils.fromWei(String(getAllowance)))))

//console.log("adfbhadb",SendMntCon);
                }
                else{
                     SendMntCon=(((Number(Mul_YouWillPay))/(10**(NewTokenDecimal)))+((Number(getAllowance))/(10**(NewTokenDecimal))))*(10**(NewTokenDecimal))
                }

                //var SendMntCon = web3.utils.toWei(String(Mul_YouWillPay))

                }catch(err)
                {
                    //console.log("dfbafsgnh",err);
                }
                try {
                
                    // if (item.contractAddress === Wallet_Details.networkConfiguration.singleContract ||
                    //     item.contractAddress === Wallet_Details.networkConfiguration.multipleContract 
                   
                    //     ) {
                        // if (item.type === 721) {
                            //console.log("item.contractAddress,SendMntCon", item.contractAddress,SendMntCon);
                            await bidvalue.methods.approve(
                                Wallet_Details.networkConfiguration.TradeContract,
                                SendMntCon
                            ).send({
                                from: Wallet_Details.UserAccountAddr,
                            })
                                .on('transactionHash', async (transactionHash) => {
                                    handle = setInterval(async () => {
                                        receipt = await getReceipt(web3, transactionHash)
                                        clr1();
                                    }, 8000)
                                })
                        // }
                        // else {
                            // await bidvalue.methods.approve(
                            //     Wallet_Details.networkConfiguration.TradeContract,
                            //     SendMntCon

                            // ).send({
                            //     from: Wallet_Details.UserAccountAddr,
                            // })
                            //     .on('transactionHash', async (transactionHash) => {
                            //         handle = setInterval(async () => {
                            //             receipt = await getReceipt(web3, transactionHash)
                            //             clr1();
                            //         }, 8000)
                            //     })

                        // }
                    // }
                    // else {
                    //     await bidvalue.methods.approve(
                    //         Wallet_Details.networkConfiguration.trade,
                    //         SendMntCon
                    //     ).send({
                    //         from: Wallet_Details.UserAccountAddr,
                    //     })
                    //         .on('transactionHash', async (transactionHash) => {
                    //             handle = setInterval(async () => {
                    //                 receipt = await getReceipt(web3, transactionHash)
                    //                 clr1();
                    //             }, 8000)
                    //         })

                    // }
                }
                catch (e) {
                    setApproveCallStatus('try')
                    //console.log("dskljdlasd",e)
                }
                async function clr1() {
                    if (receipt != null) {
                        clearInterval(handle);
                        if (receipt.status === true) {
                            setApproveCallStatus('done')
                            setPurchaseCallStatus('init')
                        }
                    }
                }
            }
            else {
                window.$('#connect_modal').modal('show');
            }
        }
    }


    async function FormSubmit_StepOne() {
        if (Wallet_Details.providerss) {
            var web3 = new Web3(Wallet_Details.providerss)
            if (
                web3
            ) {
                var handle = null;
                var receipt = null;
                var CoursetroContract = null
                var contractCall = null;
                //console.log("fdxbhzdf",BuyOwnerDetails.tokenPrice,NoOfToken);
                var sendAmount = web3.utils.toWei(String(Number(Number(BuyOwnerDetails.tokenPrice * 1e6) * NoOfToken) / 1e6));
                //console.log("sendAmount",sendAmount);
                var web3 = new Web3(Wallet_Details.providerss);
                try {
                    setPurchaseCallStatus('processing');
                    // if (item.contractAddress === Wallet_Details.networkConfiguration.singleContract ||
                    //     item.contractAddress === Wallet_Details.networkConfiguration.multipleContract 
                      
                    //     ) {

                        // if (item.type === 721) {
                            
                            CoursetroContract = new web3.eth.Contract(
                                Market,
                                Wallet_Details.networkConfiguration.TradeContract   
                            )
                            // if (String(CoinName).toLowerCase() === (config.tokenSymbol).toLowerCase()) {

                            

                                if (String(CoinName).toLowerCase() !== String(Wallet_Details.networkConfiguration.currencySymbol).toLowerCase()  ) {
                                    //ids[0] - tokenId, ids[1] - amount, ids[2] -  nooftoken, ids[3] - nftType

 var ids = [item.tokenCounts,sendAmount.toString(),NoOfToken,item.type,item?.tokenowners_current?._nonce, sendAmount.toString() ]
 
try{

                                contractCall = await CoursetroContract.methods
                                    .saleWithToken(
                                        BuyOwnerDetails.CoinName.toString(),
                                        BuyOwnerDetails.tokenOwner,
                                        ids,
                                        // sendAmount.toString(),
                                        item.tokenowners_current.contractAddress,
                                        item?.tokenowners_current?.Randomname,
                                        item?.tokenowners_current?.signature,

                                    )
                                    .send({
                                        from: Wallet_Details.UserAccountAddr,
                                    })
                                    .on('transactionHash', async (transactionHash) => {
                                        // handle = setInterval(async () => {
                                            receipt = await getReceipt(web3, transactionHash)
                                            clr1();
                                        // }, 8000)
                                    })

                        }catch(err)
                        {
                            //console.log("fgaehrthr",err);
                        }

                            }
                            else {
                                
                                //console.log("elseeeeeeeee",item);
                                //console.log("BuyOwnerDetails.tokenOwner",BuyOwnerDetails.tokenOwner,"item.tokenCounts,",item.tokenCounts,"sendAmount.toString()",sendAmount.toString(),"MultipleWei",MultipleWei);
                         try{
                            var ids = [item.tokenCounts,sendAmount.toString(),NoOfToken,item.type,item?.tokenowners_current?._nonce, sendAmount.toString() ]
                            

                            console.log("SDgbdfsbhsfdbfgb",sendAmount.toString(),BuyOwnerDetails.tokenOwner,ids,item.tokenowners_current.contractAddress,item?.tokenowners_current?.Randomname,item?.tokenowners_current?.signature,MultipleWei,typeof(web3.utils.fromWei(MultipleWei)));

    // ids[0] - tokenId, ids[1] - amount, ids[2] -  nooftoken, ids[3] - nftType, isd[4] - _nonce, isd[5] - signatureValue

                                contractCall = await CoursetroContract.methods
                                    .saleToken(
                                        // sendAmount.toString(),
                                        BuyOwnerDetails.tokenOwner,
                                        ids,
                                        item.tokenowners_current.contractAddress,
                                        item?.tokenowners_current?.Randomname,
                                        item?.tokenowners_current?.signature,
                                    )
                                    .send({
                                        from: Wallet_Details.UserAccountAddr,
                                        value: MultipleWei,
                                        
                                    })
                                    .on('transactionHash', async (transactionHash) => {
                                        handle = setInterval(async () => {
                                            receipt = await getReceipt(web3, transactionHash)
                                            clr1();
                                        }, 8000)
                                    })
                                }catch(err)
                                {
                                    console.log("contract error",err);
                                }

                            }
                        

                        // }
                    //     else {
                    //         CoursetroContract = new web3.eth.Contract(
                    //             MULTIPLE,
                    //             item.contractAddress
                    //         )
                    //         if (String(CoinName).toLowerCase() === (Wallet_Details.networkConfiguration.tokenSymbol).toLowerCase()) {
                    //             ////console.log("Towei contract"," BuyOwnerDetails.tokenOwner", BuyOwnerDetails.tokenOwner,"item.tokenCounts",item.tokenCounts,"sendAmount",sendAmount,"NoOfToken",NoOfToken)
                    //             contractCall = await CoursetroContract.methods
                    //                 .saleWithToken(
                    //                     BuyOwnerDetails.tokenOwner,
                    //                     item.tokenCounts,
                    //                     sendAmount.toString(),
                    //                     NoOfToken,
                    //                     CoinName,

                    //                 )
                    //                 .send({
                    //                     from: Wallet_Details.UserAccountAddr
                    //                 })
                    //                 .on('transactionHash', async (transactionHash) => {
                    //                     handle = setInterval(async () => {
                    //                         receipt = await getReceipt(web3, transactionHash)
                    //                         clr1();
                    //                     }, 8000)
                    //                 })

                    //         }
                    //         else {
                    //             // //console.log("hgjkhfdkjighdfkjkjfdhk",  BuyOwnerDetails.tokenOwner,item.tokenCounts,NoOfToken);
                    //             contractCall = await CoursetroContract.methods

                    //                 .saleToken(
                    //                     BuyOwnerDetails.tokenOwner,
                    //                     item.tokenCounts,
                    //                     sendAmount.toString(),
                    //                     NoOfToken,
                    //                 )
                    //                 .send({
                    //                     from: Wallet_Details.UserAccountAddr,
                    //                     value: web3.utils.fromWei(web3.utils.toWei(((MultipleWei))))
                    //                 })
                    //                 .on('transactionHash', async (transactionHash) => {
                    //                     handle = setInterval(async () => {
                    //                         receipt = await getReceipt(web3, transactionHash)
                    //                         clr1();
                    //                     }, 8000)
                    //                 })
                    //         }

                    //     }
                    // }
                    // else {
                    //     CoursetroContract = new web3.eth.Contract(
                    //         Trade,
                    //         Wallet_Details.networkConfiguration.trade
                    //     )
                    //     if (String(CoinName).toLowerCase() === (Wallet_Details.networkConfiguration.tokenSymbol).toLowerCase()) {
                    //         ////console.log("Towei contract"," BuyOwnerDetails.tokenOwner", BuyOwnerDetails.tokenOwner,"item.tokenCounts",item.tokenCounts,"sendAmount",sendAmount,"NoOfToken",NoOfToken)
                    //         contractCall = await CoursetroContract.methods
                    //             .saleWithToken(
                    //                 BuyOwnerDetails.tokenOwner,
                    //                 item.tokenCounts,
                    //                 sendAmount.toString(),
                    //                 NoOfToken,
                    //                 CoinName,
                    //                 BuyOwnerDetails.type,
                    //                 BuyOwnerDetails.contractAddress
                    //             )
                    //             .send({
                    //                 from: Wallet_Details.UserAccountAddr
                    //             })
                    //             .on('transactionHash', async (transactionHash) => {
                    //                 handle = setInterval(async () => {
                    //                     receipt = await getReceipt(web3, transactionHash)
                    //                     clr1();
                    //                 }, 8000)
                    //             })

                    //     }
                    //     else {
                    //         //console.log("fsdfdsfsdfsdfdsfsdfsdfsdf", BuyOwnerDetails.tokenOwner,
                    //             item.tokenCounts,
                    //             sendAmount.toString(),
                    //             NoOfToken,
                    //             BuyOwnerDetails.type,
                    //             BuyOwnerDetails.contractAddress)
                    //         contractCall = await CoursetroContract.methods
                    //             .saleToken(
                    //                 BuyOwnerDetails.tokenOwner,
                    //                 item.tokenCounts,
                    //                 sendAmount.toString(),
                    //                 NoOfToken,
                    //                 BuyOwnerDetails.type,
                    //                 BuyOwnerDetails.contractAddress
                    //             )
                    //             .send({
                    //                 from: Wallet_Details.UserAccountAddr,
                    //                 value: web3.utils.fromWei(web3.utils.toWei(((MultipleWei))))
                    //             })
                    //             .on('transactionHash', async (transactionHash) => {
                    //                 handle = setInterval(async () => {
                    //                     receipt = await getReceipt(web3, transactionHash)
                    //                     clr1();
                    //                 }, 8000)
                    //             })
                    //     }
                    // }
                }
                catch (error) {
                    console.log('Order not placed error : ', error);
                    setPurchaseCallStatus('tryagain');
                    toast.error('Order not placed', toasterOption);
                }

                async function clr1() {
                    if (receipt !== null) {
                        clearInterval(handle);
                        if (receipt.status === true) {
                            var postData = {
                                tokenOwner: BuyOwnerDetails.tokenOwner, // old owner
                                UserAccountAddr: Wallet_Details.UserAccountAddr, // new owner
                                tokenCounts: item.tokenCounts,
                                tokenType: item.type,
                                NoOfToken: item.type === 721 ? 1 : NoOfToken,
                                transactionHash: receipt.transactionHash,
                                tokenBid: true,
                                CoinName: CoinName,
                                USDvalue: NewTokenUSValue
                            }
                            if (((String(item.contractAddress).toLowerCase()) !== Wallet_Details.networkConfiguration.singleContract) ||
                                ((String(item.contractAddress).toLowerCase()) !== Wallet_Details.networkConfiguration.multipleContract)
                                // ((String(item.contractAddress).toLowerCase()) !== config.old_singleContract) ||
                                // ((String(item.contractAddress).toLowerCase()) !== config.old_multipleContract)
                                ) {
                                var postMetaData = {
                                    tokenOwner: BuyOwnerDetails.tokenOwner, // old owner
                                    tokenCounts: item.tokenCounts,
                                    NoOfToken: item.type === 721 ? 1 : NoOfToken,
                                    contractAddress: item.contractAddress,

                                }
                                var result = await PurchaseNow_Complete_Action_Meta(postMetaData);
                            }
                            var postPrice = {
                                tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                                UserAccountAddr: Wallet_Details.UserAccountAddr, // new owner
                                tokenPrice: NewTokenUSValue
                            }
                            var Resp = await PurchaseNow_Complete_Action(postData);
                            var Responce = await PurchaseNow_Price_Action(postPrice);
                            ////console.log("vffnbkjnfvbjmn",postPrice);
                            if (Resp.data && Resp.data.toast && Resp.data.toast.type == 'success') {
                                toast.success("Collectible purchase successfully", toasterOption)
                                setPurchaseCallStatus('done');

                                window.$('.modal').modal('hide');

                                //     if(location_name=='/info/'+props.item.tokenCounts){
                                //         setTimeout(() => { 
                                //     var payload={
                                //     curAddr:UserAccountAddr,
                                //     tokenCounts:item.tokenCounts}
                                //     props.againCall(payload)
                                // }, 8000);
                                // }
                                // else{
                                history.push("/my-items")
                                // props.GetUpdatas();
                                // }
                            }

                        }
                    }
                }



            }

        }
        else {
            window.$('#connect_modal').modal('show');
        }

    }


    console.log('kfsjkfjskjg',item)



    return (
        <div>
            <div className="modal fade primary_modal PurchaseNow_modal" id="PurchaseNow_modal" tabIndex="-1" role="dialog" data-bs-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="buy_modalLabel">Checkout</h5>

                            <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close" id="close9"


                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body px-0">
                            <div className="row mx-0">
                                <div className="col-12 col-sm-3 px-4">
                                    <p className="buy_title_sm">Seller</p>
                                </div>
                                <div className="col-12 col-md-6 px-4">
                                    <p className="buy_title_md text-md-right word_brak_text">
                                        <span title={"Seller : " + (item && item.userprofile && item.userprofile.name ? item.userprofile && item.userprofile.name : String(BuyOwnerDetails && (BuyOwnerDetails.tokenOwner !== "" ? BuyOwnerDetails.tokenOwner : item.tokenOwner)))} >{item.userprofile && item.userprofile.name ? item.userprofile && item.userprofile.name : String(BuyOwnerDetails && (BuyOwnerDetails.tokenOwner !== "" ? BuyOwnerDetails.tokenOwner : item.tokenOwner)).slice(0, 12).concat('....')}</span></p>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-md-3 px-4">
                                    <p className="buy_title_sm" >Buyer</p>
                                </div>
                                <div className="col-12 col-md-6 px-4">
                                    <p className="buy_title_md text-md-right word_brak_text">
                                        <span title={"Buyer : " + (!isEmpty(MyItemAccountAddr_Details) ? (MyItemAccountAddr_Details.name !== "" ? MyItemAccountAddr_Details.name : Wallet_Details.UserAccountAddr) : Wallet_Details.UserAccountAddr)} > {!isEmpty(MyItemAccountAddr_Details) && MyItemAccountAddr_Details.name !== "" ? MyItemAccountAddr_Details.name : String(Wallet_Details.UserAccountAddr).slice(0, 12).concat('....')}</span></p>
                                </div>
                            </div>
                            <form className="bid_form" action="#">
                                {item.type === 721 ? ('') :
                                    <div className="mb-3 px-4 ">

                                        <label htmlFor="qty">Quantity
                                            <span>({BuyOwnerDetails.putonsalequantity > 1 ? BuyOwnerDetails.putonsalequantity : item && BuyOwnerDetails && BuyOwnerDetails.balance && BuyOwnerDetails.balance} is available)</span>
                                        </label>

                                        <div className="mb-3 input_grp_style_1">
                                            <input
                                                type="text"
                                                maxLength={config.maxLength}
                                                className="form-control primary_inp text-center"
                                                name="NoOfToken"
                                                id="NoOfToken"
                                                onChange={inputChange}
                                                placeholder="e.g. 2"
                                                autoComplete="off"
                                                value={NoOfToken}
                                            />
                                            {ValidateError.NoOfToken && <span className="text-danger">{ValidateError.NoOfToken}</span>}
                                            {!ValidateError.NoOfToken && ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                                        </div>
                                    </div>}
                            </form>


                            <div className="row mx-0 pb-3">
                                <div className="col-12 col-sm-6 px-4">
                                    <p className="buy_desc_sm">Your balance</p>
                                </div>
                                <div className="col-12 col-sm-6 px-4 text-sm-right">
                                    <p className="buy_desc_sm_bold">

                                        <Convert
                                            item={Number(Wallet_Details.UserAccountBal/1e18)}
                                            coinName={BuyOwnerDetails.CoinName}
                                            convertVal={1} />
                                        {Wallet_Details.networkConfiguration.currencySymbol}
                                    </p>
                                </div>
                            </div>
                            {String(BuyOwnerDetails.CoinName).toLowerCase() === String(Wallet_Details.networkConfiguration.tokenSymbol).toLowerCase() &&
                                <div className="row mx-0 pb-3">
                                    <div className="col-12 col-sm-6 px-4">
                                        <p className="buy_desc_sm">Your Token balance</p>
                                    </div>
                                    <div className="col-12 col-sm-6 px-4 text-sm-right">
                                        <p className="buy_desc_sm_bold">

                                        {PurchaseBalance} {BuyOwnerDetails.CoinName}</p>
                                    </div>
                                </div>
                            }
                            <div className="row mx-0 pb-3">
                                <div className="col-12 col-sm-6 px-4">
                                    <p className="buy_desc_sm">Price</p>
                                </div>
                                <div className="col-12 col-sm-6 px-4 text-sm-right">
                                    <p className="buy_desc_sm_bold">
                                        <Convert
                                            item={Number(TokenPrice)}
                                            coinName={BuyOwnerDetails.CoinName}
                                            convertVal={1} />
                                        {BuyOwnerDetails.CoinName}
                                    </p>
                                </div>
                            </div>
                            <div className="row mx-0 pb-3">
                                <div className="col-12 col-sm-6 px-4">
                                    <p className="buy_desc_sm">Service fee</p>
                                </div>
                                <div className="col-12 col-sm-6 px-4 text-sm-right">
                                    <p className="buy_desc_sm_bold">
                                        {String(BuyOwnerDetails.CoinName).toLowerCase() === String(Wallet_Details.networkConfiguration.tokenSymbol).toLowerCase() ?

                                            (Number(Wallet_Details.Service_Fee_buyer) + Number(0)) / config.decimalvalues
                                            :
                                            Wallet_Details.Service_Fee_buyer / config.decimalvalues
                                        }
                                        % <span>{BuyOwnerDetails.CoinName}</span></p>
                                </div>
                            </div>
                            <div className="row mx-0 pb-3">
                                <div className="col-12 col-sm-6 px-4">
                                    <p className="buy_desc_sm">Royalty fee</p>
                                </div>
                                <div className="col-12 col-sm-6 px-4 text-sm-right">
                                    <p className="buy_desc_sm_bold">
                                        {((item.tokenRoyality))}
                                        % <span>{BuyOwnerDetails.CoinName}</span></p>
                                </div>
                            </div>
                            <div className="row mx-0 pb-3">
                                <div className="col-12 col-sm-6 px-4">
                                    <p className="buy_desc_sm">You will pay</p>
                                </div>
                                <div className="col-12 col-sm-6 px-4 text-sm-right">
                                    <p className="buy_desc_sm_bold">

                                        {/* {
                                            String(BuyOwnerDetails.CoinName).toLowerCase() === String(Wallet_Details.networkConfiguration.tokenSymbol).toLowerCase() ?
                                                <Convert
                                                    item={Number(YouWillPay)}
                                                    coinName={BuyOwnerDetails.CoinName}
                                                    convertVal={1} />
                                                :
                                                <Convert
                                                    item={Number(YouWillPay)}
                                                    coinName={BuyOwnerDetails.CoinName}
                                                    convertVal={1} />
                                        } */} {YouWillPay}

                                        <span>{BuyOwnerDetails.CoinName}</span></p>
                                </div>
                            </div>
                            <form className="px-4">
                                <div className="text-center">
                                    <Button
                                        type="button"
                                        className="create_btn btn-block"
                                        onClick={(FormSubmitLoadings === 'start' || isEmpty(ValidateError)) && (() => FormSubmit())}
                                        disabled={(FormSubmitLoadings === 'processing') || (FormSubmitLoadings === 'error') || (FormSubmitLoadings === 'errors') || (FormSubmitLoadings === 'errors1') || (!isEmpty(ValidateError))}
                                    >
                                        {FormSubmitLoadings === 'processing' && <> <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i > <>In-Progress</></>}
                                        {FormSubmitLoadings === 'error' && 'Check Wenlambo Balance'}
                                        {FormSubmitLoadings === 'start' && 'Proceed to payment'}
                                        {FormSubmitLoadings === 'errors' && 'Check Balance'}
                                        {FormSubmitLoadings === 'errors1' && 'Error in Field'}

                                    </Button>

                                    <Button className="btn cancel btn-block"  data-bs-dismiss="modal" aria-label="Close">Cancel</Button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade primary_modal PurchaseStep_modal" id="PurchaseStep_modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="PurchaseStepCenteredLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="PurchaseStepLabel">Follow Steps</h5>

                            <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close" disabled={(ApproveCallStatus === 'process' || PurchaseCallStatus === 'done')}  >

                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                {String(CoinName).toLowerCase() !== String(Wallet_Details.networkConfiguration.currencySymbol).toLowerCase() &&
                                    <div className="text-center">
                                        <p className="mt-3 purchase_desc text-center">Approve the transaction</p>
                                        <Button
                                            type="button"
                                            onClick={() => FormSubmit_StepTwo()}
                                            className={"create_btn btn-block"}
                                            disabled={(ApproveCallStatus === 'process' || ApproveCallStatus === 'done')}
                                        >{ApproveCallStatus === 'process' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                            {ApproveCallStatus === 'init' && 'Approve'}
                                            {ApproveCallStatus === 'process' && 'In-progress...'}
                                            {ApproveCallStatus === 'done' && 'Done'}
                                            {ApproveCallStatus === 'try' && 'Try Again'}
                                        </Button>
                                    </div>
                                }
                                <div className="text-center my-3">
                                    <p className="mt-3 purchase_desc text-center">Send transaction with your wallet</p>
                                    <Button
                                        type="button"
                                        onClick={() => FormSubmit_StepOne()}
                                        disabled={(PurchaseCallStatus === 'processing' || PurchaseCallStatus === 'start' || PurchaseCallStatus === 'done')}
                                        className={"create_btn btn-block"}

                                    >
                                        {PurchaseCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {PurchaseCallStatus === 'init' && 'Purchase'}
                                        {PurchaseCallStatus === 'start' && 'Purchase'}

                                        {PurchaseCallStatus === 'processing' && 'In-progress...'}
                                        {PurchaseCallStatus === 'done' && 'Done'}
                                        {PurchaseCallStatus === 'tryagain' && 'Try Again'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

