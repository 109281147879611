import React, { useState, useEffect, useRef } from "react";
import DETH_ABI from '../ABI/DETH_ABI.json';
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import { getUserCollection } from '../actions/v1/user'
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useHistory, useLocation } from "react-router-dom";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import randomInteger from 'random-int';
import AddIcon from '@mui/icons-material/Add';
import  delete_png from '../assets/images/del.png'

// import masonary1 from 'assets/images/masonary_04.png'
// import Loader from 'assets/images/loader.png';
import $ from 'jquery';
import '@metamask/legacy-web3'
import Select from 'react-select'
import CONFIG from '../lib/config'
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Convert from 'views/separate/Convert';
import isEmpty from "lib/isEmpty";
import Web3 from 'web3';
import Web3Utils from 'web3-utils'
import MULTIPLE from '../ABI/MULTIPLE.json';
import SINGLE from '../ABI/SINGLE.json';
import { getReceipt } from '../actions/v1/getReceiptFunc';
import {
  IPFSUploadFunc,
  ipfsImageHashGet,
} from '../actions/v1/token';
import { connectWallet, WalletConnect } from '../views/hooks/useWallet';
import {
  GetCategoryAction,
  CreateTokenValidationAction,
  TokenAddItemAction,
  TokenAddItemAction1,
  TokenAddOwnerAction,
  TokenAddOwnerAction1,
  WenlamboConvert,
  validateNftQuantity,
  createNfts,
  ipfsImageHashGetBulk
} from '../actions/v1/token';
import { Account_Connect, Account_disConnect } from "actions/redux/action";

import { AddressUserDetails_GetOrSave_Action } from "../actions/v1/user";
import { network } from "./network"
import Market from "../ABI/market"
import { ViewBulkFiles } from "./separate/ViewBulkFiles";

import { AddCollectionRef } from "./separate/create_collection";


var initialformvalue = {
  currencySymbol: '',
  tokenSymbol: '',
  tokenAddr: {},
  tokenABI: {},
  singleContract: "",
  multipleContract: "",
  BNBPROVIDER: "",
  networkVersion: '',
  chainId: '',
  Chainid: 0,
  trade: "",
  Fronturl:''
}

const mapdata = [
  { value: 'Discord', label: 'Discord', classname:"fab fa-discord" },
  { value: 'Instagram', label: 'Instagram', classname:"fab fa-instagram" },
  { value: 'Linkedin', label: 'Linkedin', classname:'fab fa-linkedin' },
  { value: 'Whatsapp', label: 'Whatsapp', classname:"fab fa-whatsapp" },
  { value: 'Twitter', label: 'Twitter', classname:"fab fa-twitter" },
  { value: 'Slack', label: 'Slack', classname:"fab fa-slack" },
  { value: 'Telegram', label: 'Telegram', classname:"fab fa-telegram" },
  { value: 'Github', label: 'Github', classname:"fab fa-github" },
  { value: 'Facebook', label: 'Facebook', classname:"fab fa-facebook-f" },
  { value: 'Others', label: 'Others', classname:"fas fa-hashtag" },
  // Add more data items here as needed
];


toast.configure();
const dashboardRoutes = [];
let toasterOption = CONFIG.toasterOption;
const useStyles = makeStyles(styles);
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function CreateSingle(props) {
  const dispatch = useDispatch();


  const Wallet_Details = useSelector(state => state.wallet_connect_context);

  //console.log("drhesrth", Wallet_Details.tokenAddress);
  // const networkoption = [
  //   { value: config.currencySymbol, label: config.currencySymbol },
  //   { value: config.tokenSymbol, label: config.tokenSymbol },
  // ];

  const classes = useStyles()
  const { ...rest } = props;
  const history = useHistory();

  const [category,setCategory] = React.useState(false);

  var pathVal = '';
  var new_pathVal = '';
  const location = useLocation();
  if (location.pathname) {
    if (location.pathname.split('/').length >= 2) {
      pathVal = location.pathname.split('/')[1];
      new_pathVal = location.pathname.split('/')[2]
    }
  }

  const [location_pathname, Set_location_pathname] = useState(pathVal);
  const [newlocation_pathname, Set_new_location_pathname] = useState(new_pathVal);

  var CollectibleType_val =  CONFIG.singleType;

  const [CollectibleType, Set_CollectibleType] = useState(CONFIG.singleType);
  const [TokenFilePreUrl, setTokenFilePreUrl] = useState("");
  const [imgfilename, setimgfilename] = useState('');
  const [fileSizes, setfilesize] = useState(0)
  const [TokenFile, setTokenFile] = useState("");
  const [TokenFilePreReader, setTokenFilePreReader] = useState("");
  const [ValidateError, setValidateError] = useState({ TokenPrice: '' });
  const [PutOnSale, setPutOnSale] = useState(false);
  const [PutOnSaleType, setPutOnSaleType] = useState('UnLimitedAuction');
  const [PutOnSaleQuantity, setPutOnSaleQuantity] = useState('1');
  const [TokenBid, setTokenBid] = useState(true);
  const [Unlockoncepurchased, Set_Unlockoncepurchased] = useState(false);
  const [MinimumBid, Set_MinimumBid] = useState(0);
  const [Clocktime, set_Clocktime] = useState('');
  const [MintHashVal, Set_MintHashVal] = useState('');
  const [EndClocktime, set_EndClocktime] = useState("");
  const [TokenPrice, setTokenPrice] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [TokenName, setTokenName] = useState('');
  const [TokenDescription, setTokenDescription] = useState('');
  const [TokenRoyalities, setTokenRoyalities] = useState('');
  const [TokenProperties, setTokenProperties] = useState('');
  const [UnLockcontent, Set_UnLockcontent] = useState("");
  const [TokenQuantity, Set_TokenQuantity] = useState(2);
  const [CoinName, setCoinNames] = useState("");
  const [ApproveCallStatus, setApproveCallStatus] = useState('init');
  const [ApproveTrade, setApproveTrade] = useState('init');

  const [StartDate, Set_StartDate] = useState('Select Start Date');
  const [EndDate, Set_EndDate] = useState('Select End Date');
  const [priceoption, setPriceoption] = React.useState([]);
  const [FormSubmitUserClicked, Set_FormSubmitUserClicked] = useState(false);
  const [UserAccountBal, Set_UserAccountBal] = useState(0);
  const [TokenCategory, setTokenCategory] = useState({ label: '' });
  const [UserCollection, setUserCollection] = useState('');
  const [CollectionList, setCollectionList] = useState([]);
  const [CategoryOption, setCategoryOption] = useState([]);
  const [TokenImages, setTokenImage] = useState({});
  const [ipfshash, setIpfsHash] = useState("");
  const [ipfshashurl, setipfshashurl] = useState('');
  const [additionalImage, setAdditionalImage] = useState('')
  const [ipfsmetatag, set_ipfsmetatag] = useState('');
  const [MintCallStatus, setMintCallStatus] = useState('init');
  const [ContractAddressUser, set_ContractAddressUser] = useState(ContractAddressUser_val);
  const [networkoption, setnetworkoption] = React.useState([]);
  const [NetworkID, setNetworkID] = React.useState('');
  const [config, setconfig] = React.useState(initialformvalue);
  const [iscurrency, setiscurrency] = React.useState();
const[accountDetailssss,setaccountDetailssss]=useState({})
const [decimal, setdecimal] = React.useState();
const[check,setcheck]= React.useState(false);
// const [originalImage, setoriginalImagee] = useState('')
const [approvecheck, setapprovecheck] = useState(false)
const [fullcategory, setFullcategory] = useState({});
const [CategorywithSubcategory,setCategorywithSubcategory] = useState({})
const [NFTProbability, setNFTProbability] = useState(null);
const [ListNFT, SetListNFT] = useState(false);

const[files,set_files]=useState([])
const[mp3File,set_mp3File]=useState([])
const[audioname,setaudioname]=useState([])

const [Twitter, setTwitter] = useState('')
const [youtube, setyoutube] = useState('')
const [facebook, setfacebook] = useState('')
const [instagram, setinstagram] = useState('')
const [ipfsArray, setipfsArray] = useState([])
const [nftcreated, setnftcreated] = useState("")
const [ipfsdone, setipfsdone] = useState("")
const [mintdone, setmintdone] = useState("")
const [placeordedone, setplaceordedone] = useState("")

const [agree, SetAgree] = useState({original:false,'termsandcondition':false});

const [LayerType,SetLayerType] = useState({label:"Image Only",value:"Image"});
const [ViewMode, SetViewMode] = useState(false);
const [MultipleImagePath,SetMultipleImagePath] = useState('')
const [PopupOpen,SetPopupOpen] = useState(false)
const [FileProbability,SetFileProbability] = useState('');
const [RequiredNFT,SetRequiredNFT] = useState('');

const [NFTIds,SetNFTIds] = useState([]);

const [Randomname, setRandomName] = useState("") 
const [_nonce, setNonceHash] = useState("")
const [signature, setSignatureHash] = useState("")

const [socialLinks, SetSocialLinks] = useState([]);
const [filterRows, setFilterRows] = useState([]);

const mappedData = mapdata.map(item => ({
  value: item.value,
  label: item.label,
  classname: item.classname,
}));

const CreateCollectionForwardRef = useRef();


//console.log("dfgadsgd",config);
var initvalue;

if(newlocation_pathname == 'audio'){
  initvalue = " wav , FLAC , mp3 , WAV , flac , MP3"
}
else if(newlocation_pathname == 'video'){
  initvalue = " OGV , ogv , WEBM , webm , mp4";
}
else if(newlocation_pathname == 'image'){
  initvalue = " png , PNG, jpeg , JPEG , jpg , JPG"
}

const [Validfile, SetValidFile] = useState(initvalue);


  var ContractAddressUser_val = (location_pathname === 'create-single') ? Wallet_Details.networkConfiguration.singleContract : Wallet_Details.networkConfiguration.multipleContract;


  useEffect(() => {
     getCollection()
    //console.log("jhvhgchgf", Wallet_Details);
  }, [Wallet_Details.UserAccountAddr]);

console.log('dgjkdjkgjdkgd',location_pathname)
  async function getCollection() {


    var Singlee = Wallet_Details.networkConfiguration.singleContract
    var Multiplee =  Wallet_Details.networkConfiguration.multipleContract
    var payload = {
      userAddress: Wallet_Details.UserAccountAddr
    }
    if (location_pathname === 'create-multiple') {
      payload.NFTType = 'MULTIPLE'
      payload.Type = Multiplee
    }
    else {
      payload.NFTType = 'SINGLE'
      payload.Type = Singlee
    }
    var userCollectiondata = await getUserCollection(payload)
    // //console.log("gmfjdgjuhfbgv",userCollectiondata.data);
    var Collectionlist = [];
    if (userCollectiondata && userCollectiondata.message &&userCollectiondata.message.data&& isEmpty(userCollectiondata.message.data.collections)===false) {
      var Collectionlist = [];
      // //console.log("setUserCollection",userCollectiondata.data);
      userCollectiondata.message.data.collections.map((item, index) => {
        Collectionlist.push({
          ...item,
          ...{
            name: 'userCollection',
            value: item._id,
            label: item.collectionName,
            // custon:item.customurl
          }
        })
      })
    }
    Collectionlist.push({	
      name: 'userCollection',	
      value: 'create',	
      label: <div className="drop_static_create"><Link onClick={()=> CreateCollectionForwardRef.current.AddCollection_Click()}>Create Collections</Link></div>,	
        
    })
    setCollectionList(Collectionlist)

  }

  useEffect(() => {

//console.log("dfgaerghar",Wallet_Details,Wallet_Details.tokenAddress);
    if (Wallet_Details&&Wallet_Details.tokenAddress.length >0) {
      setPriceoption(Wallet_Details.tokenAddress)
    }
  },[Wallet_Details.tokenAddress])

  useEffect(() => {
//console.log("gfnhdgfhnjdfngj",Wallet_Details);
    if (Wallet_Details&&Wallet_Details.networkoption.length >0 ) {
      //console.log("bdgfndgfhngfdhn",Wallet_Details.networkoption);
      setnetworkoption(Wallet_Details.networkoption)
    }

    GetCategoryCall();
    CreateItemValidation(FormSubmitUserClicked);
  }, [
    Wallet_Details.UserAccountAddr,TokenFilePreReader,CoinName]);





  async function GetCategoryCall() {
    var resp = await GetCategoryAction()
    //console.log("fgjndghkgh",resp);
    if (resp && resp.data && isEmpty(resp.data.list) === false) {
      var CategoryOption = [];
      var ind = null
      if(resp&&resp.data&&isEmpty(resp.data.list) === false)
      {
      resp.data.list.map((item, index) => {
        ind = ((isEmpty(TokenImages) || TokenImages.activate === false) && String(item.name).toLowerCase() === "film") ? index : -1
        CategoryOption.push({
          name: 'TokenCategory',
          value: item._id,
          label: item.name
        })
      })
    }
      if (ind > -1) {
        delete CategoryOption[ind]
      }
      //console.log("Category Options", CategoryOption)
      setCategoryOption(CategoryOption)
      setFullcategory(resp.data.list)
    }
  }



  const PriceCalculate = async (data = {}) => {
    var price = (typeof data.price != 'undefined') ? data.price : TokenPrice;
    var weii = price * CONFIG.decimalvalues;
    var per = (weii * Wallet_Details.Service_Fee_seller) / 1e20;
    var mulWei = parseFloat(weii - per);
    var getVal = (mulWei / CONFIG.decimalvalues);
    // if(CoinName=="BNB"){
    Set_YouWillGet(getVal.toFixed(4));
    // }
    //   if(CoinName=="WENLAMBO"){
    //     Set_YouWillGet(getVal.toFixed(2));}
    ////////console.log("qowpoqpwpoqpwop",getVal)
  }

  const priceoptionfunc = (e) => {
    //console.log("nfrymntfryju",e.label);
    setCoinNames(e.label)
    setdecimal( e.Decimal)
    // setiscurrency(e.currency)
  };



async function switchNetwork(configdata)
{
  var type = ""
var networkConfiguration ={}
//console.log("dfbgfdbgdfbgfb",configdata,localStorage?.walletConnectType);
if(configdata)
{
    if (localStorage?.walletConnectType && localStorage?.walletConnectType != null && localStorage?.walletConnectType == 'MetaMask') {

      type = "MetaMask"
    }
    else if (localStorage?.walletConnectType && localStorage?.walletConnectType == 'WalletConnect' && localStorage?.walletConnectType != null) {
      type = "WalletConnect"
    }
    // //console.log("dthstrhrtjhsrt",type,config.Chainid);

    //     var accountDetails = await connectWallet(type,config.Chainid)

    // //console.log("accountDetailscreateeeeeeeeeee",accountDetails);

    window.$('#connect_modal').modal('hide');
    //console.log("connecttype............", type, configdata.Chainid)
    var accountDetails = await connectWallet(type, configdata.Chainid)
    //console.log("accountDetailsin create page......................", accountDetails)
    //  const id=toast.loading("Wallet Connecting...")
 var web3 = new Web3(window.ethereum);
  //console.log("dfghrtfh",web3);
  if(window.ethereum.isMetaMask == true){
    const chainId = await web3.eth.getChainId();
    //console.log("fghdtgj",chainId);

    if(chainId===network.ETH.Chainid)
    {
          networkConfiguration=network.ETH
    }
    else if(chainId===network.BSC.Chainid){
      networkConfiguration=network.BSC
    }


  }
  //setPriceoption()

    if (accountDetails != '' &&accountDetails?.web3?._provider!='') {
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          UserAccountAddr: accountDetails.accountAddress,
          UserAccountBal: accountDetails.coinBalance,
          WalletConnected: "true",
          Wen_Bln: accountDetails.tokenBalance,
          Accounts: accountDetails.accountAddress,
          providerss: accountDetails.web3._provider,
          networkConfiguration:networkConfiguration
        }
      })
    }
    else{
      toast.warning("please connect your wallet")
    }
  }



  //   var ReqData = {
  //     addr: String(accountDetails.accountAddress).toLowerCase()
  // }
  // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
  // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
  //     return Resp.data.data.User
  // } else {
  //     return null
  // }





return accountDetails
}


// async function orderApprovecheck(item) {
//   if (Wallet_Details.providerss == null) {
//     toast.warning("OOPS!..connect Your Wallet", toasterOption);
//     return false;
//   }
//   var web3 = new Web3(Wallet_Details.providerss);
//   try {
//     var MultiContract = new web3.eth.Contract(
//       (item.type === 721 ? SINGLE : MULTIPLE),
//       item.contractAddress
//     )
//     var status = await MultiContract.methods.isApprovedForAll(
//       Wallet_Details.UserAccountAddr,
//       Wallet_Details.networkConfiguration.TradeContract
//     ).call();
//     ////console.log("OrderApprove Check", status);
//     return status
//   }
//   catch (e) {
//     //console.log("OrderApprove Check", e);

//     return false

//   }

// }

async function orderApprovecheck(item, tokenOwnerInfo) {
  if (Wallet_Details.providerss == null) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return false;
  }
  var web3 = new Web3(Wallet_Details.providerss);
  try {

      //   //console.log("OrderApprove Check", item,tokenOwnerInfo);
      var MultiContract = new web3.eth.Contract(
          (item.type === 721 ? SINGLE : MULTIPLE),
          item.contractAddress
      );
      var status = await MultiContract.methods.isApprovedForAll(
          tokenOwnerInfo.tokenOwner,
          Wallet_Details.networkConfiguration.TradeContract
      ).call();
      return status;
  }
  catch (e) {
      // //console.log("OrderApprove Check", e);
      return false

  }
}

var SettokenCategory =(cat,subcat)=>{
  //console.log('ctfastfdtsd',cat,subcat)
  setCategorywithSubcategory({category:cat.name,subcategory:subcat,id:cat._id,display:cat.name+'-'+subcat})
  setCategory(!category)
}
/////////////////////////////bulk mint ////////////////////////////////////////

  const selectFileChange = async (e) => {

    var validext = ["png", "PNG", "jpeg", "JPEG", "jpg", "JPG"]
    var audioext = ["wav", "FLAC", "mp3", "WAV", "flac", "MP3"]
    //console.log("dfbsfgbsfgbs",e.target.id);//fileInputControl
    var validfile;
    if(newlocation_pathname == 'audio'){
      audioext = ["wav", "FLAC", "mp3", "WAV", "flac", "MP3"]
    }
    else if(newlocation_pathname == 'video'){
      audioext = ["OGV", "ogv", "WEBM", "webm", "mp4"]
    }
    else if(newlocation_pathname == 'image'){
      audioext = ["png", "PNG", "jpeg", "JPEG", "jpg", "JPG"]
    }
    if( newlocation_pathname !== 'layer' ){
      validfile = ["png", "PNG", "jpeg", "JPEG", "jpg", "JPG"]
    }
    console.log('dkksldksfs',e.target,)
    if (e.target.id === 'fileInputControl') {
      try {
        if (Wallet_Details.UserAccountAddr == '') {
          toast.warning(" connect your wallet", toasterOption)
        }
        else {
          var filessss = []
          var compfilepath = []
          var folderlength = []
          var files = e.target.files
          //console.log("fnsfgndghvmn",files);
          //console.log("sfjnfdjndgfj",files.length > 0);

          if (files) {
            if (files.length > 0) {
              for (var i = 0; i < files.length; i++) {
                filessss.push(files[i])
              }
            }
            if(newlocation_pathname == 'layer'){
              var validatext = await Promise.all(filessss.map((file) => {

                var ext = file.name.split(".").pop()
                var extval = validext.includes(ext)
                return extval
              }))
              var checkfileext = validatext.includes(false)
              if (!checkfileext) {
                var data =
                {
                  files,
                  curradd: Wallet_Details.UserAccountAddr,
                }
                var check = await validateNftQuantity(data)
                //console.log("dfbssdfgnfsgn",check.data.data);
                setNFTProbability(check.data.data)
  
                set_files(files)
                toast.success("file uploaded successfully")
              }
              else {
                toast.warn(`only ${validext} extensions are allowed`)
              }
            }
            else{
              var validatext = await Promise.all(filessss.map((file) => {

                var ext = file.name.split(".").pop()
                var extval = validfile.includes(ext)
                return extval
              }))
              var checkfileext = validatext.includes(false)
              if (!checkfileext) {
                set_files(files);
                toast.success((newlocation_pathname == 'layer'? "File" : "Thumb file")+" uploaded successfully")
              }
              else{
                toast.warn(`only ${validfile} extensions are allowed`)
              }
            }
            //console.log("fghnjdghmnfhgn",filessss);
            
          }
        }
      }

      catch (err) {
        //console.log("vdfbvfdsbvfd",err);
      }
    }
    else if (e.target.id === "audio") {
      try {
        var mp3files = e.target.files
        var mp3filesss = []
        if (mp3files) {
          //console.log("fbhfngn",mp3files);

          if (mp3files.length > 0) {
            for (var i = 0; i < mp3files.length; i++) {
              mp3filesss.push(mp3files[i])
            }
          }
          //console.log("fghnjdghmnfhgn",mp3filesss);
          var validatextaudio = await Promise.all(mp3filesss.map((file) => {
            var ext = file.name.split(".").pop()
            //console.log("Gdnhjsfegnjhd",ext);
            var extval = audioext.includes(ext)
            return extval
          }))
          //console.log("dgmjfhgmfhjm",validatextaudio);
          var audiocheckfileext = validatextaudio.includes(false)
          //console.log("Dgfhjnfghjmfh",audiocheckfileext);
          if (!audiocheckfileext) {

            set_mp3File(mp3files)
            toast.success((newlocation_pathname == 'layer'? "audios" : "File")+" upload successfully")
          }
          else {
            toast.warn(`only ${audioext} extensions are allowed`)
          }
        }
      }
      catch (err) {
        //console.log("fdghmnghjmgh",err);
      }
      // setaudioname(audioname)
    }

  }



const selectChange = async(e) => {


   
  console.log("sdgsdrh", e.value, e.name,networkoption);


  if (e.name == "networkname") {
    setCoinNames("")
    //console.log("sdgsdrhiff", e.value, e.name,networkoption);
    if (e.name == "networkname" && e.value == CONFIG.binance) {
      var configdata = network.BSC
      var accountDetails =await  switchNetwork(configdata)
      configdata = {...configdata,...{Fronturl:CONFIG.Front_URL,label:e.label,value:e.value}}
      setconfig(configdata)
    }
    else if (e.name == "networkname" && e.value == CONFIG.etherium) {
      var configdata = network.ETH
      var accountDetails =await  switchNetwork(configdata)
      console.log('checkkkkk',accountDetails)
      configdata = {...configdata,...{Fronturl:CONFIG.Front_URL,label:e.label,value:e.value}}
      setconfig(configdata)
    }
  }

  setaccountDetailssss(accountDetails)
  console.log("sdfvsdfsdf",e);
  if (e && e.name && e.label && e.value) {

    switch (e.name) {
      case 'TokenCategory':
        setTokenCategory(e);
        break;
      case "networkname":
        setNetworkID(configdata?.Chainid)
        break;
      case 'userCollection':
        {
          if(e.value == 'create'){
            setUserCollection("")
          }
          else{
            setUserCollection(e.label)
            if(e?.SubCategory && e?.CategoryId && e?.Category){
              setCategorywithSubcategory({category:e.Category,subcategory:e.SubCategory,id:e.CategoryId,display:e.Category+'-'+e.SubCategory,disabecategory:true, collection:e.label})
            }
            else if(CategorywithSubcategory?.collection == UserCollection){
              setCategorywithSubcategory({});
            }
          }
        }
        
      default:
    }

  }
  
  else{
    toast.warning("please connect your wallet",toasterOption)
   }

}




const inputChange = (e) => {
  //console.log("dfgndgfn",CoinName);
  setValidateError({})
  if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
    var value = e.target.value;
    const re = /^[0-9]+([.][0-9]+)?$/; //with decimal
    const re1 = /^[0-9\b]+$/; // only [0-9]

   

    switch (e.target.name) {
      case 'TokenPrice':
        if (value !== '' && isNaN(value) === false && value > 0) {
          setTokenPrice(value);
          PriceCalculate({ price: value });
        }
        else {
          setValidateError(ValidateError);
          setTokenPrice('0');
          PriceCalculate({ price: 0 });
        }
        break;
      case 'TokenName':
        setTokenName(value);
        break;
      case 'TokenDescription':
        setTokenDescription(value);
        break;
      case 'TokenRoyalities':
        setTokenRoyalities(value);
        break;
      case 'TokenProperties':
        setTokenProperties(value);
        break;
      case 'UnLockcontent':
        Set_UnLockcontent(value);
        break;
      case 'MinimumBid':
        Set_MinimumBid(value);
        break;
      case 'TokenQuantity':
        Set_TokenQuantity(value);
        break;
        case 'twitter':
          setTwitter(value);
          break;
        case 'youtube':
          setyoutube(value);
          break;
        case 'facebook':
          setfacebook(value);
          break;
        case 'instagram':
          setinstagram(value);
          break;


      default:
      // code block
    }
  }
}

async function CreateItem() {

  //  var accountDetails =await  switchNetwork()

//console.log("dfbgszdhbsf",CoinName);
if(Wallet_Details.UserAccountAddr!=="")
{
  var check = await orderApprovecheck();

      //console.log("Wallet_Details.providerss", Wallet_Details);
      Set_FormSubmitUserClicked(true);
      var errors = await CreateItemValidation(true);
      var errorsSize = Object.keys(errors).length;
      console.log("FBGdfbdfbdfb",errorsSize,errors);
      if (errorsSize !== 0) {
        toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
        return false
      }

      else if (Wallet_Details.providerss) {
        if (config.Chainid === Wallet_Details.networkConfiguration.Chainid) {

        if (Wallet_Details.providerss == null) {
          toast.error("Please Connect to BINANCE Network", toasterOption)
        }
        else {

          if (typeof Wallet_Details.UserAccountBal === 'undefined' || Wallet_Details.UserAccountBal === null || Wallet_Details.UserAccountBal === 0) {
            Set_UserAccountBal(0);
          }
          else {
            Set_UserAccountBal(Wallet_Details.UserAccountBal);
          }
          if (Wallet_Details.UserAccountBal === 0) {
            toast.error("Insufficient balance", toasterOption);
            return false;
          }
          var TokenCategory_label = TokenCategory.label;
          let payload = {
            TokenName,
            TokenRoyalities,
            image: files,
            TokenCategory_label,
            PutOnSaleType,
            TokenPrice,
          }
          //console.log("payload", payload);
          const resp = await CreateTokenValidationAction(payload);
          if (resp && resp.data) {
            //console.log("iffff in createissue");
            if (resp.data.errors) {
              var errors = resp.data.errors;
              var errorsSize = Object.keys(errors).length;
              if (errorsSize !== 0) {
                setValidateError(errors);
                toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
              }
              else {
                //console.log("else in createissue");
                setValidateError({});

                  if(Wallet_Details.networkConfiguration.Chainid===config.Chainid)
                  {
                    if(newlocation_pathname != 'layer'){
                      setnftcreated("true")
                    }
                    // if(PutOnSale){
                      var generator = require('generate-password');
                      console.log("qweqweqwewqeqweqwezxccvc", Wallet_Details,);
                      
                      var web3 = new Web3(Wallet_Details.providerss)
                      if (web3) {
                        // var web3Rpc = new Web3(config.RPC_URL)
                        // console.log("qweqwewqewqewqewqqwvbv", web3Rpc);
                        var randomNum = randomInteger(10000000, 100000000);
                        console.log("sdcfsdfsdfsdvsdfdsf", randomNum);
                        var password = generator.generate({
                          length: 10,
                          numbers: true
                        });
                        console.log("asdasdasdas", password);
                        if (web3) {
                          // console.log("checking window.ethereum", web3.givenProvider,Wallet_Details.providerss)
                          var web3RpcPro = new Web3(web3.providers);
                          console.log("asddfsfsdvbvbvcb", web3RpcPro, Wallet_Details.UserAccountAddr);
                          const to = Wallet_Details.UserAccountAddr
                          const _amount = (TokenPrice == "" || TokenPrice == undefined) ? 0 : web3RpcPro.utils.toWei(String(TokenPrice));
                          console.log("ajhghjas", _amount);
                          const _nonce = Date.now();
                          console.log("qwewqewqreqwrqrq", password);
                  
                          setRandomName(password)
                          var tot = _nonce + Number(randomNum);
                          setNonceHash(tot);
                          console.log("qwewqeqwewqeqweasdas", to, _amount, password, tot);
                          const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
                          console.log("asdsadasfdafaf", result);
                          const signhash = await web3.eth.personal.sign(result, to);
                          console.log("Signature", result, signhash)
                          if (signhash) {
                            setSignatureHash(signhash);
                          }
                        }
                      }
                    // }
                    
                    SetPopupOpen(true)
                  window.$('#create_item_modal2').modal('show');
                  }
                  else{
                    toast.warn("CoiName is not Valid")
                  }
              }
            }
          }
          }
          // })
        }
      }
      else {

if(config.Chainid ===0)
{
  toast.error("Please select network ");

}else{
  toast.error("Please switch network", toasterOption);
  setTimeout(() => {
    window.location="/"
  }, 1000);
}

       
        // Set_WalletConnected(false);
      }
    }
    else{
      toast.warning("please connect your wallet")

      // var register = localStorage.getItem('registedEmail');
      // //console.log("dgfbnsfdgbn",localStorage.registedEmail,localStorage.registedEmail ===undefined);
      // if(register==='')
      // {
      // toast.warning("please register and connect your wallet")
      // }
    }
    // }


    // var ReqData = {
    //   addr: String(accountDetails.accountAddress).toLowerCase()
    // }
    // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
    // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
    //   return Resp.data.data.User
    // } else {
    //   // return null
    // }
  }

  async function CreateItemValidation(chk) {

    var approvedata ={curradd:Wallet_Details.UserAccountAddr,
     tradeadd: Wallet_Details.networkConfiguration.TradeContract}
    var check = await orderApprovecheck(approvedata);
    setapprovecheck(check)
    // console.log("DFnhsfgnj",);
    if (chk) {
      var ValidateError = {};
      console.log("dghnsdfxgj",PutOnSale,PutOnSaleType,TokenPrice);
      //console.log("gfbnhsdfgnbdgfn",Number(TokenQuantity) !== Number(mp3File.length));
      
if(((isEmpty(files)===true) && ((newlocation_pathname != 'image' && newlocation_pathname != 'layer') || (newlocation_pathname == 'layer' && LayerType?.value == 'Imageaudio'))))
{
  ValidateError.layer = (newlocation_pathname == 'layer' ? '"audio"':'"Thumb Image"')+' is required';
}
if(isEmpty(mp3File)===true && ((newlocation_pathname != 'layer') || (newlocation_pathname == 'layer' && LayerType?.value != 'Image')))
  {
    ValidateError.photo = '"File" is required';
  }
      if ((Number(TokenQuantity) !== Number(mp3File.length)) && (newlocation_pathname === 'layer' && LayerType.value !== 'Image' )) {
        ValidateError.TokenQuantity = `length of audio file is ${mp3File.length} only ${mp3File.length}NFT is allowed `;
      }
      if( (TokenQuantity>NFTProbability) && (newlocation_pathname == 'layer') )
      {
        ValidateError.TokenQuantity = `only ${NFTProbability} NFTs can be created "upload more traits for more NFTs"`;
      }
      if( (newlocation_pathname !== 'layer') && (newlocation_pathname !== 'image')){
        if(files.length !== mp3File.length){
          ValidateError.layer = 'Thump Image and '+FirstCaps(newlocation_pathname)+' File Count Must be Same';
          ValidateError.photo = 'Thump Image and '+FirstCaps(newlocation_pathname)+' File Count Must be Same';
        }
        // ValidateError.network = '"network" is not allowed to be empty';
      }
      if(config.Chainid === 0)
      {
        ValidateError.network = '"network" is not allowed to be empty';

      }
      if (CoinName === "" &&PutOnSaleType !=="UnLimitedAuction") {
        ValidateError.TokenPrice = 'Currency or Token" must be required';
      }
      if (TokenName === '') {
        ValidateError.TokenName = '"Name" is not allowed to be empty';
      }
      if (TokenName !== "") {
        if ((CONFIG.nameFormat).test(TokenName)) {
          ValidateError.TokenName = '"Emoji" is not allowed';
        }
      }
      if (TokenRoyalities === '') {
        ValidateError.TokenRoyalities = '"Royalties" is not allowed to be empty';
      }
      else if (isNaN(TokenRoyalities) === true) {
        ValidateError.TokenRoyalities = '"Royalties" must be a number';
      }
      else if (TokenRoyalities > 20) {
        ValidateError.TokenRoyalities = '"Royalties" must be less than or equal to 20';
      }
      console.log('kjskfjsfs',UserCollection,isEmpty(UserCollection))
      if(isEmpty(UserCollection)){
        ValidateError.userCollection = 'Collection is not allowed to be Empty';
      }
      // if (TokenFilePreUrl === '') {
      //   ValidateError.photo = '"File" is required';
      // }
      // if (30000000 < fileSizes) {
      //   ValidateError.photo = '"File" Must be below 30mb';
      // }
      if (isEmpty(CategorywithSubcategory)) {
        ValidateError.TokenCategory = '"Category" is required';
      }

      if (Unlockoncepurchased === true && UnLockcontent === '') {
        ValidateError.UnLockcontent = '"Locked content" is required';
      }
      if (PutOnSale === true && PutOnSaleType === 'FixedPrice') {
        if (TokenPrice === '' || isNaN(TokenPrice) === true && Number(TokenPrice) === 0) {
          ValidateError.TokenPrice = '"Price" must be a number';
        }
        else if (Number(TokenPrice) === 0) {
          ValidateError.TokenPrice = '"Price" must be greater than zero';
        }
        else if (CoinName === "") {
          ValidateError.TokenPrice = 'Currency or Token" must be required';
        }
        if (YouWillGet === 0) {
          ValidateError.TokenPrice = 'only allowed 4 digits  after decimal point';
        }

      }
      if (PutOnSale === true && PutOnSaleType === 'TimedAuction') {
        if (MinimumBid === '') {
          ValidateError.MinimumBid = '"Bid Price" must be a number';
        }
        if (Clocktime === '') {
          ValidateError.Clocktime = '"Start Clock Time " cant be a number';
        }
        if (EndClocktime === '') {
          ValidateError.EndClocktime = '"End Clock Time " cant be a number';
        }
        if (Clocktime === 'Select Start Date') {
          ValidateError.Clocktime = '"Start Clock Time " cant be a number';
        }
        if (EndClocktime === 'Select End Date') {
          ValidateError.EndClocktime = '"End Clock Time " cant be a number';
        }
        if (Clocktime === 'Invalid Date') {
          ValidateError.Clocktime = '"Start Clock Time " cant be a number';
        }
        if (EndClocktime === 'Invalid Date') {
          ValidateError.EndClocktime = '"End Clock Time " cant be a number';
        }
      }
      if (TokenQuantity === '' || isNaN(TokenQuantity) === true && TokenQuantity === 0) {
        ValidateError.TokenQuantity = '"Number of copies" must be a number';
      }
      if(!agree?.original){
        ValidateError.original = 'This field is required'
      }
      if(!agree?.termsandcondition){
        ValidateError.termsandcondition = 'This field is required'
      }
     
        // if (youtube=== "") {
        //   ValidateError.youtube = "Youtube link is Required";
        // }
      
        // if (instagram === "") {
        //   ValidateError.instagram = "Instagram link is Required";
        // }
      
        // if (facebook === "") {
        //   ValidateError.facebook = "Facebook link is Required";
        // }
      
        // if (Twitter === "") {
        //   ValidateError.twitter = "Twitter link is Required";
        // }
      

        if(socialLinks?.length > 0){
          socialLinks.map((val,ind)=>{
            if(isEmpty(val.url)){
              ValidateError['url'+ind] = "Url cannot be Empty";
            }
            if(isEmpty(val.urlfor)){
              ValidateError['urlfor'+ind] = "Url cannot be Empty";
            }
          })
        }




      console.log("DNzfgnbfgn",ValidateError);
      setValidateError(ValidateError);
      return ValidateError;
    }
    else {
      return {};
    }
  }

  async function createnfts()
  {try{
    setnftcreated("processs")
    var data=
    {
      files,
      curradd:Wallet_Details.UserAccountAddr,
      TokenQuantity:TokenQuantity
    }
   var multipleImage = await createNfts(data)
   
   console.log("ghjghkjmghkm",multipleImage.data.data.path);
  if(multipleImage?.data?.data?.path!=''
  )
  {
    SetMultipleImagePath(multipleImage.data.data.path.split('/public/')[1]);
    setnftcreated("true")
  }
}catch(err)
{
  setnftcreated("")
}

  }

const IpfsAndMint =async()=>
{
try{
setipfsdone("processs")
console.log("fgnhdfgndfgngh",mp3File,config);
var senddata;
var ipfsdata;
if(newlocation_pathname == 'layer'){
  senddata = {
    name :TokenName,
    Image: mp3File,
    desc: TokenDescription != "" ? TokenDescription : "This NFT Token From " + config.Fronturl,
    Creator: Wallet_Details.UserAccountAddr.toLowerCase(),
    TokenQuantity: TokenQuantity,
  }
  ipfsdata=await ipfsImageHashGetBulk(senddata)
}
else{
  senddata = {
    name :TokenName,
    File: mp3File,
    desc: TokenDescription != "" ? TokenDescription : "This NFT Token From " + config.Fronturl,
    Creator: Wallet_Details.UserAccountAddr.toLowerCase()
  }
  if( newlocation_pathname !== 'image'){
    senddata.Thumbfile = files;
  }
  ipfsdata=await IPFSUploadFunc(senddata)
}

console.log("sdfbgasdfbgadfb",ipfsdata);

var ipfs = ipfsdata.data.ipfsfinal
//var nullcheck = ipfs.has(null)
var nullcheck =ipfs.map((item)=>
{
  console.log('lskflskflsf',item)
  if(item===null)
  {
    return true
  }
  else{
    return false
  }
})

var check = nullcheck.includes(true)
var aerrcheck=ipfs.includes(null)
console.log("fsgndgfndgfndghf",check,aerrcheck);
if(check)
{
  setipfsdone("try")
}
else
{
  toast.success('IPFS Uploaded',{autoClose:1000});
setipfsArray(ipfsdata.data.ipfsfinal)
setipfsdone("true")
}
}
catch(err)
{
  setipfsdone("")

  //console.log("dfbsfdbfb",err);
}     

  // var data =
  // {
  //   Image: originalImage,

  //   Name: TokenName,
  //   Description: TokenDescription,
  //   Price: TokenPrice,
  //   Royalities: TokenRoyalities,
  //   Category_label: CategorywithSubcategory.category,
  //   Owner: Wallet_Details.UserAccountAddr,
  //   Creator: Wallet_Details.UserAccountAddr,
  //   CategoryId: CategorywithSubcategory.id,
  //   Quantity: TokenQuantity,
  //   Balance: TokenQuantity,
  //   // ContractAddress:Wallet_Details.networkConfiguration.TradeContract,
  //   ContractAddress:(location_pathname === 'create-single') ? Wallet_Details.networkConfiguration.singleContract:Wallet_Details.networkConfiguration.multipleContract,
  //   Status: Status,
  //   Type: CollectibleType,
  //   CoinName: CoinName,
  //   collection: UserCollection,
  //   SelectedNetwork: NetworkID,
  //   decimal: decimal,
  //   Subcategory: CategorywithSubcategory.subcategory

  // }
}




















// async function ApproveCall() {
//   try {
//     if (Wallet_Details.UserAccountAddr === "") {
//       toast.warning("OOPS!..connect Your Wallet", toasterOption);
//       return false;
//     }
//     else {
//       setApproveCallStatus('processing');
//       var senddata = {
//         Image: TokenFile,
//         name: TokenName,
//         // image: config.IPFS_IMG + "/" + ipfsimghashget.data.ipfsval,
//         desc: (TokenDescription !== "" ? TokenDescription : 'This Token was Created in EDAFACE NFT platform'),
//         Creator: Wallet_Details.UserAccountAddr.toLowerCase()
//       }
//       //console.log("senddata", senddata);
//       try {
//         var ipfsimghashget = await ipfsImageHashGet(senddata);
//       } catch (err) {
//         //console.log("err in approve", err);
//       }
//       //console.log("ipfsimghashget", ipfsimghashget)
//       if (ipfsimghashget && ipfsimghashget.data && ipfsimghashget.data.medadata) {
//         if (ipfsimghashget.data.medadata.ipfsval !== "") {
//           //console.log("dsfgnhdfgjnfgjh",ipfsimghashget.data.medadata.image);
//           setoriginalImagee(ipfsimghashget.data.medadata.image)

//           //console.log("ipfsimghashget.data.medadata.ipfsval", ipfsimghashget.data.medadata.ipfsval);
//           setIpfsHash(ipfsimghashget.data.medadata.ipfsval)
//           //console.log("${config.IPFS_IMG}/${ipfsimghashget.data.medadata.ipfsval}", `${CONFIG.IPFS_IMG}/${ipfsimghashget.data.medadata.ipfsval}`);
//           setipfshashurl(`${CONFIG.IPFS_IMG}/${ipfsimghashget.data.medadata.ipfsval}`)
//           //console.log("ipfsimghashget.data.medadata.additionalImage", ipfsimghashget.data.medadata.additionalImage);
//           setAdditionalImage(ipfsimghashget.data.medadata.additionalImage)
//           if (ipfsimghashget.data.medadata.ipfsmetadata) {
//             var ipfsurls = ipfsimghashget.data.medadata.ipfsmetadata
//             //console.log("ipfsurls", ipfsurls);
//             set_ipfsmetatag(`${CONFIG.IPFS_IMG}/${ipfsurls}`)
//             toast.success("Approve Successfully", toasterOption);
//             setApproveCallStatus('done');
//           }
//           else {
//             setApproveCallStatus('tryagain');
//             toast.error("Try Again", CONFIG.toasterOption)
//           }
//         }
//         else {
//           setApproveCallStatus('tryagain');
//           toast.error("Try Again", CONFIG.toasterOption)
//         }

//       }
//       else {
//         setApproveCallStatus('tryagain');
//         toast.error("Try Again", CONFIG.toasterOption)
//       }
//     }
//   }
//   catch (error) {
//     setApproveCallStatus('tryagain');
//     toast.error("Try Again", CONFIG.toasterOption)
//   }
// }


// const MintCall = async () => {

//   //console.log("mintcalllllllllll");
//   var web3 = new Web3(Wallet_Details.providerss);
//   if (Wallet_Details.UserAccountAddr === "") {
//     toast.warning("OOPS!..connect Your Wallet", toasterOption);
//     return false;
//   }
//   var gas;
//   await web3.eth.getGasPrice()
//     .then(async (result) => {
//       gas = result;


//     });
//   //console.log("gas", gas);
//   var CoursetroContract = null;
//   var contractCall = null;
//   var TokenPriceInStr = (PutOnSaleType === "FixedPrice")? (window.web3.toWei(TokenPrice)).toString():'0'
// //console.log("asgvasdgfb",   ipfsmetatag,
// Wallet_Details.UserAccountAddr,
// Wallet_Details.UserAccountAddr,
// TokenQuantity,
// TokenPriceInStr,
// CollectibleType,
// String(TokenRoyalities));
//     CoursetroContract = new web3.eth.Contract(Market, Wallet_Details.networkConfiguration.TradeContract);
//     try {
//       contractCall = await CoursetroContract.methods.minting(
//         ipfsmetatag,
//         Wallet_Details.UserAccountAddr,
//         Wallet_Details.UserAccountAddr,
//         TokenQuantity,
//         CollectibleType,
//         TokenRoyalities.toString(),
//         TokenPriceInStr,  
//       )
//     } catch (err) {
//       //console.log("adfsbdhbdfgtnh", err);
//     }
//     //console.log("contractCall", contractCall);
//   try {
//     var mintCall = null;
//     var receipt = null;
//     var handle = null;
//     ////////console.log("wqywuyquyw",typeof TokenPriceInStr)
//     setMintCallStatus('processing')
//     if (contractCall != null) {
//       await contractCall
//         .send({
//           from: Wallet_Details.UserAccountAddr
//         })
//         .on('transactionHash', (transactionHash) => {
//           ////console.log("testing all 3@123", transactionHash)
//           mintCall = transactionHash;
//           if (mintCall) {
//             handle = setInterval(async () => {
//               receipt = await getReceipt(web3, transactionHash)
//               //console.log("XGFnbfgnfxdgn",receipt);
//               clr1();
//             }, 8000)
//           }
//         })
//     }
//   }
//   catch (e) {
//     //console.log("dfsfdsfdsef", e)
//     toast.error("Mint not Successfully", toasterOption);
//     setMintCallStatus('tryagain');
//   }
//   async function clr1() {
//     if (receipt != null) {
//       clearInterval(handle);
//       if (receipt.status === true) {
//         Set_MintHashVal(mintCall);
//         toast.success("Your Token Added Successfully", toasterOption);
//         var Status = "true";
//         var tokenid = receipt?.logs[1]?.topics[2] && receipt.logs[1].topics[2];
        
//         const someString = Web3Utils.hexToNumber(tokenid);
//         //console.log("dfhbsfgnsfg",receipt,tokenid,someString);
//         //console.log("dbsdfbhsfgb",);
//         var TokenAddItemPayload = {
//           Image: originalImage,
//           ipfsimage: ipfshash,
//           Name: TokenName,
//           Count: someString,
//           Description: TokenDescription,
//           Price: TokenPrice,
//           Royalities: TokenRoyalities,
//           Category_label: CategorywithSubcategory.category,
//           Bid: TokenBid,
//           Properties: TokenProperties,
//           Owner: Wallet_Details.UserAccountAddr,
//           Creator: Wallet_Details.UserAccountAddr,
//           CategoryId: CategorywithSubcategory.id,
//           Quantity: TokenQuantity,
//           Balance: TokenQuantity,
//           // ContractAddress:Wallet_Details.networkConfiguration.TradeContract,
//           ContractAddress:(location_pathname === 'create-single') ? Wallet_Details.networkConfiguration.singleContract:Wallet_Details.networkConfiguration.multipleContract,
//           Status: Status,
//           HashValue: mintCall,
//           Type: CollectibleType,
//           MinimumBid: 0,
//           Clocktime: '',
//           EndClocktime: '',
//           UnLockcontent: '',
//           PutOnSale: PutOnSale,
//           PutOnSaleType: PutOnSaleType,
//           CoinName: CoinName,
//           additionalImage: additionalImage,
//           collection: UserCollection,
//           SelectedNetwork: NetworkID,
//           decimal: decimal,
//           Subcategory: CategorywithSubcategory.subcategory
//         };
//          //console.log("lkjhg", TokenAddItemPayload)
//         if (Unlockoncepurchased === true) {
//           TokenAddItemPayload.UnLockcontent = UnLockcontent;
//         }

//         if (PutOnSale === true) {
//           if (PutOnSaleType === 'FixedPrice') {
//             TokenAddItemPayload.Price = TokenPrice;
//           }
//           else if (PutOnSaleType === 'TimedAuction') {
//             TokenAddItemPayload.MinimumBid = MinimumBid;
//             TokenAddItemPayload.Clocktime = Clocktime;
//             TokenAddItemPayload.EndClocktime = EndClocktime;
//             //TokenAddItemPayload.CoinName = Wallet_Details.networkConfiguration.tokenSymbol;
//           }
//         }
//         //console.log("bdfbrhderh", TokenAddItemPayload);
//         var resp = await TokenAddItemAction(TokenAddItemPayload);
//         //console.log('tokkkkkeeee',resp)
//         var TokenAddOwnerPayload = {
//           'Count': someString,
//           'Price': TokenPrice,
//           'Owner': Wallet_Details.UserAccountAddr,
//           'Balance': TokenQuantity,
//           'Quantity': TokenQuantity,
//           'ContractAddress': (location_pathname === 'create-single') ? Wallet_Details.networkConfiguration.singleContract:Wallet_Details.networkConfiguration.multipleContract,
//           // "ContractAddress":Wallet_Details.networkConfiguration.TradeContract,
//           'Type': CollectibleType,
//           'tokenCreator': Wallet_Details.UserAccountAddr,
//           // 'previousPrice':TokenPrice,
//           'HashValue': mintCall,
//           'CoinName': CoinName,
//           'Status': Status,
//           'collection': UserCollection,
//           'SelectedNetwork': NetworkID,
//           "decimal":decimal,
//           "PutOnSaleType": PutOnSaleType,
//           // "currencyCheck": iscurrency,
//         };
//         if (PutOnSaleType === 'TimedAuction') {
//           TokenAddOwnerPayload.MinimumBid = MinimumBid;
//           TokenAddOwnerPayload.Clocktime = Clocktime;
//           TokenAddOwnerPayload.EndClocktime = EndClocktime;
//           //TokenAddOwnerPayload.CoinName = Wallet_Details.networkConfiguration.tokenSymbol
//         }
//         //console.log("dafhbgsfrtjh", TokenAddOwnerPayload);
//         await TokenAddOwnerAction(TokenAddOwnerPayload);

//         setMintCallStatus('done');
//         setTimeout(() => window.$('#create_item_modal').modal('hide'), 1000);
//         setTimeout(() => history.push("/my-items"), 1200);
//         // setTimeout(() => window.history.pushState({}, "", "/my-items"))
//         // setTimeout(() => history.push("/my-items"), 1200);
//         //window.location="/my-items"






//       }
//     }

//   }
// }



// async function getapproveFun()
// {
// setApproveTrade("processing")
// var web3 = new Web3(Wallet_Details.providerss);

//         var address = (location_pathname === 'create-single') ? Wallet_Details.networkConfiguration.singleContract:Wallet_Details.networkConfiguration.multipleContract


//         try {
//           var ConnectContract = await new web3.eth.Contract(  CollectibleType == '721' ? SINGLE : MULTIPLE,address );
//           var contract_Method_Hash = await
//               ConnectContract
//                   .methods
//                   .setApprovalForAll(Wallet_Details.networkConfiguration.TradeContract, true)
//                   .send({
//                       from: Wallet_Details.UserAccountAddr
//                   }).on('transactionHash', (transactionHash) => {
//                       return transactionHash
//                   })
//          var  HashValue=contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash
//           var receipt = await web3.eth.getTransactionReceipt(HashValue);

//           var need_data = {
//               status: receipt.status,
//               HashValue: receipt.transactionHash,
//           }
// //console.log("Fdbnhsfgnsfg",need_data);
// if(need_data.status === true)
// {
// setApproveTrade("done")

// }
// else{
// setApproveTrade("tryagain")
// }
//           return need_data;
    
//       }
//       catch (e) {
//           //console(data, e)
//           return false
//       }

// }


var nftids = [];

async function Mintcallfun(mintinc)
{
  try{
  setmintdone("processs")
  var mintindex = mintinc != undefined ? mintinc : 0;
  var ipfshashh = []
  var supply1 = []
  var royal1 = []
  var sub1 = []
  var val1 = []
  var price1 = []

  var size = 50
// if(ipfshashh.length > mintindex)
// {
  var web3 = new Web3(Wallet_Details.providerss);

//console.log("dfbsfdbsfdgb",web3);
  if (Wallet_Details.UserAccountAddr === "") {
    toast.warning("OOPS!..connect Your Wallet", toasterOption);
    return false;
  }
  var gas;
  await web3.eth.getGasPrice()
    .then(async (result) => {
      gas = result;
    });



  var CoursetroContract = null;
  var contractCall = null;
  var mintingfees = null;
  var ipfsurls = ipfsArray[mintindex].ipfsmetadata
  var originalImage=ipfsArray[mintindex].image
  var ipfsHashmp3=ipfsArray[mintindex].ipfsval
  var ipfsmetahash =`${CONFIG.IPFS_IMG}/${ipfsurls}`
  var TokenPriceInStr =  (window.web3.toWei(TokenPrice)).toString()
//console.log("fdgjhngfmjghm",Market,Wallet_Details.networkConfiguration.TradeContract);
  CoursetroContract = new web3.eth.Contract(Market,Wallet_Details.networkConfiguration.TradeContract);
  //console.log("dfbdfbdfb",ipfsArray.length);
  var supply = new Array(ipfsArray.length).fill(1);
  var royal = new Array(ipfsArray.length).fill(window.web3.toWei(TokenRoyalities).toString());
  var price = new Array(ipfsArray.length).fill(TokenPriceInStr);
  var sub = new Array(ipfsArray.length).fill(TokenPriceInStr);
  var val = new Array(ipfsArray.length).fill(1);


//console.log("dfbsdfbsfcgbnsf",supply);
  if(ipfsArray?.length>0)
  {
      for (let i = 0, j = ipfsArray.length; i<j; i+= size) {
        ipfshashh.push(ipfsArray.slice(i, (i + size)))
        supply1.push(supply.slice(i, (i + size)))
        royal1.push(royal.slice(i, (i + size)))
        price1.push(price.slice(i, (i + size)))
        val1.push(sub.slice(i, (i + size)))
        sub1.push(val.slice(i, (i + size)))

      }
    }

if(ipfshashh?.length>mintindex)
{

    try {


      // contractCall = await CoursetroContract.methods.minting(
      //   ipfsmetahash,
      //   Wallet_Details.UserAccountAddr,
      //   Wallet_Details.UserAccountAddr,
      //   TokenQuantity,
      //   CollectibleType,
      //   TokenRoyalities.toString(),
      //   TokenPriceInStr,  
      // )



      var ipfsarr = [];
      let currputonsaleqty = [];
      ipfshashh[mintindex].map(val=>{
        ipfsarr.push(`${CONFIG.IPFS_IMG}/${val?.ipfsmetadata}`)
        currputonsaleqty.push(Number(PutOnSaleQuantity))
      })
      console.log('kkkkkkkk',ipfsarr,
      Wallet_Details.UserAccountAddr,
      supply1[mintindex],
      window.web3.toWei(TokenRoyalities).toString(),
      TokenQuantity,
      CollectibleType,PutOnSaleQuantity,currputonsaleqty,)
      var mintingfee = await CoursetroContract.methods.getServiceFee().call()
      mintingfees = mintingfee[3]
      console.log('sklsklks',mintingfee,supply1[mintindex])
       contractCall = await CoursetroContract.methods.batchMinting(
        ipfsarr,
        Wallet_Details.UserAccountAddr,
        supply1[mintindex],
        currputonsaleqty,
        [window.web3.toWei(TokenRoyalities).toString(),supply1[mintindex].length,CollectibleType],
        ListNFT,
        // window.web3.toWei(TokenRoyalities).toString(),
        // ipfsarr.length,
        // CollectibleType,
        // price1[mintindex],
        // sub1[mintindex],
        // val1[mintindex]
      );


    } catch (err) {
      setMintCallStatus('try')
      setmintdone('try')
      toast.error("Try Again", toasterOption);
      console.log("adfsbdhbdfgtnh", err);
    }

 
    try {
      var mintCall = null;
      var receipt = null;
      var handle = null;
      ////////console.log("wqywuyquyw",typeof TokenPriceInStr)
      setMintCallStatus('processing')
      if (contractCall != null) {
        // var gasPrice = await web3.eth.getGasPrice();
        // var encoded = contractCall.encodeABI()
        // var gasdata = await web3.eth.estimateGas({
        //   from: Wallet_Details.UserAccountAddr,
        //   to: Wallet_Details.networkConfiguration.TradeContract,
        //   data: encoded,
        // });
        await contractCall
          .send({
            from: Wallet_Details.UserAccountAddr, 
            // value: Wallet_Details.Minting_fee,
            // gasLimit: parseInt(gasdata * 1.5, 10), 
            // gasPrice: gasPrice
          })
          .on('transactionHash', (transactionHash) => {
            ////console.log("testing all 3@123", transactionHash)
            mintCall = transactionHash;
            if (mintCall) {
              handle = setInterval(async () => {
                receipt = await getReceipt(web3, transactionHash)
                //console.log("XGFnbfgnfxdgn",receipt);
                clr1(mintindex);
              }, 8000)
            }
          })
      }
    }
    catch (e) {
      console.log("dfsfdsfdsef", e)
      toast.error("Try Again", toasterOption);
      setMintCallStatus('tryagain');
      setmintdone('try')
    }
  }
  else{
    console.log('lkdlskdsds',nftids);
    SetNFTIds(nftids)
    setmintdone('true');
    window.$('#create_item_modal2').modal('hide');
    history.push("/my-items")
  }
    async function clr1(mintindex) {
      try{
        //console.log("XGFnbfgnfxdgn",receipt,mintindex,ipfshashh,Number(CollectibleType) == 721);
      if (receipt != null) {
        clearInterval(handle);
        if (receipt?.logs != undefined) {
          //console.log('lkldfjhjldfjhljdfl')
          var Loglength = receipt.logs.length;
          var tokenhex = [];
           var ipfshsah1=ipfshashh[mintindex]
           var web3 = new Web3(Wallet_Details.providerss)
           var tokenname = [];
// //console.log("fbsdfbsfdgnb",receipt.logs,Web3Utils.hexToNumber(receipt.logs[0].topics[0]),Web3Utils.hexToNumber(receipt.logs[0].topics[1]),Web3Utils.hexToNumber(receipt.logs[0].topics[2]),Web3Utils.hexToNumber(receipt.logs[0].topics[3]));
          // if(Number(CollectibleType) == 721){
            for (let i = 1; i <= Loglength; i += 2) {
              var tokenid = receipt.logs[i].topics[2];
              var someString = web3.utils.hexToNumber(tokenid);
              var tokendata = {
                tokenCounts: someString,
              };
              tokenhex.push(tokendata);
              if(tokendata){
                nftids.push(someString);
              }
            }
            console.log('kkskfkklsjlfls',nftids)
          // }
          // else{
          //   for (let i = 0; i <= Loglength; i += 2) {
          //     var tokenid = String(receipt.logs[i].data);
          //     // var someString = Web3Utils.hexToNumber(tokenid);
          //     //console.log('qwewqeqweqweqweqw111',tokenid)
          //     var sliceee = tokenid.slice(2)
          //     var lengthuh = sliceee.length / 2
          //   //console.log("qwewqeqweqweqweqw",lengthuh,tokenid, sliceee, sliceee.slice(0, lengthuh), sliceee.slice(lengthuh));

            
          //   var TokenID = web3.utils.hexToNumber("0x" + sliceee.slice(0, lengthuh))
            
          //   var Balance = web3.utils.hexToNumber("0x" + sliceee.slice(lengthuh))
          //   //console.log('shfksjigskjgks',TokenID)

          //     var tokendata = {
          //       tokenCounts: TokenID,
          //     };
          //     tokenhex.push(tokendata);
          //   }
          // }
        }
        //console.log("dfbdfbdfb",tokenhex);
        var finalarrofdata = tokenhex.map((obj, index) =>
        Object.assign({}, obj, ipfshsah1[index])
      );
//console.log("dfbnhdfgndfghn",finalarrofdata);
        if (receipt.status === true&&finalarrofdata) {
          Set_MintHashVal(mintCall);
          // toast.success("Your Token Added Successfully", toasterOption);
          var Status = "true";
          // //console.log("sxdbvsdbvsdbvs",receipt);
          // var tokenid = receipt?.logs[1]?.topics[2] && receipt.logs[1].topics[2];
          
          // //console.log("xdsbsdfbsfdbs",tokenid);

          // const someString = Web3Utils.hexToNumber(tokenid);      
          var TokenAddItemPayload = {
            Name: TokenName,
            Description: TokenDescription,
            Price: TokenPrice,
            Royalities: TokenRoyalities,
            Category_label: CategorywithSubcategory.category,
            Bid: TokenBid,
            Properties: TokenProperties,
            Owner: Wallet_Details.UserAccountAddr,
            Creator: Wallet_Details.UserAccountAddr,
            CategoryId: CategorywithSubcategory.id,
            Quantity: 1,
            Balance: 1,
            // ContractAddress:Wallet_Details.networkConfiguration.TradeContract,
            ContractAddress:Wallet_Details.networkConfiguration.singleContract,
            Status: Status,
            HashValue: mintCall,
            Type: CollectibleType,
            MinimumBid: 0,
            Clocktime: '',
            EndClocktime: '',
            UnLockcontent: '',
            PutOnSale: PutOnSale,
            PutOnSaleType: PutOnSaleType,
            CoinName: CoinName,
            additionalImage: additionalImage,
            collection: UserCollection,
            SelectedNetwork: NetworkID,
            decimal: decimal,
            Subcategory: CategorywithSubcategory.subcategory,
            ipfsarry:finalarrofdata,
            TermsandConditions: agree.termsandcondition,
            Randomname:Randomname,
            _nonce:_nonce,
            signature:signature,
            PutOnSaleQuantity: PutOnSaleQuantity,
            ListNFT: ListNFT,
            ListedQuantity: ListNFT ? 1 : 0,
            SocialLinks: JSON.stringify(socialLinks)
          };
          
          console.log("dfnfrngjtygmn",TokenAddItemPayload);

          var resp = await TokenAddItemAction1(TokenAddItemPayload);
          //console.log('tokkkkkeeee',resp)

          if(resp)
          {
var incr = mintindex+1
            Mintcallfun(incr)


          }
        //   if(resp)
        //   {
        //   var TokenAddOwnerPayload = {
        //     'Price': TokenPrice,
        //     'Owner': Wallet_Details.UserAccountAddr,
        //     'Balance': 1,
        //     'Quantity': 1,
        //     'ContractAddress': Wallet_Details.networkConfiguration.singleContract,
        //     // "ContractAddress":Wallet_Details.networkConfiguration.TradeContract,
        //     'Type': CollectibleType,
        //     'tokenCreator': Wallet_Details.UserAccountAddr,
        //     // 'previousPrice':TokenPrice,
        //     'HashValue': mintCall,
        //     'CoinName': CoinName,
        //     'Status': Status,
        //     'collection': UserCollection,
        //     'SelectedNetwork': NetworkID,
        //     "decimal":decimal,
        //     "PutOnSaleType": "FixedPrice",
        //     // "currencyCheck": iscurrency,
        //     ipfsarry:finalarrofdata
        //   };
        //  //console.log("fghnbdfghndgf",TokenAddOwnerPayload);
        //    await TokenAddOwnerAction1(TokenAddOwnerPayload);
  
        //   // setMintCallStatus('done');
        //   // setTimeout(() => window.$('#create_item_modal').modal('hide'), 1000);
        //   // setTimeout(() => history.push("/my-items"), 1200);
        //   }
        }
      
      }
      }catch(err)
      {
        //console.log("fgndfgnhfghmn",err);
      }
    }
   
    }
    catch(err)
    {
      console.log("Dfbghdfnbdgfngdh",err);
setmintdone("")
    }
  }

  async function PlaceOrder(){
    try{
      console.log('NFTIds',NFTIds)
      setplaceordedone('process');
      if(NFTIds?.length > 0){
        var web3 = new Web3(Wallet_Details.providerss)
        var CoursetroContract = new web3.eth.Contract(Market,Wallet_Details.networkConfiguration.TradeContract);
        var contractCall = await CoursetroContract.methods.batchListing(
          TokenPrice,
          NFTIds,
          CollectibleType,
        );
        var mintCall = null;
      var receipt = null;
      var handle = null;
      ////////console.log("wqywuyquyw",typeof TokenPriceInStr)
      setMintCallStatus('processing')
      if (contractCall != null) {
        await contractCall
          .send({
            from: Wallet_Details.UserAccountAddr
          })
          .on('transactionHash', (transactionHash) => {
            ////console.log("testing all 3@123", transactionHash)
            mintCall = transactionHash;
            if (mintCall) {
              handle = setInterval(async () => {
                receipt = await getReceipt(web3, transactionHash)
                console.log("XGFnbfgnfxdgn",receipt);
              }, 8000)
            }
          })
          // window.$('#create_item_modal2').modal('hide');
    // history.push("/my-items")
        console.log('minttttttt',mintCall,receipt)
      }
      }
      else{
        setplaceordedone('try')
        toast.error('error-Occured')
      }
    }
    catch(err){
      console.log('PlaceOrder error',err)
    }
  }

  const ChangeLayer = (e) => {
    console.log('kjsfffsfsfs',e);
    SetLayerType(e);
  }

  useEffect(()=>{
    if(mp3File?.length > 0 && newlocation_pathname !== 'layer'){
      Set_TokenQuantity(mp3File.length)
    }
  },[mp3File]);

  const ChangeView = (val) => {
    SetViewMode(val)
  }

  const Oncancel = (from) => {
    console.log('OncancelOncancel',from,from === 'fileInputControl')
    if (from === 'fileInputControl') {
      console.log('fileInputControl',files)
      if(files?.length > 0){
        set_files([]);
        toast.success('Files Removed Successfully',1000)
      }
    }
    else if(from === 'audio') {
      console.log('audio',mp3File)
      if(mp3File?.length > 0){
        set_mp3File([]);
        toast.success('Files Removed Successfully',1000)
      }
    }
  }

  const FirstCaps = ( data ) => {
    return data[0].toLocaleUpperCase()+data.slice(1);
  }

  const GetRequiredLayers = (e) => {
    if(!isNaN(e.target.value)){
      SetRequiredNFT(e.target.value);
      if( Number(e.target.value) >= 2 ){
        let num = Number(e.target.value)
        let guessnum = 2;
        let count = 0;
        let total = 0;
        let arr = [];
        let prime = true;
        let currnum = num;
        // for(let i = 1; currnum > 1 ;i++){
        //   total = 0;
        //   console.log('forrrrr',currnum,i)
        //   if(currnum%i !== 0){
        //     if( i == Math.round(currnum/2)){
        //       arr.push(2);
        //       i=1;
        //       currnum=Math.round(currnum/2);
        //     }
        //   }
        //   else if(i!=1 && num%i == 0){
        //     arr.push(i);
        //     currnum=Math.round(currnum/i);
        //     prime = true;
        //     i=1
        //   }
        //   else if( i==1 && currnum==2 ){
        //     arr.push(2);
        //     currnum=Math.round(currnum/i);
        //     // i=1
        //   }
        //   if(total >= num){
        //     break;
        //   }
        // }
        console.log('totalllllllll',arr,currnum,prime);
        let quo = 0;
        let folder = 2;
        
        if(num<16){
          quo = Math.round(num/folder);
        }
        else{
          folder = 4
          quo = Math.round(num/folder);
        }

        Loop(quo);

        function Loop(check) {
          console.log('checkcccccc',check,folder,quo,num)
          if((check ** folder) > num){
            Loop(check-1)
          }
          else{
            quo = num > 2 ? check+1 : check;
            return true
          }
        }
        
        // let rem = Number(e.target.value)%2;
        console.log('lkslfkslks',quo,Math.round(quo));
        SetFileProbability(folder+" Folders Each of "+quo+" Layers");
      }
      else{
        if(!isEmpty(FileProbability)){
          SetFileProbability('')
        }
      }
    }
  }

  const Viewfunction = () => {
    if( (newlocation_pathname !== 'layer') && (newlocation_pathname !== 'image')){
      if(files.length !== mp3File.length){
        toast.error('Thump Image and '+FirstCaps(newlocation_pathname)+' File Count Must be Same',1000)
      }
      else if(files.length == 0 && mp3File.length == 0){
        toast.error('Upload Thump Image and '+FirstCaps(newlocation_pathname)+' File to See Preview',1000)
      }
      else{
        SetViewMode(true)
      }
    }
    else if((mp3File.length == 0 && newlocation_pathname == 'image')){
      toast.error('Upload Images to See Preview',1000)
    }
    else{
      SetViewMode(true)
    }
  }

  const CheckedChange = (e) => {
   
    if (e && e.target && e.target.name) {
      if (e.target.name === 'putonsale') {
        if (PutOnSale === false) {
          setPutOnSale(true);
          if (PutOnSaleType === 'UnLimitedAuction') {
            setPutOnSaleType('FixedPrice')
          }
        }
        else {
          setPutOnSale(false);
          setPutOnSaleType("UnLimitedAuction")
          setTokenBid(true)
          setPutOnSaleQuantity('')
          setTokenPrice(0)
          setCoinNames('')
        }
      }
      else if(e.target.name === "ListNFT"){
        if (ListNFT === false) {
          SetListNFT(true)
          setPutOnSale(true);
          if (PutOnSaleType === 'UnLimitedAuction') {
            setPutOnSaleType('FixedPrice')
          }
        }
        else {
          SetListNFT(false)
          setPutOnSale(false);
          setPutOnSaleType("UnLimitedAuction")
          setTokenBid(true)
          setPutOnSaleQuantity('')
          setTokenPrice(0)
          setCoinNames('')
        }
      }
    }
  };

  const addFilters = () => {
    if(filterRows.length < 10){
      setFilterRows([...filterRows, { filters: "" }])
      SetSocialLinks([...socialLinks, { url: "" }])
    }
    else{
      toast.error('Already added 10 links',1000)
    }
  };

  const removeFilters = (index) => {
    const list = [...filterRows]
    let currlink = socialLinks;
    list.splice(index, 1)
    currlink.splice(index,1)
    setFilterRows(list)
    SetSocialLinks([...currlink])
  };

  const OnChange = (e,index) => {
    console.log('jdgdlgldgd',e,index)
    let currlinks = socialLinks;
    setValidateError({})
    if(e?.target?.id){
      
      const {id,value} = e.target;
      
      filterRows.map((val,ind)=>{
        if(ind == index){
          currlinks[ind][id] = value
        }
        
      })
    }
    else if(e?.value){
      const {classname,label,value} = e;
      
      filterRows.map((val,ind)=>{
        if(ind == index){
          currlinks[ind].urlfor = value;
          currlinks[ind].classname = classname;
        }
        
      })
    }
    console.log('kdlkkgdlkgd',currlinks)
    SetSocialLinks([...currlinks])
  }

  return (
    <div className="inner_header">

      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
          <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
        </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      {console.log('ldkgldkkgdkg',NFTProbability,MultipleImagePath,mp3File,files)}
      <ScrollToTopOnMount />
      { ViewMode ? 
      <ViewBulkFiles Files={mp3File} ThumbFiles={newlocation_pathname === 'layer'? MultipleImagePath :files} ChangeView={ChangeView} TokenQuantity={TokenQuantity} Pathname={newlocation_pathname} Layertype={LayerType?.value} Popup={PopupOpen}/> :
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
        <AddCollectionRef
          ref={CreateCollectionForwardRef}
          GetCollection={getCollection}
        />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <Link to="/create-polymultiple">
                  <i className="fas fa-arrow-left mr-3 arrow_back"></i>
                </Link>
                <h3 className="section-head mb-0 sec_he_sm">Create Bulk NFTs</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-5">
          <GridContainer>
        
            <GridItem xs={12} sm={12} md={12}>
              <form className="formCls">
                  { newlocation_pathname == 'layer' &&
                    <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="name">NFT Need To be Minted</label>
                      <input type="text"
                        className="form-control primary_inp"
                        name="MintQuantity"
                        id="MintQuantity"
                        onChange={(e)=>GetRequiredLayers(e)}
                        placeholder="e.g. 10"
                        value={RequiredNFT || ''}
                      />

                    </div>
                    {console.log('lsflkslkfls',FileProbability)}
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="desccription">Layers Needed (approximately)</label>
                      <textarea type="text"
                        className="form-control primary_inp"
                        disabled={true}
                        placeholder=""
                        autoComplete="off"
                        value={FileProbability ? FileProbability : '' }
                      ></textarea>

                    </div>
                  </div>}
                <div className="form-row">
                  
                  {(newlocation_pathname !== 'layer') &&
                   <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">View</label>
                        <p className="form_note">Click here to View the Files Uploaded</p>
                      </div>
                      <div className="file_btn btn primary_btn" onClick={() => Viewfunction()}>
                        View
                      </div>
                    </div>
                  </div>}
                  
                  
                  {(newlocation_pathname !== 'image' )&&
                  <div className="form-group col-md-12">
                    
                    <div className="d-flex justify-content-between align-items-start flex-wrap">
                      
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">{newlocation_pathname == 'layer' ? "Upload layer" : "Upload Thumb File" }</label>
                        <p className="form_note resform_note">{newlocation_pathname == 'layer' ? "The folder should contain at least two files.Note you can preview the uploaded files by clicking on the uploaded folder." : "As per metadata standard, while uploading "+newlocation_pathname+" file please add a image file too. Based no. of video file uploaded, same quantity of image file also need to be uploaded"}</p>
                        {(newlocation_pathname != 'layer' && newlocation_pathname != 'image') && 
                        <>
                          <p className="form_note resform_note">Example: If you add one {newlocation_pathname} file simultaneously add one image file too</p>
                          <p className="form_note resform_note">If you add ten {newlocation_pathname} file simultaneously add ten image file too</p></>}
                      </div>
                      <div className="d-flex justify-content-end align-items-center">
                      <div className="file_btn btn primary_btn me-2">Upload
                        <input
                          className="inp_file"
                          type="file"
                          id="fileInputControl"
                          webkitdirectory="true"
                          multiple
                          // value={files}
                          // type="file"
                          // name="file"
                          // accept="audio/*,video/*,image/*"
                           onChange={selectFileChange}
                        />
                      </div>
                      <div className="file_btn btn primary_btn" onClick={()=> Oncancel('fileInputControl')}>Cancel</div>
                      {ValidateError.layer && <span className="text-danger img-file">{ValidateError.layer}</span>}
                    </div>
                    </div>
                  </div>}
                  {(( newlocation_pathname !== 'layer') || (newlocation_pathname == 'layer' && LayerType?.value == 'Imageaudio')) &&
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start flex-wrap">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">{newlocation_pathname == 'layer' ? "Upload audio file" : "Upload "+FirstCaps(newlocation_pathname) }</label>
                        <p className="form_note">{newlocation_pathname == 'layer' ? "MP3,mp3,FLAC" : Validfile }</p>
                      </div>
                      <div className="info_btn_kralign">
                      <div className="file_btn btn primary_btn me-2">Upload
                        <input
                          className="inp_file"
                          type="file"
                          id="audio"
                          webkitdirectory="true"
                          multiple
                          // value={mp3File||''}
                          // type="file"
                          // name="file"
                          // accept="audio/*,video/*,image/*"
                           onChange={selectFileChange}
                        />
                      </div>
                      <div className="file_btn btn primary_btn" onClick={()=> Oncancel('audio')}>Cancel</div>
                      {ValidateError.photo && <span className="text-danger img-file">{ValidateError.photo}</span>}
                      </div>
                    </div>
                  </div>}
                </div>


                <div className="row mx-0 mt-4 fixed_price_sec">

                  <div className="form-row type">
                      {/* <div className="form-group col-md-6">

                  <div className="form-row price">
                      {/* <div className="form-group col-md-12">

                        <label className="primary_label" htmlFor="price_new">Price</label>

                        <div className="">
                          <div className="">
                            <div className="input-group input_grp_style_1">

                              <input
                                type="text"
                                className="form-control selct_form_input_h"
                                placeholder="0"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                name="TokenPrice"
                                id="TokenPrice"
                                step="0.01"
                                maxLength={15}
                                autoComplete="off"
                                onChange={inputChange}
                              />
                              <div className="input-group-append">
                                <Select
                                  className="yes1 form-control primary_inp select1 selxet_app"
                                  id="basic-addon2"
                                  name="coinname"
                                  onChange={priceoptionfunc}
                                  options={priceoption}
                                  label="Select price"

                                formControlProps={{
                                  fullWidth: true
                                }}

                                />

                              </div>
                            </div>
                            {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                          </div>


                        </div>
                        <p className="form_note">Service fee
                          <span className="font_we_700_note_txt">{Wallet_Details.Service_Fee_seller / 1e18}% </span><br />
                          You will receive <span className="font_we_700_note_txt">

                            {/* {YouWillGet}

                            <Convert
                              item={Number(YouWillGet)}

                              coinName={CoinName}
                              convertVal={1}
                            />
                            {CoinName}
                          </span>
                          <span className="font_we_700_note_txt">
                            <br />



                            {/* $( {CoinName == "CAKE" ? Wallet_Details.Token_convertion[0].USD * TokenPrice : Wallet_Details.Token_convertion[0].USD * TokenPrice})
                          </span>
                        </p>
                        </div> */}
                        {/* <div className="form-group col-md-6">
                  <label className="primary_label" htmlFor="price_new">Type</label>


                  <div className="form-row w-100 mt-2">
                    <div className="form-group col-md-12">
                      <div className="input-group input_grp_style_1">


                        <Select
                          className="yes1 typecontrol3 form-control primary_inp typecontrol"
                          id="networkname"
                          name="networkname"
                          // onChange={networkoptionfunc}
                        //  onChange={(e)=>Set_CollectibleType(e.value)}
                          options={[{ value: '721', label: 'ERC721' }, { value: '1155', label: 'ERC1155' }]}
                          // options={networkoption}
                          label="Select price"
                          classNamePrefix="react-select"
                        // formControlProps={{
                        //   fullWidth: true
                        // }}
                        />
                        
                            {ValidateError.network && <span className="text-danger">{ValidateError.network}</span>}


                      </div>
                      <p>Please, select either ERC 721 or ERC 1155.</p>
                    </div>
                  </div>
                </div> */}
                <div className="form-group col-md-6 network">
                  <label className="primary_label" htmlFor="price_new">Network</label>
                      <div className="input-group input_grp_style_1 pt-2">


                        <Select
                          className="yes1 typecontrol3 form-control primary_inp typecontrol"
                          id="networkname"
                          name="networkname"
                          // onChange={networkoptionfunc}
                          onChange={selectChange}
                          options={networkoption}
                          label="Select price"
                          classNamePrefix="react-select"
                          isSearchable={false}
                          value={config?.label ? config : {label:'Select price'} }
                        // formControlProps={{
                        //   fullWidth: true
                        // }}
                        />
                            {ValidateError.network && <span className="text-danger">{ValidateError.network}</span>}


                      </div>
                      <p className="form-note">Supported blockchains are BNB Smartchain and Ethereum Smartchain</p>
                </div>
                {newlocation_pathname == 'layer' &&
                <div className="form-group col-md-6 network">
                  <label className="primary_label" htmlFor="price_new">Choose Type</label>
                      <div className="input-group input_grp_style_1 pt-2">


                        <Select
                          className="yes1 typecontrol3 form-control primary_inp typecontrol"
                          id="layertype"
                          name="layertype"
                          // onChange={networkoptionfunc}
                          onChange={(e)=>ChangeLayer(e)}
                          options={[{label:'Image Only',value:'Image'},{label:'Image With Audio',value:'Imageaudio'}]}
                          label="Select price"
                          classNamePrefix="react-select"
                          value={LayerType}
                          isSearchable={false}
                        // formControlProps={{
                        //   fullWidth: true
                        // }}
                        />


                      </div>
                      <p className="form-note">Select This To Upload Your Layer For NFT's</p>
                </div>}
                </div>
                </div>
        
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Name</label>
                    <input type="text"
                      className="form-control primary_inp"
                      name="TokenName"
                      id="TokenName"
                      onChange={inputChange}
                      placeholder="e.g. Redeemable"
                      value={TokenName||''}
                      />
                    {ValidateError.TokenName && <span className="text-danger">{ValidateError.TokenName}</span>}

                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Description <span className="text-muted">(Optional)</span></label>
                    <textarea type="text"
                      className="form-control primary_inp"
                      id="desccription"
                      name="TokenDescription"
                      onChange={inputChange}
                      placeholder="You have an option to give description of your NFT"
                      autoComplete="off"
                      value={TokenDescription||''}
                    ></textarea>

                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="Collection">Select Collection</label>
                    <Select
                      className="yes1 typecontrol3 form-control primary_inp typecontrol"
                      id="userCollection"
                      name="userCollection"
                      onChange={selectChange}
                      maxMenuHeight={220}
                      options={CollectionList}
                      label="Select Collection"
                      classNamePrefix="react-select"
                      placeholder="Select Collection"
                      value={
                        UserCollection ? {
                          label: UserCollection,
                          value: UserCollection
                        } : ""
                      }
                    // formControlProps={{
                    //   fullWidth: true
                    // }}
                    />
                    {console.log('jshjhfsg',ValidateError)}
                    {ValidateError.userCollection && <span className="text-danger">{ValidateError.userCollection}</span>}

                  </div>
                  <div className="form-group col-md-6 category">
                    <label className="primary_label mb-3" htmlFor="category">Category</label>
              
                    <input type="text" placeholder="Select the category and the subcategory of your NFT" className="form-control" 
                    value={CategorywithSubcategory?.display ? CategorywithSubcategory?.display : ""}
                    onClick={() => setCategory(!category)}/>
                    {category == true ?
                    (
                    <><div className="dropdown">
                      
                      <ul className="dropdown-menu1 p-3">
                      {fullcategory?.length > 0  &&
                      fullcategory.map((val)=>{
                        return(
                        <li className="dropdown-sub">
                          {val.name}
                          <ul className="sub-dropdown">
                            {val.subcategory.map((sub)=>{
                              return(
                              <li onClick={()=>SettokenCategory(val,sub)}>{sub}</li>
                              )
                            })}
                          </ul>
                        </li>)
                      })
                      }
                        {/* <li className="dropdown-sub">Gaming
                          <ul className="sub-dropdown">
                            <li>Sports</li>
                            <li>Wearables</li>
                            <li>Accessories</li>
                          </ul>
                        </li>
                        <li className="dropdown-sub">Collectible
                          <ul className="sub-dropdown">
                            <li>Kiddies</li>
                            <li>Religion</li>
                            <li>Military</li>
                            <li>Households</li>
                            <li>Fashion</li>
                          </ul>
                        </li> */}
                      </ul>
                    </div></>):(<></>)}  
                    {ValidateError.TokenCategory && <span className="text-danger"><br />{ValidateError.TokenCategory}</span>}

                  </div>
                </div>
               
                <div className="form-row">
                <div className="col-md-6">
                    <div className="form-group">
                    <label className="primary_label" htmlFor="royalties">Royalties</label>
                    <div className="inp_grp">
                      <input type="text"
                        className="form-control primary_inp"
                        name="TokenRoyalities"
                        onChange={inputChange}
                        id="royalties"
                        value={TokenRoyalities}
                        placeholder="10%" />
                      <p className="inp_append">%</p>
                      <span>Note that you may choose not to receive royalty. As such, you type in 0% as royalty.</span>
                      {ValidateError.TokenRoyalities && <span className="text-danger"><br />{ValidateError.TokenRoyalities}</span>}

                    </div>

                  </div>
                  
                  <div className="d-flex justify-content-between align-items-start grid_toggle gap-2">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">List NFT</label>
                        <p className="form_note">To List your NFT on this MarketPlace by turning on the button .</p>
                      </div>
                      <label className="switch toggle_custom me-0">
                        <input type="checkbox"
                          id="ListNFT"
                          name="ListNFT"
                          onChange={CheckedChange}
                          checked={ListNFT}

                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                  {ListNFT && <div className="d-flex gap-2 justify-content-between align-items-start grid_toggle">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Put on Sale</label>
                        <p className="form_note">Decide how you want to sell your minted NFT by turning on the button.</p>
                      </div>
                      <label className="switch toggle_custom me-0">
                        <input type="checkbox"
                          id="putonsale"
                          name="putonsale"
                          onChange={CheckedChange}
                          checked={PutOnSale}

                        />
                        <span className="slider"></span>
                      </label>
                    </div>}
                    {PutOnSale && <div className="form-group">
                  
                  <label className="primary_label" htmlFor="properties">Price </label>
                  <div className="input-group input_grp_style_1">
                  <input type="text"
                    className="form-control primary_inp"
                    id="TokenPrice"
                    name="TokenPrice"
                    onChange={inputChange}
                    placeholder="0" />
                    <div className="input-group-append">
                              <Select
                                className="yes1 form-control primary_inp select1 selxet_app"
                                id="basic-addon2"
                                name="coinname"
                                onChange={priceoptionfunc}
                                options={priceoption}
                                label="Select price"
                              // formControlProps={{
                              //   fullWidth: true
                              // }}
                              />

                            </div>
                            </div>
                    {ValidateError.TokenPrice && <span className="text-danger"><br />{ValidateError.TokenPrice}</span>}
                </div>}
                  </div>
                



                
                  {/* <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="properties">Properties <span className="text-muted">(Optional)</span></label>
                    <input type="text"
                      className="form-control primary_inp"
                      id="properties"
                      name="TokenProperties"
                      onChange={inputChange}
                      placeholder="e.g. size" />

                  </div> */}
                  { newlocation_pathname == 'layer' &&
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="desccription">NFT quantity</label>
                      <input
                        type="text"
                        className="form-control"
                        id="TokenQuantity"
                        name="TokenQuantity"
                        onChange={inputChange}
                        placeholder="e.g. 1"
                        value={TokenQuantity}
                        maxLength={5}
                        autoComplete="off"
                      />
                      <p className="form-note">Please, indicate how many NFTs you are minting.</p> 
                      {ValidateError.TokenQuantity && <span className="text-danger"><br />{ValidateError.TokenQuantity}</span>}
                    </div>
                  }

     {/* <div className="form-row w-100">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">
                      Social Media Links
                    </label>
                    <p className="form-note">Please, indicate your social media links.</p>
                  </div>
               


                  <div className="form-group col-md-6 form_ino_che">
                    <input
                      type="text"
                      className="form-control primary_inp"
                      onChange={inputChange}
                      value={Twitter}
                      name="twitter"

                      id="twitter"
                      placeholder="Twitter account address"
                    />

                    
                    {ValidateError.twitter && (
                      <span className="text-danger">
                        {ValidateError.twitter}
                      </span>
                    )}
                  </div>
                  
                  <div className="form-group col-md-6 form_ino_che">
                    <input
                      type="text"
                      className="form-control primary_inp"
                      id="youtube"
                      name="youtube"

                      placeholder="Youtube channel address"
                       onChange={inputChange}
                      value={youtube}
                    />

                    

                    {ValidateError.youtube && (
                      <span className="text-danger">
                        {ValidateError.youtube}
                      </span>
                    )}
                  </div>
                
              <div className="form-group col-md-6 form_ino_che">
                    <input
                      type="text"
                      className="form-control primary_inp"
                      placeholder="Facebook account address"
                      id="facebook"
                      name="facebook"

                      onChange={inputChange}
                      value={facebook}
                    />
                    
                    {ValidateError.facebook && (
                      <span className="text-danger">
                        {ValidateError.facebook}
                      </span>
                    )}
                  </div>
                 
                  <div className="form-group col-md-6 form_ino_che">
                    <input
                      type="text"
                      className="form-control primary_inp"
                      placeholder="Instagram address"
                       onChange={inputChange}
                      id="instagram"
                      name="instagram"

                      value={instagram}
                    />
                   


                    {ValidateError.instagram && (
                      <span className="text-danger">
                        {ValidateError.instagram}
                      </span>
                    )}
                  </div>
                 </div>    */}
                 <div className="form-row w-100">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">
                      Social Media Links
                    </label>
                  </div>
                </div>
                <div className="social_add_sec">
                  <p className="text_title">Interactive media links, e.g. Facebook, Telegram, Twitter, Instagram, etc.</p>
                      
                      <div className="add_social">
                        <div className="btn_sec">
                      <Button type="button" className="btn btn-add-social" onClick={addFilters}> <AddIcon/></Button><span className="add_txt">Click to Add</span>
                      </div>
              
                      </div>
      {console.log('dgldklgkd',socialLinks)}
                      {filterRows.map((val,index) => (
                    <div className="url_card my-3" key={index}>
                     <label className="primary_label" htmlFor="name">
                      URL For
                    </label>
                      <div className="row first_row">
                        <div className="col-lg-3 mb-4 mb-lg-0">
                        <Select 
                          id = "urfor"
                          options={mappedData}
                          classNamePrefix="react-select"
                          className="yes1 typecontrol3 form-control primary_inp typecontrol" 
                          onChange= {(e)=>OnChange(e,index)}
                          value={
                            socialLinks[index]?.urlfor?
                            {
                              label:socialLinks[index].urlfor,
                              value:socialLinks[index].urlfor,
                            }
                            :''
                          }
                        />
                        {ValidateError['urlfor'+index] && (
                      <span className="text-danger">{ValidateError['urlfor'+index]}</span>
                    )}
                        </div>
                        <div className="col-lg-9 mb-4 mb-lg-0">
                          <div className="row">
                            <div className="col-10">
                        <form>
  <div class="form-group">
    <input type="text" class="form-control" id="url" aria-describedby="emailHelp" placeholder="Enter url" onChange={(e)=>OnChange(e,index)} value={socialLinks[index]?.url||''} />
    {ValidateError['url'+index] && (
                      <span className="text-danger">{ValidateError['url'+index]}</span>
                    )}
  </div>
  </form>
  </div>
  <div className="col-2"><img src={delete_png} onClick={()=>removeFilters(index)} alt="delete" className="img-fluid delete_png" width={30}/></div>
  </div>
 
                        </div>
                        </div>
                    </div>
                      ))}
                </div>
                </div>
                <div className="mb-3">
                <div className="d-flex agreement mb-0">
                <input type="checkbox" id="agreement1" name="agreement1" value="Agreement" checked={agree.original} onChange={()=>SetAgree({...agree,...{original:!agree.original}})}/>
                <label for="vehicle1" className="ml-2">I declare that this is an original artwork. I understand that no plagiarism is allowed, and that the artwork can be removed anytime if detected.</label>
               
                </div>
                {ValidateError.original && <span className="text-danger ms-3">{ValidateError.original}</span>}
                </div>
                <div className="mb-3">
                <div className="d-flex agreement mb-0">
                <input type="checkbox" id="agreement2" name="agreement2" value="Car" checked={agree.termsandcondition} onChange={()=>SetAgree({...agree,...{termsandcondition:!agree.termsandcondition}})}/>
                <label for="vehicle2" className="ml-2">I have read and I agree to all the NFT Terms & Conditions and all legal requirements of EDA</label>
               
                </div>
                {ValidateError.termsandcondition && <span className="text-danger ms-3">{ValidateError.termsandcondition}</span>}
                </div>
                <div>

                  <Button className="create_btn" data-toggle="model" data-target="#create_item_modal" onClick={CreateItem} >Create item</Button>

                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>}
      <Footer />
    
 
    
      <div className="modal fade primary_modal" id="create_item_modal2" tabIndex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-bs-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="create_item_modalLabel">Proceed to create multiple nfts</h5>

              <button type="button" onClick={()=>{ setnftcreated("");SetPopupOpen(false)}} className="close"  data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            
            { newlocation_pathname == 'layer' && 
            <div className="modal-body">

              <div className="text-left">
              <div className="d-flex justify-content-start">
              <div className="tick">
              <i class="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>
              </div>
              <div className="content mb-2">
            <p className="approve_text">Create NFT <span className="mint_freecost">(It's Free of Cost)</span></p>
            <p className="approve_desc">This will generate the various kinds of NFTs for minting.</p>
            </div>
            </div>
            <Button className="create_btn w-100" 
             disabled={nftcreated==="true"||nftcreated === 'processs'} 
            onClick={()=>createnfts()}> 
            {nftcreated === '' && 'Create NFT'}
            {nftcreated === 'processs' && 'processing'}
            {nftcreated==="true"&&'done'}</Button>

            </div>

            </div>}
            { newlocation_pathname == 'layer' && 
            <div className="modal-body">

              <div className="text-left">
              <div className="d-flex justify-content-start">
              <div className="tick">
              <i class="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>
              </div>
              <div className="content mb-2">
            <p className="approve_text">View NFT <span className="mint_freecost">(It's Free of Cost)</span></p>
            <p className="approve_desc">This will generate the various kinds of NFTs for minting.</p>
            </div>
            </div>
            <Button className="create_btn w-100" 
             disabled={
             nftcreated === 'processs'||nftcreated!=="true"} 
            onClick={()=>{SetViewMode(true);window.$('#create_item_modal2').modal('hide');}}> 
              View NFT
            </Button>

            </div>

            </div>}
            <div className="modal-body">

            <div className="text-left">
            <div className="d-flex justify-content-start">
              <div className="tick">
              <i class="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>
              </div>
              <div className="content mb-2">
            <p className="approve_text">IFPS Upload <span className="mint_freecost">(It's Free of Cost)</span></p>
            <p className="approve_desc">IFPS refers to InterPlanetary File System. This is where your NFT file will be stored for easy access</p>
            </div>
            </div>
            <Button className="create_btn w-100"
                    disabled={
                       ipfsdone==="true"||ipfsdone === 'processs'||
                      nftcreated === 'processs'||nftcreated!=="true"
                     } 

            onClick={()=>IpfsAndMint()}>{ipfsdone===""&&'IPFS Upload'}       
            {ipfsdone === 'processs' && 'processing'}
            {ipfsdone==="true"&&'done'}
            {ipfsdone==="try"&&'try again'}</Button>

            </div>          
            </div>
            <div className="modal-body">

            <div className="text-left">
            <div className="d-flex justify-content-start">
              <div className="tick">
              <i class="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>
              </div>
              <div className="content mb-2">
            <p className="approve_text">Mint NFT</p>
            <p className="approve_desc">This button allows you to mint the NFT on the selected blockchain. </p>
            </div>
            </div>
            <Button className="create_btn w-100" 
                                     disabled=
                                     {
                                      mintdone === 'processs'||mintdone === 'true'||
                                     nftcreated === 'processs'||nftcreated!=="true"||
                                     ipfsdone!=="true"||ipfsdone==="try"||ipfsdone === 'processs'
                                    } 

                                     onClick={()=>Mintcallfun()}>{mintdone===""&&'Mint'}       
                                     {mintdone === 'processs' && 'processing'}
                                     {mintdone==="true"&&'done'}
                                     {mintdone==="try"&&'Try-again'}
                                     </Button>

            </div>          

            </div>
            {/* <div className="modal-body">

            <div className="text-left">
            <div className="d-flex justify-content-start">
              <div className="tick">
              <i class="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>
              </div>
              <div className="content mb-2">
            <p className="approve_text">Place Order</p>
            <p className="approve_desc">This button allows you to Place Order for the NFT Minted on the selected blockchain. </p>
            </div>
            </div>
            <Button className="create_btn w-100" 
                                     disabled=
                                     {
                                      placeordedone === 'process' || placeordedone === 'true' ||
                                      mintdone === 'processs'||mintdone !== 'true'||
                                     nftcreated === 'processs'||nftcreated!=="true"||
                                     ipfsdone!=="true"||ipfsdone==="try"||ipfsdone === 'processs'
                                    } 

                                     onClick={()=>PlaceOrder()}>{placeordedone===""&&'Place Order'}       
                                     {placeordedone === 'processs' && 'processing'}
                                     {placeordedone==="true"&&'done'}
                                     {placeordedone==="try"&&'Try-again'}
                                     </Button>

            </div>          

            </div> */}
          </div>
        </div>
      </div>

    </div>
  );
}
